import React from 'react';
import { useCollectionApi } from './api';
import { getCategoryProducts, mergeAndDeduplicateById } from 'helper/internal';
import { metalData } from 'data/';
import { Poster, ProductCard, ShopByCollection, MetalType } from 'components/index';
import { useResponsive } from 'hooks';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetDBPInventoryProductsApi, useGetStoreProductsApi } from 'api';
import { useGetBackgroundColor } from "../../../src/api/index";

const CollectionsLists = ({ data: banners}) => {
  const { isMobile } = useResponsive();
  const [searchParams] = useSearchParams(); 
  const { data: storeDBPInventory } = useGetDBPInventoryProductsApi({ onLoad: true });
  const { data: storeProducts } = useGetStoreProductsApi({ onLoad: true, searchParams });
  const { data: collection } = useCollectionApi({ onLoad: true });
  
  const { data } = useGetBackgroundColor({ onLoad: true });

  // Merging and sorting products data
  const dataFinal = mergeAndDeduplicateById(storeProducts,storeDBPInventory);
  const finalCollections = getCategoryProducts(dataFinal).sort((a, b) => b.products.length - a.products.length);

  // CustomPoster Component
  const CustomPoster = ({ bannerIndex }) => (
    bannerIndex < banners?.length ? (
      <div key={bannerIndex}>
        <Poster img={banners[bannerIndex]?.image} url={banners[bannerIndex]?.ctaUrl}/>
      </div>
    ) : null
  );

  // ProductCollection Component
  const ProductCollection = ({ item }) => (
    <div key={item.id}>
      <section className='py-6 sm:py-3'>
        <div className=''>
        <div className="mb-8 flex items-center justify-between sm:mb-3">
            <span className="font-serif text-[30px] font-semibold md:text-[20px] text-center flex-1 sm:flex sm:pl-[10px]">
              {item?.name} Collection
            </span>
            <Link
              to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}`}
              className="border-b text-[20px] font-semibold md:text-[20px] sm:text-[15px] ml-auto mr-[79px] sm:mr-[10px]"
              style={{ color: data.color.secondaryColor, borderColor: data.color.secondaryColor }}
            >
              View All
            </Link>
          </div>

          <div className='grid grid-cols-5 gap-[10px] lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 sm:gap-[10px] pl-[130px] pr-[130px] sm:p-[2px]'>
            {item?.products
              ?.slice(0, isMobile ? 2 : 5)
              ?.map(product => (
                <ProductCard key={product.id} product={product} />
              ))}
          </div>
        </div>
      </section>
    </div>
  );

  return (
    <>
      {/* First Poster */}
      <CustomPoster bannerIndex={0} />
      {/* MetalType Section */}
      <MetalType metalData={metalData} />
      
      {finalCollections.length > 0 && (
        <>
          <ProductCollection item={finalCollections[0]} />
        </>
      )}

      {/* ShopByCollection Section */}
      <ShopByCollection data={collection} />

      {/* Conditional Rendering for Collections and Posters */}
      {finalCollections.slice(1, 6).map((item, index) => (
        <React.Fragment key={index}>
          <ProductCollection item={item} />
          <CustomPoster bannerIndex={index+1} />
        </React.Fragment>
      ))}

      {/* Additional banners and collections if available */}
      {finalCollections.length > 6 && (
        <>
          <ProductCollection item={finalCollections[6]} />
          <CustomPoster bannerIndex={6} />
        </>
      )}

      {/* Commented sections for future use */}
      {/* Gold jewellery */}
      {/* Silver jewellery */}
      {/* Platinum jewellery */}
      {/* Diamond jewellery */}
      {/* <CustomPoster bannerIndex={8}/> */}
    </>
  );
};

export default CollectionsLists;
