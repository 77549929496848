import useDeviceDetect from 'hooks/useDeviceDetect';
import ViewProfileMobile from 'components/profile/ViewProfileMobileView';
import UserProfileDesktop from 'components/profile/ViewProfileDesktopView';
const ProfilePage = () => {
  const { isMobile } = useDeviceDetect(); 

  return (
    <>
    {isMobile && <ViewProfileMobile/>}
     {!isMobile && <UserProfileDesktop/>}
    </>
  );
};
export default ProfilePage;