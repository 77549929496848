import { createSlice } from '@reduxjs/toolkit';
import { mergeAndDeduplicate,mergeAndDeduplicateById } from 'helper/internal';

const initialState = {
    menuData: {
        data: [],
        isLoading: {
            localStore: true,
            mainStore: true,
        },
    },
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuData(state, action) {
            const { type, data } = action.payload;
            if (type === 'localStore') {
                state.menuData.data = mergeAndDeduplicateById(data, state.menuData.data);
                state.menuData.isLoading.localStore = false;
            } else if (type === 'mainStore') {
                state.menuData.data = mergeAndDeduplicateById(data,state.menuData.data );
                state.menuData.isLoading.mainStore = false;
            }

            const allLoaded = !state.menuData.isLoading.localStore && !state.menuData.isLoading.mainStore;
            state.menuData.isLoading.global = !allLoaded;
        },
    },
});

export const { setMenuData } = menuSlice.actions;
export default menuSlice.reducer;
