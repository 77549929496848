import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useHistoryTracker = () => {
    const [history, setHistory] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setHistory((prevHistory) => [...prevHistory, location.pathname]);
    }, [location]);

    const goBack = () => {
        console.log("history",history);
        if (history.length > 1) {
            const previousPath = history[history.length - 2];
            if(previousPath==='/login'){
                navigate('/'); // Default to home if no history
            }else{
                navigate(previousPath);
            }
        } else {
            navigate('/'); // Default to home if no history
        }
    };

    return goBack;
};

export default useHistoryTracker;
