import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { useGetBackgroundColor } from '../../../src/api/index';

const OrderItemDetails = ({
  orderDetails,
  itemDetailsPage,
  sendDataToParent,
}) => {
  const { isMobile } = useDeviceDetect();
  const { data } = useGetBackgroundColor({ onLoad: true });
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  console.log("orderDetails",orderDetails)
  return (
    <>
      <div className='sm:p-4'>
        {/* Main Wrapper */}
        <div>
          {/* Left Section: Order Info */}
          {isMobile && <div className='lg:col-span-2'>
            <div className={`${!isMobile ? 'flex justify-between' : ''}`}>
              <div className='mb-4'>
                <h2 className='text-xl font-semibold'>
                  Order ID: {orderDetails?.plainOrderNumber}
                </h2>
                <p className='text-sm text-gray-500'>
                  Placed on {orderDetails?.created}
                </p>
                <p className='text-bold text-sm'>
                  Total Price: ₹{orderDetails?.price?.toLocaleString()}
                  {/* <span className="ml-2 text-[#EC4899] cursor-pointer">Price Details</span> */}
                </p>
              </div>
              {!isMobile && (
                <div>
                  <button
                    className='rounded-md px-4 py-2 text-white'
                    onClick={() => sendDataToParent(!itemDetailsPage)}
                    style={{ backgroundColor: data.color.ctaPrimary }}
                  >
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </button>
                </div>
              )}
            </div>
            <div className='mx-auto w-full rounded-lg border bg-white'>
              {/* Order Package Info */}
              <div className='mb-4 border-b bg-[#f2f2f2] p-4'>
                <h3 className='text-sm text-gray-600'>Package 1</h3>
                <p className="text-gray-500 text-xs font-bold">{orderDetails?.statusDisplay ==="Fulfilled" ? "Order Delivered" : (orderDetails?.statusDisplay === "Unfulfilled" && orderDetails?.paymentStatus ==="Fully charged")? "Order Accepted. Arriving soon":(orderDetails?.statusDisplay ==="Unfulfilled" ? "Order Not Accepted yet" : "Order "+orderDetails?.statusDisplay)}</p>                               
                {/* <p className="text-gray-500 text-xs font-bold">Arriving on 26th Aug</p> */}
              </div>
              {orderDetails?.lines?.map((line, index) => {
                return (
                  <>
                    {/* Product Information */}
                    <div className='mb-4 flex p-4'>
                      {/* Product Image */}
                      <div className='mb-4 h-28 w-28 sm:mb-0'>
                        {!line?.dbpVariantData?.images &&
                          !line?.variant?.product?.images && (
                            <img
                              src='https://via.placeholder.com/100'
                              alt='Product'
                              className='h-full w-full rounded-md object-cover'
                            />
                          )}
                        {line?.dbpVariantData?.images[0] ? (
                          <img
                            src={line?.dbpVariantData?.images[0]}
                            alt='Product'
                            className='h-32 w-32 rounded-lg object-cover'
                          />
                        ) : (
                          <img
                            src={line?.variant?.product?.images[0]?.url}
                            alt='Product'
                            className='h-32 w-32 rounded-lg object-cover'
                          />
                        )}
                      </div>

                      {/* Product Details */}
                      <div className='ml-4 text-left'>
                        {/* <p className="text-black font-semibold text-base">SHAYA</p> */}
                        <p className='mb-1 text-sm text-gray-500'>
                          {line?.variant?.product?.name
                            ? line?.variant?.product?.name
                            : line?.dbpVariantData?.product?.name}
                        </p>
                        <p className='mb-2 text-sm text-gray-500'>
                          Quantity: {line?.quantity}
                        </p>
                        <p className='text-lg font-semibold text-black sm:text-[20px] text-[20px]'>
                          ₹
                          {parseInt(
                            JSON.parse(
                              orderDetails?.metadata[index]?.replace(/'/g, '"')
                            )['Total Price']
                          )?.toLocaleString()}
                        </p>
                        <p className="font-semibold mt-2" style={{color : '#651fff'}}>{orderDetails?.statusDisplay ==="Fulfilled" ? "Delivered" : (orderDetails?.statusDisplay === "Unfulfilled" && orderDetails?.paymentStatus ==="Fully charged")? "Accpted":(orderDetails?.statusDisplay ==="Unfulfilled" ? "Under Process" : orderDetails?.statusDisplay)}</p>                               
                      </div>
                    </div>
                  </>
                );
              })}

              {/* Actions: Invoice Options */}
              {/* <div className="flex justify-center space-x-6 mb-[8%]">
            <button className="flex flex-col items-center text-purple-600">
            <div className={`w-12 h-12 sm:w-9 sm:h-9 rounded-full flex items-center justify-center p-3 border border-[#9333EA]`}>
            <FontAwesomeIcon icon={faEnvelope} className="w-7 h-7 sm:w-5 sm:h-5"/>
            </div>
                <span className="text-md sm:text-xs">Email Invoice</span>
            </button>
            <button className="flex flex-col items-center text-purple-600">
            <div className={`w-12 h-12 sm:w-9 sm:h-9 rounded-full flex items-center justify-center p-3 border border-[#9333EA]`}>
            <FontAwesomeIcon icon={faPrint} className="w-7 h-7 sm:w-5 sm:h-5"/>
            </div>
                <span className="text-md sm:text-xs">Print Invoice</span>
            </button>
            </div> */}

              {/* Return Requested */}
              {/* <div className="text-start p-4">
            <div className="text-purple-600 font-semibold flex gap-2">
            <div className={`w-5 h-5 rounded-full bg-purple-600 text-white flex items-center justify-center font-semibold p-3`}>
            <FontAwesomeIcon icon={faCheck}/>
            </div>
            Return Requested</div>
            </div> */}
            </div>
            <div className='my-4 border'>
              <h4 className='text-md border-b bg-[#f2f2f2] p-3'>
                Delivery Address
              </h4>
              <div className='flex items-start justify-between p-3 font-bold'>
                <div className='text-sm'>
                  {console.log('orderDetauls', orderDetails)}
                  {orderDetails?.shippingAddress ? (
                    <>
                      <p>
                        {orderDetails?.billingAddress?.firstName}{' '}
                        {orderDetails?.billingAddress?.lastName}
                      </p>
                      <p>
                        {orderDetails?.billingAddress?.streetAddress1}{' '}
                        {orderDetails?.billingAddress?.streetAddress2}{' '}
                        {orderDetails?.billingAddress?.city},{' '}
                        {orderDetails?.billingAddress?.postalCode}
                      </p>
                      <p>{orderDetails?.billingAddress?.countryArea}, India</p>
                    </>
                  ) : (
                    <>
                      <p>
                        {orderDetails?.billingAddress?.firstName}{' '}
                        {orderDetails?.billingAddress?.lastName}
                      </p>
                      <p>
                        {orderDetails?.billingAddress?.streetAddress1}{' '}
                        {orderDetails?.billingAddress?.streetAddress2}{' '}
                        {orderDetails?.billingAddress?.city},{' '}
                        {orderDetails?.billingAddress?.postalCode}
                      </p>
                      <p>{orderDetails?.billingAddress?.countryArea}, India</p>
                    </>
                  )}
                </div>
                {/* <button className="text-purple-600">Edit</button> */}
              </div>
            </div>
            {/* Address Info */}
            <div className='mb-4 border'>
              <h4 className='text-md border-b bg-[#f2f2f2] p-3'>
                Billing Address
              </h4>
              <div className='flex items-start justify-between p-3 font-bold'>
                <div className='text-sm'>
                  {orderDetails?.billingAddress && (
                    <>
                      <p>
                        {orderDetails?.billingAddress?.firstName}{' '}
                        {orderDetails?.billingAddress?.lastName}
                      </p>
                      <p>
                        {orderDetails?.billingAddress?.streetAddress1}{' '}
                        {orderDetails?.billingAddress?.streetAddress2}{' '}
                        {orderDetails?.billingAddress?.city},{' '}
                        {orderDetails?.billingAddress?.postalCode}
                      </p>
                      <p>{orderDetails?.billingAddress?.countryArea}, India</p>
                    </>
                  )}
                </div>
                {/* <button className="text-purple-600">Edit</button> */}
              </div>
            </div>

            {/* Price Details */}
            <div className='mb-4 border'>
              <h4 className='text-md border-b bg-[#f2f2f2] p-3'>
                Price Details
              </h4>
              <div className='flex gap-[15%] p-3 text-sm'>
                <div>
                  <p>Product Value</p>
                  <p>Shipping Charges</p>
                  <p className='mb-4 font-bold'>Total Amount</p>
                  {/* <p>Payment Method</p> */}
                </div>
                <div>
                  <p>₹{orderDetails?.price?.toLocaleString()}</p>
                  <p>Free</p>
                  <p className='mb-4 font-bold'>
                    ₹{orderDetails?.price?.toLocaleString()}
                  </p>
                  {/* <p>PayU</p> */}
                </div>
              </div>
            </div>
          </div>}
          {!isMobile && <div className='grid grid-cols-2 gap-6'>
            <div>
            <div className={`${!isMobile ? 'flex justify-between' : ''}`}>
              <div className='mb-4'>
                <h2 className='text-xl font-semibold'>
                  Order ID: {orderDetails?.plainOrderNumber}
                </h2>
                <p className='text-sm text-gray-500'>
                  Placed on {orderDetails?.created}
                </p>
                <p className='text-bold text-sm'>
                  Total Price: ₹{orderDetails?.price?.toLocaleString()}
                  {/* <span className="ml-2 text-[#EC4899] cursor-pointer">Price Details</span> */}
                </p>
              </div>
              {!isMobile && (
                <div>
                  <button
                    className='rounded-md px-4 py-2 text-white'
                    onClick={() => sendDataToParent(!itemDetailsPage)}
                    style={{ backgroundColor: data.color.ctaPrimary }}
                  >
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </button>
                </div>
              )}
            </div>
            <div className='mx-auto w-full rounded-lg border bg-white'>
              {/* Order Package Info */}
              <div className='mb-4 border-b bg-[#f2f2f2] p-4'>
                <h3 className='text-sm text-gray-600'>Package 1</h3>
                <p className="text-gray-500 text-xs font-bold">{orderDetails?.statusDisplay ==="Fulfilled" ? "Order Delivered" : (orderDetails?.statusDisplay === "Unfulfilled" && orderDetails?.paymentStatus ==="Fully charged")? "Order Accepted. Arriving soon":(orderDetails?.statusDisplay ==="Unfulfilled" ? "Order Not Accepted yet" : "Order "+orderDetails?.statusDisplay)}</p>
                {/* <p className="text-gray-500 text-xs font-bold">Arriving on 26th Aug</p> */}
              </div>
              {orderDetails?.lines?.map((line, index) => {
                return (
                  <>
                    {/* Product Information */}
                    <div className='mb-4 flex p-4'>
                      {/* Product Image */}
                      <div className='mb-4 h-28 w-28 sm:mb-0'>
                        {!line?.dbpVariantData?.images &&
                          !line?.variant?.product?.images && (
                            <img
                              src='https://via.placeholder.com/100'
                              alt='Product'
                              className='h-full w-full rounded-md object-cover'
                            />
                          )}
                        {line?.dbpVariantData?.images[0] ? (
                          <img
                            src={line?.dbpVariantData?.images[0]}
                            alt='Product'
                            className='h-32 w-32 rounded-lg object-cover'
                          />
                        ) : (
                          <img
                            src={line?.variant?.product?.images[0]?.url}
                            alt='Product'
                            className='h-32 w-32 rounded-lg object-cover'
                          />
                        )}
                      </div>

                      {/* Product Details */}
                      <div className='ml-4 text-left'>
                        {/* <p className="text-black font-semibold text-base">SHAYA</p> */}
                        <p className='mb-1 text-sm text-gray-500'>
                          {line?.variant?.product?.name
                            ? line?.variant?.product?.name
                            : line?.dbpVariantData?.product?.name}
                        </p>
                        <p className='mb-2 text-sm text-gray-500'>
                          Quantity: {line?.quantity}
                        </p>
                        <p className='text-lg font-semibold text-black'>
                          ₹
                          {parseInt(
                            JSON.parse(
                              orderDetails?.metadata[index]?.replace(/'/g, '"')
                            )['Total Price']
                          )?.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}

              {/* Actions: Invoice Options */}
              {/* <div className="flex justify-center space-x-6 mb-[8%]">
            <button className="flex flex-col items-center text-purple-600">
            <div className={`w-12 h-12 sm:w-9 sm:h-9 rounded-full flex items-center justify-center p-3 border border-[#9333EA]`}>
            <FontAwesomeIcon icon={faEnvelope} className="w-7 h-7 sm:w-5 sm:h-5"/>
            </div>
                <span className="text-md sm:text-xs">Email Invoice</span>
            </button>
            <button className="flex flex-col items-center text-purple-600">
            <div className={`w-12 h-12 sm:w-9 sm:h-9 rounded-full flex items-center justify-center p-3 border border-[#9333EA]`}>
            <FontAwesomeIcon icon={faPrint} className="w-7 h-7 sm:w-5 sm:h-5"/>
            </div>
                <span className="text-md sm:text-xs">Print Invoice</span>
            </button>
            </div> */}

              {/* Return Requested */}
              {/* <div className="text-start p-4">
            <div className="text-purple-600 font-semibold flex gap-2">
            <div className={`w-5 h-5 rounded-full bg-purple-600 text-white flex items-center justify-center font-semibold p-3`}>
            <FontAwesomeIcon icon={faCheck}/>
            </div>
            Return Requested</div>
            </div> */}
            </div>
            </div>
            <div>
            <div className='my-4 border'>
              <h4 className='text-md border-b bg-[#f2f2f2] p-3'>
                Delivery Address
              </h4>
              <div className='flex items-start justify-between p-3 font-bold'>
                <div className='text-sm'>
                  {console.log('orderDetauls', orderDetails)}
                  {orderDetails?.shippingAddress ? (
                    <>
                      <p>
                        {orderDetails?.billingAddress?.firstName}{' '}
                        {orderDetails?.billingAddress?.lastName}
                      </p>
                      <p>
                        {orderDetails?.billingAddress?.streetAddress1}{' '}
                        {orderDetails?.billingAddress?.streetAddress2}{' '}
                        {orderDetails?.billingAddress?.city},{' '}
                        {orderDetails?.billingAddress?.postalCode}
                      </p>
                      <p>{orderDetails?.billingAddress?.countryArea}, India</p>
                    </>
                  ) : (
                    <>
                      <p>
                        {orderDetails?.billingAddress?.firstName}{' '}
                        {orderDetails?.billingAddress?.lastName}
                      </p>
                      <p>
                        {orderDetails?.billingAddress?.streetAddress1}{' '}
                        {orderDetails?.billingAddress?.streetAddress2}{' '}
                        {orderDetails?.billingAddress?.city},{' '}
                        {orderDetails?.billingAddress?.postalCode}
                      </p>
                      <p>{orderDetails?.billingAddress?.countryArea}, India</p>
                    </>
                  )}
                </div>
                {/* <button className="text-purple-600">Edit</button> */}
              </div>
            </div>
            {/* Address Info */}
            <div className='mb-4 border'>
              <h4 className='text-md border-b bg-[#f2f2f2] p-3'>
                Billing Address
              </h4>
              <div className='flex items-start justify-between p-3 font-bold'>
                <div className='text-sm'>
                  {orderDetails?.billingAddress && (
                    <>
                      <p>
                        {orderDetails?.billingAddress?.firstName}{' '}
                        {orderDetails?.billingAddress?.lastName}
                      </p>
                      <p>
                        {orderDetails?.billingAddress?.streetAddress1}{' '}
                        {orderDetails?.billingAddress?.streetAddress2}{' '}
                        {orderDetails?.billingAddress?.city},{' '}
                        {orderDetails?.billingAddress?.postalCode}
                      </p>
                      <p>{orderDetails?.billingAddress?.countryArea}, India</p>
                    </>
                  )}
                </div>
                {/* <button className="text-purple-600">Edit</button> */}
              </div>
            </div>

            {/* Price Details */}
            <div className='mb-4 border'>
              <h4 className='text-md border-b bg-[#f2f2f2] p-3'>
                Price Details
              </h4>
              <div className='flex gap-[15%] p-3 text-sm'>
                <div>
                  <p>Product Value</p>
                  <p>Shipping Charges</p>
                  <p className='mb-4 font-bold'>Total Amount</p>
                  {/* <p>Payment Method</p> */}
                </div>
                <div>
                  <p>₹{orderDetails?.price?.toLocaleString()}</p>
                  <p>Free</p>
                  <p className='mb-4 font-bold'>
                    ₹{orderDetails?.price?.toLocaleString()}
                  </p>
                  {/* <p>PayU</p> */}
                </div>
              </div>
            </div>
            </div>
          </div>}
          {/* Right Section: Order Summary */}
          {/* <div> */}
          {/* <div className="font-semibold mb-4 p-3 text-xs  border border-[#9333EA] flex gap-2">
          <div className={`w-4 h-4 rounded-full flex items-center justify-center p-3 bg-[#9333EA] text-white`}>
            <FontAwesomeIcon icon={faIndianRupee} className="w-3 h-3"/>
          </div>You have earned 94 xCLusive Points with this product. Please redeem your points on your CaratLane app, before they expire on 11th Mar 2025.
          </div> */}

          {/* Delivery Info */}
          {/* <div className="mb-4 border">
            <h4 className="border-b p-3 bg-[#f2f2f2] text-md">Expected Delivery Date</h4>
            <p className="p-3 font-bold">26th Aug</p>
          </div> */}
          {/* gift summary */}
          {/* <div className="mb-4 border">
            <h4 className="border-b p-3 bg-[#f2f2f2] text-md">Gift Summary</h4>
            <div className="text-sm p-3">
            <p className="font-bold">Packaging</p>
            <p>Standard Packaging</p>
            <p>Shaya Package</p>
            </div>
          </div> */}
          {/* Shipping Method */}
          {/* <div className="mb-4 border">
            <h4 className="border-b p-3 bg-[#f2f2f2] text-md">Shipping Method</h4>
            <p className="p-3 font-bold">Standard Shipping + Free Delivery</p>
          </div> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default OrderItemDetails;
