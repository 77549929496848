import { useSelector } from 'react-redux';
import { useGetBackgroundColor } from "../../../src/api/index";
import React, { useState } from 'react';

const PromiseSection = ({ data:dataPromise }) => {
  const storeData = useSelector(state => state.storeData);
  const { data } = useGetBackgroundColor({ onLoad: true });
  const [popupData,setPopupData] = useState(null);
  const [showPopup,setShowPopup] = useState(false);
  const Popup = ({ policy, onClose }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-4xl mx-4 h-4/5">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close"
          >
            ✕
          </button>
  
          {/* Header */}
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-800">{policy?.question}</h2>
          </div>
  
          {/* Content */}
          <div className="p-6 overflow-auto h-[calc(100%-80px)]">
            <p className="text-gray-700 leading-relaxed">{policy?.answer}</p>
          </div>
        </div>
      </div>
    );
  };
  
  const closePopup = () => {
    setShowPopup(false);
    setPopupData(null);
  };
  return (
    <>
    <div>{showPopup && <Popup policy={popupData} onClose={closePopup} />}</div>
    <section className='py-16 sm:py-5 mt-[-4px]' style={{backgroundColor : data.color.primaryColor}}>
      <div className='container'>
        <div className='grid grid-cols-2 items-center lg:grid-cols-1 lg:gap-7'>
          <h4 className='font-serif text-left text-[45px] font-medium leading-[50px] text-white lg:text-center sm:text-[30px] sm:leading-none sm:pl-[0px] pl-[150px] '>
            {storeData?.name?.replace(","," ")}
            <span className='block text-[75px] sm:text-[50px] font-serif'>
              Promise
            </span>
          </h4>

          <div className='flex flex-wrap justify-center gap-y-8 pr-[75px] sm:pr-[0px]'>
            {dataPromise?.faqs.map(({ logo, question,answer }, i) => {
              return (
                <span className='inline-block w-1/3 text-center'>
                  <span className='mx-auto mb-2 block aspect-square w-[130px] rounded-full border border-[#1F5EFF] sm:w-[60px]'>
                    <img
                      src={logo}
                      onClick={()=>{setPopupData(dataPromise?.faqs[i]); setShowPopup(true);}}
                      className='mx-auto my-0 block h-[130px] w-[130px] rounded-full border border-[#1F5EFF] sm:h-[60px] sm:w-[60px]'
                      alt={question}
                    />
                  </span>
                  <span className='inline-block w-28 text-xs text-white sm:text-[12px]'>
                    {question}
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default PromiseSection;
