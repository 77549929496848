import { ProductCard } from 'components/index';
import React,{useEffect, useState} from 'react';

const ProductList = ({dataFinal, filter, sortFilter}) => {

const [productData,setProductData] = useState(dataFinal);

useEffect(()=>{
  console.log("This is called dataFinal::::: ",dataFinal)   
  setProductData(dataFinal);
 },[dataFinal])

useEffect(()=>{
  if(filter?.length){
    console.log("This is called ::::: ",filter) 
     // Create a variable to hold the filtered products
     const filteredDataFromFilter = dataFinal.reduce((acc, category) => {
       const filteredSub = category.sub.map(subcategory => {
         const filteredProducts = subcategory.products.filter(product => {
           console.log("product",product, filter);
           return filter.every(({ type, value }) => product[type] === value);
         });
         return { ...subcategory, products: filteredProducts };
       }).filter(sub => sub.products.length > 0);
     
       if (filteredSub.length > 0) {
         acc.push({ ...category, sub: filteredSub });
       }
       return acc;
     }, []);
     console.log("Filtered Data", filteredDataFromFilter);
     setProductData(filteredDataFromFilter);
  }else{
    setProductData(dataFinal);
  }
},[filter])

useEffect(()=>{
  if(sortFilter){
     // Create a variable to hold the filtered products
     const sortProducts = dataFinal.reduce((acc, category) => {
      const filteredSub = category.sub.map(subcategory => {
         // Sort products based on the sortFilter value
      let sortedData = subcategory.products; // Clone the array to avoid mutating the original
      switch (sortFilter) {
        case 'weightLowToHigh':
        sortedData = subcategory.products.sort((a, b) => parseFloat(a.netWeight) - parseFloat(b.netWeight));
        break;
      case 'weightHighToLow':
        sortedData = subcategory.products.sort((a, b) => parseFloat(b.netWeight) - parseFloat(a.netWeight));
        break;
      case 'priceLowToHigh':
        sortedData = subcategory.products.sort((a, b) => parseFloat(a.displayPrice) - parseFloat(b.displayPrice));
        break;
      case 'priceHighToLow':
        sortedData = subcategory.products.sort((a, b) => parseFloat(b.displayPrice) - parseFloat(a.displayPrice));
        break;
      default:
        break;
      }

      // Return the subcategory with sorted products
      return { ...subcategory, products: sortedData };
      }).filter(sub => sub.products.length > 0);
    
      if (filteredSub.length > 0) {
        acc.push({ ...category, sub: filteredSub });
      }
      return acc;
    }, []);
     console.log('sortProducts 61:>> ', sortProducts);
     setProductData(sortProducts);
  }else{
    setProductData(dataFinal);
  } 
},[sortFilter])
  console.log("dataFinal",dataFinal);
  return (
    <>
    <main className='flex-1 mb-5'>
      <div className='grid grid-cols-5 gap-[10px] lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 sm:gap-[5px]'>
        {productData.length > 0 &&
          productData.map(product =>
            product && product?.sub && product?.sub?.map(subProduct =>
              subProduct?.products?.map(prod => (
                <ProductCard key={prod.id} product={prod} />
              ))
            )
          )}
      </div>   
    </main>
    </>
  ); 
};

export default ProductList;
