export const mergeAndDeduplicate = (array1, array2) => {               
    const mergedArray = [...array1, ...array2];   
    const uniqueMap = new Map();

    for (const item of mergedArray) {
        if (!uniqueMap.has(item.name)) {                 
            uniqueMap.set(item.name, {
                name: item.name,
                img:item?.img,
                sub: [...item.sub]
            });
        } else {                 
            const existingItem = uniqueMap.get(item.name);
            
            const subUniqueMap = new Map();

            for (const subItem of existingItem.sub) {
                subUniqueMap.set(subItem.name, subItem);
            }
            for (const subItem of item.sub) {
                subUniqueMap.set(subItem.name, subItem);
            }

            existingItem.sub = Array.from(subUniqueMap.values());
        }
    }
    
    return Array.from(uniqueMap.values());
};

export const mergeAndDeduplicateById = (array1, array2) => {               
    const mergedArray = [...array1, ...array2];   
    const uniqueMap = new Map();

    for (const item of mergedArray) {
        if (!uniqueMap.has(item.id)) {                 
            uniqueMap.set(item.id, {
            id: item.id, 
            name: item.name,
            img:item?.img,
            sub: [...item.sub]
            });
        } else {                 
            const existingItem = uniqueMap.get(item.id);
            
            const subUniqueMap = new Map();

            for (const subItem of existingItem.sub) {
                subUniqueMap.set(subItem.id, subItem);
            }
            for (const subItem of item.sub) {
                subUniqueMap.set(subItem.id, subItem);
            }

            existingItem.sub = Array.from(subUniqueMap.values());
        }
    }
    
    return Array.from(uniqueMap.values());
};

export const getCategoryProducts=(data)=> {    
    const results = [];
     
    for (const mainCategory of data) {        
        const categoryResult = {
            name: mainCategory.name,
            id: mainCategory.id,
            products: []
        };
        
        const productIds = new Set();
        
        for (const subCategory of mainCategory.sub) {            
            for (const product of subCategory.products) {                
                if (!productIds.has(product.id)) {
                    productIds.add(product.id);
                    categoryResult.products.push(product);
                }
            }
        }
        
        results.push(categoryResult);
    }
 
    return results;
}

export const sortByProductLength=(arr)=> {
    return arr.sort((a, b) => b.product.length - a.product.length);
}