import Loader from "components/Loader/Loader";
import StoreList from "components/storeList/StoreList";
import React, { useEffect, useState } from "react";
import { useStoreDataApi } from "api";

const StoreListPage = () => {
    const [loading, setLoading] = useState(true);
    const { data: storeData } = useStoreDataApi({ onLoad: true });

    useEffect(() => {
    const timer = setTimeout(() => {
    setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
    }, []);
    return (
      <>
      {loading ? <Loader/> : <StoreList store={storeData}></StoreList>}
      </>
    );
}

export default StoreListPage;