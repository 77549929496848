import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetBackgroundColor } from "../../../src/api/index";

const FindStore = ({banners}) => {
  const preferredImage = banners?.find(banner => banner.prefered)?.image;
  console.log("preferredImage",preferredImage)
  const [isDesktop, setIsDesktop] = useState(false);
  const { data } = useGetBackgroundColor({ onLoad: true });
  console.log("isDesktop",isDesktop);
  // Handle screen size changes
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 640); // "sm" breakpoint in Tailwind is 640px
    };

    // Check initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (<>
    {!isDesktop &&
      <>
      <div
        className=" sm:flex-row items-center pt-[8px] sm:pt-1 pb-[25px] sm:p-3"
      >
          <div className="w-full sm:w-1/1">
            <img
              className="h-[420px] w-[400px] sm:h-full w-full px-[15px] sm:px-0"
              src={preferredImage ? preferredImage :"assets/img/findstore/finf_our_store_image.png"}
              alt=""
            />
          </div>    
        <div className="w-full text-center px-[10%] sm:px-[2%] mt-[22px] sm:mt-3 sm:mb-5">
          <h1 className="text-[10px] sm:text-[18px] text-[#090909] font-medium mb-[10px] sm:mb-[8px] px-[5px] sm:px-0">
            Prefer to check your choices in-store ?
          </h1>
          <p className="text-[10px] sm:text-[11px] text-[#404040] mb-[15px] sm:mb-[21px] sm:ml-[9px]">
            Explore our exquisite collection up close. Find the nearest store and
            experience our jewelry firsthand.
          </p>
          <div>
            {" "}
            <Link to='/storeList' className="text-[13px] sm:text-[15px] px-[36px] sm:px-[39px] py-[15px] text-white font-inter rounded-[5px] font-semibold " style={{backgroundColor : data.color.ctaPrimary}}>
              Find our Store
              </Link>
          </div>
        </div>
      </div>
      </>
    }
    {isDesktop &&
      <>
      <div
        className="flex items-center"
      >
          <div className="w-full">
            <img
              className="h-[475px] w-[915px] w-full"
              src={preferredImage ? preferredImage :"assets/img/findstore/finf_our_store_image.png"}
              alt=""
            />
          </div>    
        <div className="w-full text-center mt-[22px] sm:mt-3 sm:mb-5">
          <h1 className="text-[30px] text-[#090909] font-medium mb-[10px] px-[5px]">
            Prefer to check your choices in-store ?
          </h1>
          <p className="text-[20px] px-[15%] text-[#404040] mb-[35px]">
            Explore our exquisite collection up close. Find the nearest store and
            experience our jewelry firsthand.
          </p>
          <div>
            {" "}
            <Link to='/storeList' className="text-[13px] sm:text-[10px] px-[36px] sm:px-[39px] py-[15px] text-white rounded-[5px] font-semibold" style={{backgroundColor : data.color.ctaPrimary}}>
              Find our Store
            </Link>
          </div>
        </div>
      </div>
      </>
    }
    </>
  );
};

export default FindStore;
