import { useSelector } from 'react-redux';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade, Scrollbar } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetBackgroundColor } from "../../../src/api/index";
const Category = () => {
  const menuData = useSelector(state => state.menu?.menuData);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 640); // Set initial value based on window size
  const { data:themeColor } = useGetBackgroundColor({ onLoad: true });
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 640); // "sm" breakpoint in Tailwind is 640px
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const firstItems = isDesktop ? menuData?.data || [] : menuData?.data?.slice(0, 4) || [];
  const firstItems = menuData?.data || [];
  // const nextFourItems = menuData?.data?.slice(4, 8) || [];

  const renderSwiperSlides = (items) =>
        items.map(({ id, img, name }, i) => (
      <SwiperSlide key={i} className='h-[160] w-[160]'>
        <Link
          to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${id}`}
          className="text-[20px] font-semibold md:text-[20px] sm:text-[15px] h-[160] w-[160]" //border-b border-black 
        >
          {/* //bg-[#E9F0FF]  */}
          <div className="flex aspect-square overflow-hidden h-[160] w-[160]"> 
            <img
              src={img}
              alt={name}
              className={`h-[160] w-[160]`} //bg-ocean1 rounded-[8px] 
              // style={{borderColor:themeColor?.color?.primaryColor || 'transparent', border:"1px solid"}}
            />
          </div>
          <span className="category-name mt-2 block text-center text-base font-semibold sm:text-[16px]">
            {name}
          </span>
        </Link>
      </SwiperSlide>
    ));

  const swiperSettings = {
    spaceBetween: 10,
    // slidesPerView: 3,
    breakpoints: {
      320: { slidesPerView: 3.5, spaceBetween: 10 },
      480: { slidesPerView: 3.5, spaceBetween: 10 },
      768: { slidesPerView: 3, spaceBetween: 10 },
      1024: { slidesPerView: 8, spaceBetween: 10 },
    },
  };

  return (
    <section className="w-full pb-8 pt-8">
      {/* <h3 className="section-title block text-center font-extrabold">Shop by Category</h3> */}
      
      {/* First Swiper */}
      <Swiper {...swiperSettings} className="sm:ml-[-10px]">
        {renderSwiperSlides(firstItems)}
      </Swiper>
  
    </section>
  );
  
};

export default Category;
