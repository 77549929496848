import { capitalizeFirstLetter } from "functions/global";
import { useMarquee } from "../../api/marquee";
import { CurrencyPrefix } from "utils";
import { generateMCXRates } from "functions";
import { useMarqueeBanner } from "./marqueeBanner";
import { useCollection } from "./collection";
import { useBanner } from "./banners";

export const useMarqueeApi = (onLoad) => {
    const { executeQuery, data, loading, error, refetch } = useMarquee(onLoad);
    let marqueeText = data;
    let response = data;
    if (data) {
        if (data?.loading) {
            marqueeText = "Loading..."
        } else if (data?.error) {
            marqueeText = "Something Went Wrong! Please Reload"
        } else {

            const rates = data?.storeDetails?.metalRates;

            const rateEntries = rates ? Object.entries(generateMCXRates(rates)) : [];

            // Generate the text for the marquee
            marqueeText = rateEntries?.filter(([key, value]) => key.includes("Rate") && !key.includes("diamond") && !key.includes("designBankDiamondRate") && value !== 0)
                .map(([key, value]) =>
                    `${capitalizeFirstLetter(key.split("Rate")[0])}: ${CurrencyPrefix()}${value?.toFixed(2)}/-`
                )
                .join("  ");
        }
    }
    return {
        executeQuery,
        data: marqueeText,
        response,
        loading,
        error,
        refetch,
    };
};

export const useMarqueeBannerApi = (onLoad) => {
    const { executeQuery, data, loading, error, refetch } = useMarqueeBanner(onLoad);

    let marqueeBannerData = data?.publishedMarqueeBannerDetails || null;

    let video = null;
    let images = [];

    if (marqueeBannerData) {
        for (const item of marqueeBannerData || []) {
            if (item?.published) {
                if (item?.isVideo) {
                    video = {video:item.file, ctaUrl: item.ctaUrl};
                } else {
                    images.push({image : item.file, ctaUrl: item.ctaUrl});
                }
            }
        }

    }

    marqueeBannerData = { video, img: images }
    return {
        executeQuery,
        data: marqueeBannerData,
        loading,
        error,
        refetch,
    };
}
 
export const useCollectionApi = ({ onLoad }) => {
    const { executeQuery, data, loading, error, refetch } = useCollection({ onLoad });
    const collections = data?.collections?.edges || null

    return {
        executeQuery,
        data: collections,
        loading,
        error,
        refetch,
    };

}

export const useBannerApi = ({ onLoad }) => {
    const { executeQuery, data, loading, error, refetch } = useBanner({ onLoad });
    let banners = data?.publishedBannerDetails || null;
    let finalData = []
    if (banners) {
        finalData = banners?.filter(({ published }) => published)?.map((banner)=>{
            console.log("banner",banner)
            return {image:banner?.image,ctaUrl:banner?.ctaUrl,prefered:banner?.prefered}
        })
    }

    return {
        executeQuery,
        data: finalData,
        loading,
        error,
        refetch,
    };

}


