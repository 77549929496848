import React, { useState } from "react";
import "./accountDesktop.css"; // Create CSS styles
import { Link,useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetBackgroundColor } from "../../../src/api/index";

const MyAccountAside = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("myOrders");
  const [selectedNav, setSelectedNav] = useState(null);
  const { data } = useGetBackgroundColor({ onLoad: true });
  const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
  const logout = () => {
    localStorage.removeItem(`vjw-${window.location.hostname}user-det`);   
    navigate({pathname: `/login`})
  };
  return (   
    <>
      {/* Sidebar */}
      <aside className="sidebar">
        <div className="profile-info">
          <h4>{refUserDetails?.firstName + " "+ refUserDetails?.lastName}</h4>
          <p>{refUserDetails?.phoneNumber}</p>
          <Link to="/edit-profile" style={{color : data.color.secondaryColor}}>
            Edit Profile
          </Link>
        </div>
        <div className="menu">
          <h5>ORDERS</h5>
          <ul>
            <li>
                <Link to="/orders" className="flex justify-between items-center text-gray-600" style={{color : selectedNav === 'orderAndReturns' ? 'rgb(136, 99, 251)' : ''}} onClick={() => {setSelectedNav('orderAndReturns')}}>
                  Orders
                </Link>
            </li>
            {/* <li>Payment</li>
            <li>Manage Refunds</li> */}
          </ul>
          <h5>ACCOUNTS</h5>
          <ul>
            <li>
              <Link to="/profile" className="flex justify-between items-center text-gray-600">
                Profile
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <button onClick={logout}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </aside>
      {/* Toast container */}
      <ToastContainer />
      </>
  );
};

export default MyAccountAside;
