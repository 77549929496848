import * as React from 'react';
const ChevronDown = props => (
  <svg
    width={14}
    height={8}
    viewBox='0 0 14 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13 1.85714L7 7L1 1.85714'
      stroke='black'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ChevronDown;
