import React, { useEffect, useState } from 'react'
import { useSendAndValidateOtp } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetBackgroundColor } from "../../../../src/api/index";


const LoginFormDesktop = () => {
    const [formState, setFormState] = useState({ phone: '', });
    const navigate = useNavigate();
    const { data } = useGetBackgroundColor({ onLoad: true });
    const { sendOtp, error, success } = useSendAndValidateOtp();
    const storeData = useSelector((state) => state.storeData);
    const handleChange = (e) => {
        let { name, value } = e.target;

        setFormState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };




    const handleSendOtp = (e) => {
        e.preventDefault();
        const phone = (process.env.REACT_APP_PH_DEFAULT + formState.phone)?.trim()
        if (phone) {
            sendOtp(phone);
        }
    };

    useEffect(() => {
        if (success?.success) {
            navigate('/verify', { state: { phone: (process.env.REACT_APP_PH_DEFAULT + formState.phone)?.trim() } })
        }
    }, [success, navigate, formState.phone])
    return (
        <div>

            <form onSubmit={handleSendOtp}>
                <div className="group relative mb-[10px]">
                    <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 z-10">+91</span>
                    <input
                        type="text"
                        name="phone"
                        maxLength={10}
                        placeholder="Enter Mobile Number"
                        value={formState.phone}
                        onChange={handleChange}
                        className="h-[50px] w-full pl-12 pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
                    />
                </div>
                <button type="submit" className="block w-full submit-btn text-white text-[14px] font-bold py-[16px] px-[65px] rounded-[10px] mb-[30px]" style={{backgroundColor : data.color.ctaPrimary}}>
                    CONTINUE TO LOGIN
                </button>
            </form>
            {error && <p className="text-red-500 text-[14px]">{error.message}</p>}
            {success && <p className="text-green-500 text-[14px]">{success.message}</p>}
            {/* <div className="social-icons flex justify-center items-center gap-[20px] mb-[32px] mt-[35px]">
                <div className="h-[50px] w-[50px] p-[8px] rounded-full shadow-lg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="100%"
                        width="100%"
                        viewBox="0 0 48 48"
                    >
                        <path
                            fill="#fbc02d"
                            d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                        <path
                            fill="#e53935"
                            d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        />
                        <path
                            fill="#4caf50"
                            d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        />
                        <path
                            fill="#1565c0"
                            d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                    </svg>
                </div>
                <div className="h-[50px] w-[50px] p-[8px] rounded-full shadow-lg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="100%"
                        width="100%"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            fill="#1877F2"
                            d="M15 8a7 7 0 00-7-7 7 7 0 00-1.094 13.915v-4.892H5.13V8h1.777V6.458c0-1.754 1.045-2.724 2.644-2.724.766 0 1.567.137 1.567.137v1.723h-.883c-.87 0-1.14.54-1.14 1.093V8h1.941l-.31 2.023H9.094v4.892A7.001 7.001 0 0015 8z"
                        />
                        <path
                            fill="#ffffff"
                            d="M10.725 10.023L11.035 8H9.094V6.687c0-.553.27-1.093 1.14-1.093h.883V3.87s-.801-.137-1.567-.137c-1.6 0-2.644.97-2.644 2.724V8H5.13v2.023h1.777v4.892a7.037 7.037 0 002.188 0v-4.892h1.63z"
                        />
                    </svg>
                </div>
            </div> */}
            <p className="text-[13px] font-medium text-[#525252]">
                New to {storeData?.name?.replace(","," ")}?
                <span>
                    <Link to="/register" style={{color : data.color.secondaryColor}}> Create an account</Link>
                </span>
            </p>
        </div>

    )
}

export default LoginFormDesktop