import useDeviceDetect from 'hooks/useDeviceDetect';
import MyOrderMainContent from 'components/orders/MyOrderMainContent';
import { useGetUserDetailsData } from "api";
import Loader from "components/Loader/Loader";
import React, { useState } from "react";
import MyOrderMainContentMobile from 'components/orders/MyOrderMainContentMobile';

const OrderPage = () => {
  const { isMobile } = useDeviceDetect(); 
  const { data: userData, loading } = useGetUserDetailsData({ onLoad: true });
 
  if(loading){
    return (<Loader />);
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // Get day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
  
    // Add appropriate suffix for the day
    const getDayWithSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };
  
    return `${getDayWithSuffix(day)} ${month} ${year}`;
  };
  if(userData){   
      localStorage.setItem(
          `vjw-${window.location.hostname}user-det`,
          JSON.stringify(userData)
      );    
    console.log("userData",userData?.orders?.edges)
    const orders = userData?.orders?.edges?.map((order)=>{
      order = order?.node;     
      let totalPrice = 0;
      let val;
      let metadatas = [];
      order?.metadata?.some((meta)=>{  
        if(meta?.value?.includes('gemstone_details')){
          let jsonString = meta?.value;
          const splitIndex = jsonString.indexOf(", 'gemstone_details':");

          // Step 2: Split the string into two parts
          const mainPartString = (jsonString.slice(0, splitIndex) + "}")?.replace(/'/g,'"');
          metadatas.push(mainPartString);
          const gemstoneDetailsString = ("{'gemstone_details':"+jsonString.slice(splitIndex).replace("'gemstone_details':", ""))?.replace(/'/g,'"');
          console.log("META :: 48 :: mainPartString",mainPartString);
          console.log("META :: 48 :: gemstoneDetailsString",gemstoneDetailsString);
           // Parse the main part of the JSON string
          let mainData = JSON.parse(mainPartString);
          console.log("mainData :: ",mainData)

          // Parse gemstone_details as an array
          // mainData.gemstone_details = JSON.parse(gemstoneDetailsString);
          totalPrice += parseInt(mainData['Total Price'])
        }else{
          val = meta?.value;
          metadatas.push(val);
          totalPrice += parseInt(JSON.parse(val?.replace(/'/g,'"'))['Total Price'])
        }
      })
      return {
        orderId : order?.id,
        plainOrderNumber: order?.number,
        created: formatDate(order?.created),
        status: order?.status,
        statusDisplay : order?.statusDisplay,
        isPaid: order?.isPaid,
        paymentStatus: order?.paymentStatusDisplay,
        price: totalPrice || 0,
        lines: order?.lines,
        metadata : metadatas,
        shippingAddress: order?.shippingAddress,
        billingAddress: order?.billingAddress
      };      
    });
      
    const cancelledOrders = orders.filter((order)=>order?.status==="CANCELED")
    return (
      <>
      {isMobile && <MyOrderMainContentMobile orders={orders} cancelledOrders={cancelledOrders} />}
       {!isMobile && <MyOrderMainContent orders={orders} cancelledOrders={cancelledOrders}/>}
      </>
    );
  }
};
export default OrderPage;