import React, { useState } from 'react';
import { useGetBackgroundColor } from "../../../../src/api/index";

const SortMobileFilter = ({closeModel, filter}) => {
  const [selectSort, setSort] = useState('latest');
  const { data } = useGetBackgroundColor({ onLoad: true });

  return (
  <>
   <div>
   <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-50">
    <div className="flex flex-col w-full max-w-sm md:max-w-md mx-auto shadow-lg relative">
      <div className="p-4 rounded-t-3xl text-white flex justify-center items-center relative" style={{backgroundColor : data.color.shade1}}>
        <div className="absolute top-2 right-2 text-white hover:text-gray-200" onClick={() => closeModel(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M18 34.125C9.105 34.125 1.875 26.895 1.875 18C1.875 9.105 9.105 1.875 18 1.875C26.895 1.875 34.125 9.105 34.125 18C34.125 26.895 26.895 34.125 18 34.125ZM18 4.125C10.35 4.125 4.125 10.35 4.125 18C4.125 25.65 10.35 31.875 18 31.875C25.65 31.875 31.875 25.65 31.875 18C31.875 10.35 25.65 4.125 18 4.125Z" fill="white" />
            <path d="M13.755 23.37C13.47 23.37 13.185 23.265 12.96 23.04C12.525 22.605 12.525 21.885 12.96 21.45L21.45 12.96C21.885 12.525 22.605 12.525 23.04 12.96C23.475 13.395 23.475 14.115 23.04 14.55L14.55 23.04C14.34 23.265 14.04 23.37 13.755 23.37Z" fill="white" />
            <path d="M22.245 23.37C21.96 23.37 21.675 23.265 21.45 23.04L12.96 14.55C12.525 14.115 12.525 13.395 12.96 12.96C13.395 12.525 14.115 12.525 14.55 12.96L23.04 21.45C23.475 21.885 23.475 22.605 23.04 23.04C22.815 23.265 22.53 23.37 22.245 23.37Z" fill="white" />
          </svg>
        </div>
        <div className="text-xl">Sort Designs By</div>
      </div>
      <ul className="space-y-2 bg-white p-3 font-bold">
        <li className={`p-2 rounded ${selectSort === 'latest'? 'text-[#447FB7]' : ''} pointer`} onClick={() => {setSort('latest'); filter('latest')}}>Latest</li>
        <li className={`p-2 rounded ${selectSort === 'weightLowToHigh'? 'text-[#447FB7]' : ''} pointer`} onClick={() => {setSort('weightLowToHigh'); filter('weightLowToHigh')}}>Weight: Low to High</li>
        <li className={`p-2 rounded ${selectSort === 'weightHighToLow'? 'text-[#447FB7]' : ''} pointer`} onClick={() => {setSort('weightHighToLow'); filter('weightHighToLow')}}>Weight: High to Low</li>
        <li className={`p-2 rounded ${selectSort === 'priceLowToHigh'? 'text-[#447FB7]' : ''} pointer`} onClick={() => {setSort('priceLowToHigh'); filter('priceLowToHigh')}}>Price: Low to High</li>
        <li className={`p-2 rounded ${selectSort === 'priceHighToLow'? 'text-[#447FB7]' : ''} pointer`} onClick={() => {setSort('priceHighToLow'); filter('priceHighToLow')}}>Price: High to Low</li>
      </ul>
    </div>
  </div>
  </div>
  </>
  );
};

export default SortMobileFilter;
