/* eslint-disable jsx-a11y/iframe-has-title */
// StoreCard.js
import React from 'react';
import './storeList.css';
import { useResponsive } from 'hooks';

const StoreCard = ({ store }) => {
  const { isDesktop, isMobile } = useResponsive();
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg lg:w-[49%] md:[49%] sm:w-full xl:w-[49%] 2xl:w-[49%] mb-[1%]">
      <p className="text-[1.25rem] font-semibold text-color mb-3">{store?.name}</p>
      <p className="text-gray-800 font-bold mb-2">{store?.company}</p>
      <p className="text-gray-600">{store?.address}</p>
      <p className="text-gray-600 my-2">Phone: {store?.phone}</p>
      <p className="text-gray-600">WhatsApp: {store?.phone}</p>
      {store?.email && 
        <p className="text-gray-600">Email: {store?.email}</p>
      }

      {/* Store Timing */}
      {!isMobile &&  store?.timing &&
      <>
      <div className="store-timing-container mt-[6%] flex">
      <div className="store-timing-header">
        <i className="icon-calendar mt-1"></i>
        <span>STORE TIMING</span>
      </div>
        <div className="day-time">
          <p className="day font-bold">Mon - Fri</p>
          <p className="time">{store?.timing.weekdays}</p>
        </div>
        <div className="day-time">
          <p className="day font-bold">Sat</p>
          <p className="time">{store?.timing.saturday}</p>
        </div>
        <div className="day-time closed w-20 text-center">
          <p className="day font-bold">Sun</p>
          <p className="text-[10px] subpixel-antialiased ">{store?.timing.sunday}</p>
        </div>
      </div>
      </>
      }
      {isMobile && store?.timing &&
      <div className="store-timing-container-mobile mt-[1%] flex p-2">
      <div className='icon-calendar'>
      </div>
      <div>
      <div className="store-timing-mobile day-time">
        <span>STORE TIMING</span>
      </div>
        <div className="day-time">
          <p className="day font-bold">Mon - Fri</p>
          <p className="time">{store?.timing.weekdays}</p>
        </div>
        <div className="day-time">
          <p className="day font-bold">Sat</p>
          <p className="time">{store?.timing.saturday}</p>
        </div>

        <div className="day-time closed text-center w-24">
          <p className="day font-bold">Sun  <span className="text-[10px] subpixel-antialiased">{store?.timing.sunday}</span></p>
        </div>
        </div>
      </div>}
      {/* Map */}
      {store?.mapLink && 
        <div className="map-container mt-4">
          <iframe
            src={store?.mapLink}
            width="100%"
            height="200"
            className="rounded-lg"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      }
    </div>
  );
};

export default StoreCard;
