import { useMutation } from "@apollo/client";
import { CREATE_TOKEN } from "graphql/mutations";

export const useCreateToken = () => {
    const [createToken, { loading, data, error }] = useMutation(CREATE_TOKEN, {
        onCompleted: (data) => {
            localStorage.setItem(
                `vjw-${window.location.hostname}ad-token`,
                data.tokenCreate.token
            );
        },
    });

    return {
        executeQuery: createToken,
        data,
        loading,
        error,
    }
}