import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sideNavOpen: false,
    homeSearchOpen: false,
    filterOpen: false,
    customizationOpen: false,
    messageOpen: false,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        openSideNav(state) {
            state.sideNavOpen = true;
        },
        closeSideNav(state) {
            state.sideNavOpen = false;
        },
        openHomeSearch(state) {
            state.homeSearchOpen = true;
        },
        closeHomeSearch(state) {
            state.homeSearchOpen = false;
        },
        openFilter(state) {
            state.filterOpen = true;
        },
        closeFilter(state) {
            state.filterOpen = false;
        },
        openCustomization(state) {
            state.customizationOpen = true;
        },
        closeCustomization(state) {
            state.customizationOpen = false;
        },
        openMessage(state) {
            state.messageOpen = true;
        },
        closeMessage(state) {
            state.messageOpen = false;
        },
    },
});

export const {
    openSideNav,
    closeSideNav,
    openHomeSearch,
    closeHomeSearch,
    openFilter,
    closeFilter,
    openCustomization,
    closeCustomization,
    openMessage,
    closeMessage,
} = clientSlice.actions;

export default clientSlice.reducer;
