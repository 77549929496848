import { useLazyQuery } from "hooks";
import { GET_SECTION_VIDEO } from "graphql/query";
import { fetchStoreDetails } from "hooks/redux/actions/graph";
import { useDispatch } from "react-redux";

export const useMarqueeBanner = (onLoad) => {
    const dispatch = useDispatch();

    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_SECTION_VIDEO,
        {
            onCompleted: (data) => {
            },
            onLoad,
        }
    );

    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};
