import React, { useState } from "react";
import "./accountDesktop.css"; // Create CSS styles
import UserProfileDesktop from "components/profile/ViewProfileDesktopView";
const ViewAccountDesktop = () => { 
  return (     
      <UserProfileDesktop/>
  );
};

export default ViewAccountDesktop;
