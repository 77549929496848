import React, { useEffect, useState } from "react";
import './checkout.css';
import { useNavigate  } from 'react-router-dom';
import AddAddress from "./addAddress";
import ConfirmationModal from "./confirmOrder";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useGetUserDetailsData } from "api";
import { useResponsive } from 'hooks';
import { useGetBackgroundColor } from "../../../src/api/index";
import { useSelector } from 'react-redux';

const Shipping = () => {
  const navigate = useNavigate();
  const storeData = useSelector(state => state.storeData);
  const { data: userData } = useGetUserDetailsData({ onLoad: true });
  const [deliveryMethod, setDeliveryMethod] = useState('home');
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [addAddressModel, setAddAddressModel] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null)
  const [billingAddress, setBillingAddress] = useState(null)
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
  const [selectedAddressType,setSelectedAddressType] = useState("shipping");
  const [checkoutId, setCheckoutId] = useState(null);
  const { isDesktop, isMobile } = useResponsive();
  const { data } = useGetBackgroundColor({ onLoad: true });
  useEffect(()=>{
    if(userData){     
      console.log("userData",userData);
      setCheckoutId(userData?.checkout?.id);
      if(userData?.checkout?.billingAddress || userData?.checkout?.shippingAddress){
        userData?.checkout?.billingAddress && setBillingAddress([userData?.checkout?.billingAddress]);
        // if(userData?.checkout?.shippingAddress){
          userData?.checkout?.shippingAddress && setShippingAddress([userData?.checkout?.shippingAddress]);
        // }else{
          // setShippingAddress(userData?.addresses);
        // }
      }
      // else if(userData?.addresses){
      //   // setShippingAddress(userData?.addresses);
      // }
    }
  },[userData]);
  
  const handleEdit = () => {
    setConfirmationModel(false);
    console.log("Edit button clicked");
    setCurrentAddress(shippingAddress[0]);
    setAddAddressModel(true); 
  };
  const fetchPaymentPage = async () => {
    try {
      const url = `${process.env.REACT_APP_PAYMENT_GATEWAY_API}?user_phone_number=${userData?.phoneNumber}&user_id=${userData.id}&domain=${storeData?.domain}&storeName=${storeData?.name?.replace(","," ")}`
      console.log("url",url)
      window.open(url, '_blank');
    } catch (error) {
      console.error("Error fetching payment page:", error);
    }
  };
  const handleConfirm = () => {
    setConfirmationModel(false)
    console.log("Confirm button clicked");
    fetchPaymentPage();
  };
  const handleCloseAddress = () =>{
    setAddAddressModel(false);
    setCurrentAddress(null);
    window.location.reload();
  }
  const [htmlContent, setHtmlContent] = useState("");
    return(
    <>
     <div className="max-w-xl mx-auto px-6">
      {/* <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        /> */}
      {/* Delivery Details */}
      <div className="mb-6">
        <div className="text-xl font-semibold text-center mb-1 mt-3">Delivery Details</div>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-1">
          <div
            className={`border p-4 rounded-lg cursor-pointer ${deliveryMethod === 'home' ? 'bg-[#D9D9D9]' : 'bg-[#F8F8F8]'}`}
            onClick={() => setDeliveryMethod('home')}
          >
            {/* <input
              type="radio"
              name="deliveryMethod"
              checked={deliveryMethod === 'home'}
              className="mr-2"
            /> */}
            <div className="flex justify-between">
            <span className="font-semibold">Home Delivery</span>
            {deliveryMethod == 'home' && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="9" stroke="#5D5D5D" stroke-width="2"/>
            <circle cx="10" cy="10" r="5" fill="#5E5E5E"/>
            </svg>}
            {deliveryMethod !== 'home' && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="9" stroke="#5E5E5E" stroke-width="2"/>
            </svg>}
            </div>
            <p className="text-sm mt-2">Earliest Delivery dates selected for your Pincode</p>
            {/* <button className="mt-3 p-2 font-medium rounded-[12px] text-sm bg-white">
            <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M16.5 11.6875H5.5C5.12417 11.6875 4.8125 11.3758 4.8125 11C4.8125 10.6242 5.12417 10.3125 5.5 10.3125H16.5C16.8758 10.3125 17.1875 10.6242 17.1875 11C17.1875 11.3758 16.8758 11.6875 16.5 11.6875Z" fill="#292D32"/>
            <path d="M11 17.1875C10.6242 17.1875 10.3125 16.8758 10.3125 16.5V5.5C10.3125 5.12417 10.6242 4.8125 11 4.8125C11.3758 4.8125 11.6875 5.12417 11.6875 5.5V16.5C11.6875 16.8758 11.3758 17.1875 11 17.1875Z" fill="#292D32"/>
            </svg> Choose your Delivery Date
            </div>
            </button> */}
          </div>
          {/* <div
            className={`border p-4 rounded-lg cursor-pointer ${deliveryMethod === 'pickup' ? 'bg-[#D9D9D9]' : 'bg-[#F8F8F8]'}`}
            onClick={() => setDeliveryMethod('pickup')}
          >
            <div className="flex justify-between">
            <span className="font-semibold">In-store pick up</span>
            {deliveryMethod == 'pickup' && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="9" stroke="#5D5D5D" stroke-width="2"/>
            <circle cx="10" cy="10" r="5" fill="#5E5E5E"/>
            </svg>}
            {deliveryMethod !== 'pickup' && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="9" stroke="#5E5E5E" stroke-width="2"/>
            </svg>}
            </div>
            <p className="text-sm mt-2">Buy now, pick up from our store at your convenience</p>
          </div> */}
        </div>
      </div>
      {deliveryMethod == 'home' && (<>
      {/* Shipping Address */}
      <div className="text-xl font-semibold text-center mb-1 mt-3">Shipping Address</div>
      {shippingAddress && shippingAddress.map((shippingAdd) =>{
        if(!shippingAdd?.defaultBillingAddress){
        return (<>
          <div className="flex justify-center items-center py-2 cursor-pointer" onClick={() => {setSelectedShippingAddress(shippingAdd?.id)}}>
          <div className={`bg-[#D9D9D9] p-4 rounded-[10px] w-full`}>
            <div className="flex justify-between items-center mb-2">
              <h2 className="font-bold text-lg">{shippingAdd && `${shippingAdd?.firstName} ${shippingAdd?.lastName}`}</h2>
              <div className="flex items-center space-x-2">
                <button className="text-black cursor-pointer mr-3" onClick={() => { setAddAddressModel(true); setCurrentAddress(shippingAdd)}}>Edit</button>
                <button className="text-gray-600 hover:text-black">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 19" fill="none">
                <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
                </svg>
                </button>
              </div>
            </div>
            <p className="text-gray-700">
              {`${shippingAdd?.streetAddress1}, ${shippingAdd?.streetAddress2}, ${shippingAdd?.city}, ${shippingAdd?.countryArea} - ${shippingAdd?.postalCode}, India`}
            </p>
            <p className="mt-2 font-bold">
              <span>Phone:</span> {shippingAdd?.phone ? `${shippingAdd?.phone}` : '-'}
            </p>
            {/* <p className="mt-1 font-bold">
              <span>Address Type:</span> Home
            </p> */}
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-black">
                <div className={`w-5 h-5 rounded-full bg-black text-white flex items-center justify-center font-semibold p-3`}>
                <FontAwesomeIcon icon={faCheck}/>
                </div>
                </button>
              </div>           
          </div>
          </div>
        </>);
        }
      }
        
    )}
     {/* Add a new address option */}
     {!shippingAddress && 
      <div
        className="bg-[#F6F6F6] border-[#0000001A] boarder-1 flex justify-between items-center p-4 border rounded-lg cursor-pointer mt-3"
        onClick={() => { setAddAddressModel(true)}}
      >
        <p className="font-semibold">Add a new address</p>
        {/* Plus Icon */}
        <div className="w-6 h-6 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g opacity="0.5">
        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#292D32"/>
        </g>
       </svg>
        </div>
      </div>
     }

    {/* Billing Address */}
    <div className="w-full mx-auto">
      {/* Title */}
      <div className="text-xl font-semibold text-center mb-1 mt-3">Billing Address</div>
      {/* Same as shipping address option */}
      <div className={`flex justify-between items-center p-4 mb-2 border rounded-lg cursor-pointer ${billingSameAsShipping ? 'bg-[#D9D9D9]' : 'bg-[#F8F8F8]'} border-[#0000001A] boarder-1`}
        onClick={() => {setBillingSameAsShipping(true)}}>
        <div>
          <p className="font-semibold">Same as shipping address</p>
          <p className="text-sm text-gray-500">
            Invoice & order updates will be shared at this address & mobile
          </p>
        </div>
        {/* Radio Button */}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g opacity="0.5">
        <circle cx="10" cy="10" r="9" stroke="black" stroke-width="2"/>
        <circle cx="10" cy="10" r="5" fill="black"/>
        </g>
        </svg>
        </div>
      {
      <>
        {billingAddress && billingAddress.map((shippingAdd) =>{       
          return (<>
            <div className="flex justify-center items-center py-2 cursor-pointer" onClick={() => {setSelectedShippingAddress(shippingAdd?.id)}}>
            <div className={`bg-[#D9D9D9] p-4 rounded-[10px] w-full`}>
              <div className="flex justify-between items-center mb-2">
                <h2 className="font-bold text-lg">{shippingAdd && `${shippingAdd?.firstName} ${shippingAdd?.lastName}`}</h2>
                <div className="flex items-center space-x-2">
                    <button className="text-black cursor-pointer mr-3" onClick={() => { setAddAddressModel(true); setCurrentAddress(shippingAdd); setSelectedAddressType("billing")}}>Edit</button>               
                  <button className="text-gray-600 hover:text-black">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 19" fill="none">
                  <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                  </button>
                </div>
              </div>
              <p className="text-gray-700">
                {`${shippingAdd?.streetAddress1}, ${shippingAdd?.streetAddress2}, ${shippingAdd?.city}, ${shippingAdd?.countryArea} - ${shippingAdd?.postalCode}, India`}
              </p>
              <p className="mt-2 font-bold">
                <span>Phone:</span> {shippingAdd?.phone ? `${shippingAdd?.phone}` : '-'}
              </p>
              {/* <p className="mt-1 font-bold">
                <span>Address Type:</span> Home
              </p> */}
                <div className="flex justify-end">
                  <button className="text-gray-500 hover:text-black">
                  <div className={`w-5 h-5 rounded-full bg-black text-white flex items-center justify-center font-semibold p-3`}>
                  <FontAwesomeIcon icon={faCheck}/>
                  </div>
                  </button>
                </div>           
            </div>
            </div>
          </>);
        })}
        </>
      }
        {/* {billingAddress && 
      (<>
        <div className="flex justify-center items-center">
        <div className="bg-[#D9D9D9] p-4 rounded-[10px] w-full">
          <div className="flex justify-between items-center mb-2">
            <h2 className="font-bold text-lg">{billingAddress && `${billingAddress?.firstName} ${billingAddress?.lastName}`}</h2>
            <div className="flex items-center space-x-2">
              <button className="text-black cursor-pointer mr-3">Edit</button>
              <button className="text-gray-600 hover:text-black">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 19" fill="none">
              <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
              </svg>
              </button>
            </div>
          </div>
          <p className="text-gray-700">
            {`${billingAddress?.streetAddress1}, ${billingAddress?.streetAddress2}, ${billingAddress?.city}, ${billingAddress?.cityArea}, ${billingAddress?.countryArea} - ${billingAddress?.postalCode} ${billingAddress?.country?.country}`}
          </p>
          <p className="mt-2 font-bold">
            <span>Phone:</span> {billingAddress?.phone ? `+91 ${billingAddress?.phone}` : '-'}
          </p>
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-black">
            <div className="w-5 h-5 rounded-full bg-black text-white flex items-center justify-center font-semibold p-3">
            <FontAwesomeIcon icon={faCheck}/>
            </div>
            </button>
          </div>
        </div>
        </div>
      </>)} */}
      {/* Add a new address option */}
      <div
        className="bg-[#F6F6F6] border-[#0000001A] boarder-1 flex justify-between items-center p-4 border rounded-lg cursor-pointer"
        onClick={() => { setAddAddressModel(true); setBillingSameAsShipping(false); setSelectedAddressType("billing");}}
      >
        <p className="font-semibold">Use a different billing address</p>
        {/* Plus Icon */}
        <div className="w-6 h-6 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g opacity="0.5">
        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#292D32"/>
        </g>
       </svg>
        </div>
      </div>
      </div>
      <p className="font-medium text-xs mt-1">We will not send an invoice to the shipping address</p>
      </>)}
      {deliveryMethod == 'pickup' && (<>
        <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Select City*</label>
          <select className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold">
            <option>Kolkata</option>
            {/* Add more cities here */}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Store*</label>
          <select className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold">
            <option>Park Street</option>
            {/* Add more stores here */}
          </select>
        </div>
      </div>
        {/* Store Address Display */}
        <div className="bg-[#D9D9D9] p-4 rounded-[10px] mb-6" style={{border : '1px solid #828282A6'}}>
        <p className="font-bold">Park Street</p>
        <p className="font-medium">Ground Floor, 21A, Park Street</p>
        <p className="font-bold">Phone: +91 9988441141</p>
        <button className="text-[#3238c2] text-[12px] mt-2 font-bold">GET DIRECTIONS</button>
      </div>
        <div className="text-xl font-semibold text-center mb-1 mt-3">Billing Address</div>
        <div className="mt-2 p-4">
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          {/* First Name */}
          <input
            type="text"
            placeholder="First Name"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"

          />
          {/* Last Name */}
          <input
            type="text"
            placeholder="Last Name"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
        </div>

        <div className="mt-4">
          {/* Street & House No */}
          <input
            type="text"
            placeholder="Street & House No"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
        </div>

        <div className="mt-4">
          {/* Landmark (Optional) */}
          <input
            type="text"
            placeholder="Landmark (Optional)"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
          {/* City */}
          <input
            type="text"
            placeholder="City"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
          {/* Pincode */}
          <input
            type="text"
            placeholder="Pincode"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
          {/* State */}
          <input
            type="text"
            placeholder="State"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
          {/* Country */}
          <div className="relative">
            <select className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold">
              <option value="India">India</option>
              {/* Add more country options here */}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-12 md:grid-cols-12 gap-4 mt-4">
          {/* Phone Country Code */}
          <select className="col-start-1 col-end-4 h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold">
            <option value="+91">+91</option>
            {/* Add more country codes here */}
          </select>

          {/* Phone Number */}
          <input
            type="text"
            placeholder="Phone Number"
            className="col-start-4 col-end-13 h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
          />
        </div>
      </div>
      </>)}
      {/* Continue Button */}
      <button className="checkout-btn text-white py-3 px-6 rounded-lg w-full mt-4" onClick={() => {setConfirmationModel(true)}} style={{backgroundColor : data.color.primaryColor}}>CONTINUE</button>
    </div>
    { addAddressModel && <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-[#393838b3] bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg sm:rounded-[0px] bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
        {!isMobile && <div class="bg-white px-4 pb-2 pt-5 sm:p-6 sm:pb-2">
          <div class="sm:flex sm:items-start">
          <div class="flex justify-between items-center mt-3 sm:ml-4 sm:mt-0">
          <div></div>
          <p class="font-bold text-black text-[15px]" id="modal-title">Add New Address</p>
          <button type="button" className="text-black font-bold text-2xl focus:outline-none" onClick={() => {
              setAddAddressModel(false);
              setSelectedAddressType("shipping");
              setCurrentAddress(null)
            }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
          </button>
          </div>
          </div>
        </div>}
        <div>
          <AddAddress currentAddress={currentAddress} checkoutAddressType={selectedAddressType} checkoutId={checkoutId} handleClose={handleCloseAddress}></AddAddress>
        </div>
      </div>
    </div>
    </div>
    </div>}
     {/* confirm pop up */}
     {confirmationModel &&<ConfirmationModal 
        address={`${shippingAddress[0]?.streetAddress1}, ${shippingAddress[0]?.streetAddress2}, ${shippingAddress[0]?.city}, ${shippingAddress[0]?.countryArea} - ${shippingAddress[0]?.postalCode}, India`}
        phoneNumber={`+91 ${shippingAddress[0]?.phone?.replace("+91",'')}`}
        onEdit={handleEdit}
        onConfirm={handleConfirm}
        close={() => setConfirmationModel(false)}  
    ></ConfirmationModal>}
    </>
    )
}
export default Shipping;