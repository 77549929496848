import React, { useEffect, useState } from "react";
import StoreCard from "./StoreCard";
import './storeList.css'
const StoreList = ({store}) => {
  console.log("store",store)
  const storeData = store?.user?.addresses?.map((storeD) => {
    const latitude = storeD?.latitude;
    const longitude = storeD?.longitude;
    const hasLatLong = longitude && longitude!=="" && latitude && latitude!=='';
    const zoom = 19; // Or any zoom level you need
    const embedMapLink = `https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&center=${latitude},${longitude}&zoom=${zoom}`;
  
    return {
      name: store?.name,
      company: storeD?.companyName,
      address: `${storeD?.streetAddress1}, ${storeD?.streetAddress2}, ${storeD?.city}, ${storeD?.countryArea}, ${storeD?.postalCode}`,
      phone: storeD?.phone,     
      // email: 'pcc_agartala@pcc.in',
      // timing: {
      //   weekdays: '11:00 AM - 8:00 PM',
      //   saturday: '11:00 AM - 2:00 PM',
      //   sunday: 'Closed',
      // },
      mapLink: hasLatLong ? embedMapLink : null,
    };
  });
  
  console.log("storeData1",storeData)
    // const storeData = [
    //     {
    //       name: 'Agartala Showroom',
    //       company: 'P.C. Chandra Jewellery Apex Pvt. Ltd',
    //       address: '49, H. G. Basak Road, Paradise Chowmuhani, Agartala, Tripura 799001',
    //       phone: '7985878756',
    //       email: 'pcc_agartala@pcc.in',
    //       timing: {
    //         weekdays: '11:00 AM - 8:00 PM',
    //         saturday: '11:00 AM - 2:00 PM',
    //         sunday: 'Closed',
    //       },
    //       mapLink: 'https://goo.gl/maps/xNWVCRF2RmtFyg7E7',
    //     },
    //      {
    //       name: 'Agartala Showroom',
    //       company: 'P.C. Chandra Jewellery Apex Pvt. Ltd',
    //       address: '49, H. G. Basak Road, Paradise Chowmuhani, Agartala, Tripura 799001',
    //       phone: '7985878756',
    //       email: 'pcc_agartala@pcc.in',
    //       timing: {
    //         weekdays: '11:00 AM - 8:00 PM',
    //         saturday: '11:00 AM - 2:00 PM',
    //         sunday: 'Closed',
    //       },
    //       mapLink: 'https://goo.gl/maps/xNWVCRF2RmtFyg7E7',
    //     },
    //     {
    //       name: 'Arambagh Showroom',
    //       company: 'P.C. Chandra Jewellery Apex Pvt. Ltd',
    //       address: 'G. L. B. Road, Arambagh, Hooghly 712601',
    //       phone: '7985878757',
    //       email: 'pcc_arambagh@pcc.in',
    //       timing: {
    //         weekdays: '11:00 AM - 7:30 PM',
    //         saturday: '11:00 AM - 7:30 PM',
    //         sunday: 'Closed',
    //       },
    //       mapLink: 'https://goo.gl/maps/QkrSh9ewZZM2',
    //     }
    //   ];
    return (
      <>
       <div className="App">
        <header>
            <div className="h-[22.5rem] bg-cover bg-center" style={{ backgroundImage: 'url(/assets/img/store.png)' }}>
            {/* Header Image (Replace the URL with your image) */}
            </div>
        </header>
        <main className="p-4">
        <div className="max-w-7xl mx-auto">
        <p className="text-center font-bold text-[20px]">Find Our Store</p>
        <div className="flex justify-center">
        <p className="text-center text-[#707070] text-[15px] w-72" >Select any of our showroom from the list to view the detailed address.</p>
        </div>
        <h1 className="text-center text-3xl font-semibold	 mb-8">Store <span className="text-color">List</span></h1>
        </div>
        <div className="mx-[5%] sm:mx-[2px]">
        <div className="flex flex-wrap gap-[1%]">
        {storeData.map((store, index) => (
            <StoreCard key={index} store={store} />
        ))}
        </div>
        </div>
        </main>
       </div>
      </>
    );
}

export default StoreList;