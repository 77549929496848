import React, { useEffect, useRef, useState } from 'react';
import './customize.css';
import { useResponsive } from 'hooks';
import { useGetCategoryPricingByCatSubCat } from 'api';
import { useGetBackgroundColor } from "../../../../src/api/index";

const Customize = (props) => { 
  const parentCategoryId = props?.variantData?.parentCategoryId;
  const childCategoryId = props?.variantData?.childCategoryId;  
  
  // productDetails?.parentCategoryName
  const { data } = useGetBackgroundColor({ onLoad: true });
  const [prices, setPrices] = useState(props?.variantData?.allVariants?.map(product => ({
      productId: product.productId,
      price: product.price,
      product
    })));
  const { data: categoryPricingData } = useGetCategoryPricingByCatSubCat({categoryId:parentCategoryId, subcategoryId:childCategoryId});
  useEffect(()=>{
    if(categoryPricingData && props?.variantData?.isDBP===true){
      const tempPrice = prices;
      for(let price in tempPrice){
        if(tempPrice[price].price===0){
          const netW = parseFloat(tempPrice[price]?.product?.netWeight);
          const grossW = parseFloat(tempPrice[price]?.product?.grossWeight);
          const stoneType = tempPrice[price]?.product?.type?.toLowerCase()==="studded" ? JSON.parse(tempPrice[price]?.product?.gemstoneDetails.replace(/'/g, '"'))[0]?.name?.toLowerCase() : "none";
          const metalRate = tempPrice[price]?.product?.metalRates[`${tempPrice[price]?.product?.metalTypeName.toLowerCase()}${tempPrice[price]?.product?.metalCarat}kRate`];
          const size = tempPrice[price]?.product?.size;
          let totalMetalCharges = netW * metalRate;
          const dataTemp = categoryPricingData?.categoryPricingByCategorySubcategory?.map((data)=>{
            console.log("")
            if(data?.stoneType===stoneType && data?.metalType===tempPrice[price]?.product?.metalTypeName.toLowerCase() && data?.carat===parseInt(tempPrice[price]?.product?.metalCarat)){
              return data;
            }
            return null;
          }).filter((data)=>data);        
          const categoryPricing = dataTemp[0];
          if(categoryPricing){
            const dbMCMode = categoryPricing.designBankMakingChargeMode ;
            const dbMC = categoryPricing.designBankMakingCharge;
            const makingCharge = parseFloat(parseFloat(dbMCMode?.toLowerCase() === 'percent' ? ((totalMetalCharges * dbMC)/100) : (dbMCMode?.toLowerCase() === 'flat' ? (dbMC): (totalMetalCharges + (dbMC*netW)))).toFixed(3));       
            
            const dbWCMode = categoryPricing.designBankWastageChargeMode;
            const dbWC = categoryPricing.designBankWastageCharge;       
            const wastageCharge = parseFloat(parseFloat(dbWCMode?.toLowerCase() === 'percent' ? ((totalMetalCharges * dbWC)/100) : (dbWCMode?.toLowerCase() === 'flat' ? (dbWC): (totalMetalCharges + (dbWC*netW)))).toFixed(3))
            
            const totalPrice = totalMetalCharges + makingCharge + wastageCharge;
            if(tempPrice[price].product){
              tempPrice[price].product.price = parseFloat(totalPrice + (totalPrice * 0.03)).toFixed(0);
              tempPrice[price].price = tempPrice[price]?.product?.price;         
              tempPrice[price].product.priceBreakdown = {
                metalType: tempPrice[price]?.product?.metalTypeName,
                metalPrice: metalRate,
                gemstones: tempPrice[price]?.product?.type?.toLowerCase() === "studded" ? JSON.parse(tempPrice[price]?.product?.gemstoneDetails).map(gem => gem.name) : [],
                totalMakingCharge : makingCharge,
                totalWastageCharge : wastageCharge,
                size: size,
                grossWeight: grossW,
                netWeight : netW,
              };         
            }
          }
        }
      }  
      setPrices(tempPrice);
    }
  },[categoryPricingData]);
  const selectedVariant = props?.selectedVariantData;
  // Map over products to get combinations of metalCarat and metalColor
  const caratAndColorCombinations = props?.variantData?.allVariants?.map(product => ({
    name: `${product.metalCarat}KT ${product.metalColor.charAt(0).toUpperCase() + product.metalColor.slice(1)}`,
    productId: product?.productId,
    stock: product?.availability.toLowerCase() === "ready" ? "In Stock" : "Made to Order"
  }));
  
  // Remove duplicates by 'name'
  const uniqueCaratColorCombinations = caratAndColorCombinations.filter((item, index, self) =>
    index === self.findIndex(t => t.name === item.name)
  );
  
  function formatPriceWithCommas(price) {
    // Convert the price to a string
    const priceString = price.toString();
  
    // Split the price into integer and decimal parts (if any)
    const [integerPart, decimalPart = ""] = priceString.split(".");
  
    // Add commas to the integer part
    let formattedIntegerPart = "";
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
      if ((count === 3 && i !== 0) || ((count + 1) % 2 === 0 && count > 3)) {
        formattedIntegerPart = "," + formattedIntegerPart;
      }
  
      formattedIntegerPart = integerPart[i] + formattedIntegerPart;
    }
  
    // Combine the formatted parts and return the result
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }
  const [selectedMetal, setSelectedMetal] = useState(null);
  const [selectedQuality, setSelectedQuality] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState('Please Select Size');
  const { isDesktop, isMobile } = useResponsive();

  const metals = uniqueCaratColorCombinations;
  
  const qualities = props?.variantData?.allVariants?.map(product => {
    try{
      const gemstoneDetails = JSON.parse(product?.gemstoneDetails.replace(/'/g, '"')) || []; // Parse the gemstoneDetails JSON string
      console.log("gemstoneDetails",gemstoneDetails.replace(/'/g, '"'))
      if(gemstoneDetails[0]['name']==="Diamond"){
        const diamondGrade = gemstoneDetails.replace(/'/g, '"')[0]?.dg || ''; // Access the 'dg' (diamond grade) field from the first gemstone
      
        return {
          name: diamondGrade, // Set the diamond grade as 'name'
          stock: product.availability.toLowerCase() === "ready" ? "In Stock" : "Made to Order", // Determine stock status
          productId : product?.productId
        };
      }else{
        return {
          name: gemstoneDetails[0]['name'], // Set the diamond grade as 'name'
          stock: product.availability.toLowerCase() === "ready" ? "In Stock" : "Made to Order", // Determine stock status
          productId : product?.productId
        };
      }
      return null;
    }catch(error){
      console.log("Ignorable error :: 33",error);
      return null;
    }
  }).filter((data)=>data);
  
  // Prepare the sizes array
  const uniqueSizesSet = new Set();

  // Prepare the unique sizes array
  const sizes = props?.variantData?.allVariants?.filter(product => {
    const isUnique = !uniqueSizesSet.has(product.size);
    uniqueSizesSet.add(product.size); // Add size to Set if it's not already present
    return isUnique;
  })
    .map(product => ({
      size: product.size, // Extract unique size
      mm: '', // Set predefined mm value
      stock: product.availability.toLowerCase() === "ready" ? "In Stock" : "Made to Order", // Check stock availability
      productId: product.productId // Extract product ID
    })); 
  
  const setFirstWhenOnlyOneRecord = ()=>{
    if(metals?.length===1) setSelectedMetal(metals[0]);
    if(qualities?.length===1) setSelectedQuality(qualities[0]);
    if(sizes && sizes?.length===1) setSelectedSize(sizes[0]);
  };
  console.log("props?.variantData",props?.variantData);
  // Prepare the prices array
  props?.variantData?.allVariants?.map(product => console.log(product));
  console.log("prices",prices)
  
  useEffect(() => {
    if (selectedVariant && metals && sizes) {
      const variantId = selectedVariant?.productId;
      const metal = metals.find(metal => metal.productId === variantId);
      if (metal) {
        handleMetalSelect(metal);
      }
      const size = sizes.find(size => size.productId === variantId);
      if (size) {
        handleSizeSelect(size);
      }
      if(qualities){
        const quality = qualities.find(quality => quality?.productId === variantId);
        if (metal) {
          handleQualitySelect(quality);
        }
      }
    }
  }, []); 
  const handleMetalSelect = metal => {   
    setFirstWhenOnlyOneRecord();
    qualities?.length && qualities.map((quality)=>{
      if(metal?.productId === quality?.productId){
        setSelectedQuality(quality);
        return;
      }
    })
    sizes && sizes.map((size)=>{
      if(metal?.productId === size?.productId){
        setSelectedSize(size);
        return;
      }
    })
    prices && prices.map((price)=>{
      if(metal?.productId === price?.productId){
        setSelectedPrice(price);
        return;
      }
    })
    setSelectedMetal(metal);
  };

  const handleQualitySelect = quality => {
    setFirstWhenOnlyOneRecord();
    metals?.map((metal)=>{
      if(quality?.productId === metal?.productId){
        setSelectedMetal(metal);
        return;
      }
    })
    sizes && sizes.map((size)=>{
      if(quality?.productId === size?.productId){
        setSelectedSize(size);
        return;
      }
    })
    prices && prices.map((price)=>{
      if(quality?.productId === price?.productId){
        setSelectedPrice(price);
        return;
      }
    })
    setSelectedQuality(quality);
  };

  const handleSizeSelect = size => {
    setFirstWhenOnlyOneRecord();
    metals?.map((metal)=>{
      if(size?.productId === metal?.productId){
        setSelectedMetal(metal);
        return;
      }
    })
    qualities[0]!=null && qualities.map((quality)=>{
      if(size?.productId === quality?.productId){
        setSelectedQuality(quality);
        return;
      }
    })
    prices && prices.map((price)=>{
      if(size?.productId === price?.productId){
        setSelectedPrice(price);
        return;
      }
    })
    setSelectedSize(size);
  };

  const handleConfirmCustomization = () => {
    console.log('Customization confirmed with:');
    console.log('Metal:', selectedMetal);
    console.log('Diamond Quality:', selectedQuality);
    console.log('Size:', selectedSize);
  
    let productId;
    let sizeProductId = null;
  
    // Check if all three (metal, diamond, size) are present
    if (selectedMetal && selectedQuality && selectedSize) {
      const productIds = [
        // selectedMetal.productId,
        selectedQuality.productId,
        // selectedSize.productId
      ];
      // Get the productId that occurs the most (majority)
      productId = productIds.sort((a, b) =>
        productIds.filter(id => id === a).length - productIds.filter(id => id === b).length
      ).pop();
    } 
    // If only metal and size are present, prioritize size's productId
    else if (selectedMetal && selectedSize && !selectedQuality) {
      sizeProductId = selectedSize.productId;
    }
  
    console.log('Final Product ID:', productId);
    console.log('Size Product ID:', sizeProductId);
    props.customizeModelClose(false, sizeProductId ? sizeProductId : productId);
  };
  
  const handleCloseButton = () => {
    props.customizeModelClose(false);
  };
  return (
    <>
      {!isMobile && (
        <div
          className='relative z-10'
          aria-labelledby='slide-over-title'
          role='dialog'
          aria-modal='true'
        >
          <div
            className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            aria-hidden='true'
          ></div>
          <div className='fixed inset-0 overflow-auto'>
            <div className='absolute inset-0 overflow-auto'>
              <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                <div className='pointer-events-auto relative w-screen max-w-md'>
                  <div className='header flex justify-between rounded-tl-[50px]' style={{backgroundColor : data.color.shade3}}>
                    <div className='price-section px-4 sm:px-6'>
                      <div className='title'>Estimated price</div>
                      <div className='price'>{(selectedPrice?.price ?? 0) !== 0 ? `₹${formatPriceWithCommas(selectedPrice?.price ?? 0)}` : (props?.variantData?.isDBP===true ? "Contact Store to get price" :"Please select variant")}</div>
                    </div>

                    <button
                      type='button'
                      className='close relative cursor-pointer rounded-md text-black'
                      onClick={handleCloseButton}
                    >
                      <span className='absolute -inset-2.5'></span>
                      <span className='sr-only'>Close panel</span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='41'
                        height='41'
                        viewBox='0 0 41 41'
                        fill='none'
                      >
                        <path
                          d='M20.5003 38.8646C10.3699 38.8646 2.13574 30.6304 2.13574 20.5C2.13574 10.3696 10.3699 2.13542 20.5003 2.13542C30.6307 2.13542 38.8649 10.3696 38.8649 20.5C38.8649 30.6304 30.6307 38.8646 20.5003 38.8646ZM20.5003 4.69792C11.7878 4.69792 4.69824 11.7875 4.69824 20.5C4.69824 29.2125 11.7878 36.3021 20.5003 36.3021C29.2128 36.3021 36.3024 29.2125 36.3024 20.5C36.3024 11.7875 29.2128 4.69792 20.5003 4.69792Z'
                          fill='#292D32'
                        />
                        <path
                          d='M15.6657 26.6158C15.3411 26.6158 15.0165 26.4963 14.7602 26.24C14.2648 25.7446 14.2648 24.9246 14.7602 24.4292L24.4294 14.76C24.9248 14.2646 25.7448 14.2646 26.2402 14.76C26.7357 15.2554 26.7357 16.0754 26.2402 16.5708L16.5711 26.24C16.3319 26.4963 15.9902 26.6158 15.6657 26.6158Z'
                          fill='#292D32'
                        />
                        <path
                          d='M25.3348 26.6158C25.0102 26.6158 24.6857 26.4963 24.4294 26.24L14.7602 16.5708C14.2648 16.0754 14.2648 15.2554 14.7602 14.76C15.2557 14.2646 16.0757 14.2646 16.5711 14.76L26.2402 24.4292C26.7357 24.9246 26.7357 25.7446 26.2402 26.24C25.984 26.4963 25.6594 26.6158 25.3348 26.6158Z'
                          fill='#292D32'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='flex h-full flex-col bg-white shadow-xl'>
                    <div className='px-3 sm:px-6 mb-[35%]'>
                      <div className='customization-container'>
                        {/* Choice of Metal */}
                        {metals?.length &&
                          <>
                            <div className='section'>
                              <div className='metal-text'>Choice of Metal</div>
                              <div className='options'>                             
                                {metals.map(metal => (
                                  <div
                                    key={metal.name}
                                    className='option'
                                    style={{backgroundColor : selectedMetal?.name === metal.name || metals.length===1 ? data.color.shade1 : data.color.shade2}}
                                    onClick={() => handleMetalSelect(metal)}
                                  >
                                    <p>{metal.name}</p>
                                    <div className='stock'>{metal.stock}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <hr
                              style={{ borderColor: 'black', margin: '4% -9%' }}
                            ></hr>
                          </>
                        }
                        {/* Diamond Quality */}                       
                        {qualities?.length ?
                          <>
                            <div className='section'>
                              <div className='flex justify-between'>
                                <div className='metal-text'>Diamond Quality</div>
                                <div className='metal-text cursor-pointer' style={{color : data.color.secondaryColor}} onClick={() => {props.customizeModelClose('diamondGuide')}}>Diamond Guide</div>
                              </div>
                              <div className='options'>
                                {qualities.length && qualities?.map(quality => (
                                  <div
                                    key={quality?.name}
                                    className={`option`}
                                    style={{backgroundColor : selectedQuality?.productId === quality?.productId ? data.color.shade1 : data.color.shade2}}
                                    onClick={() =>
                                      handleQualitySelect(quality)
                                    }
                                  >
                                    <p>{quality?.name}</p>
                                    <div className='stock'>{quality?.stock}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <hr
                              style={{ borderColor: 'black', margin: '4% -9%' }}
                            ></hr>
                            </>
                           : null
                        }
                        {/* Select Size */}
                        <div className="section overflow-x-auto max-h-[51vh] min-h-[20vh]">
                          <div className='flex justify-between'>
                            <div className='metal-text'>Select Size</div>
                            {(props?.variantData?.parentCategoryName.toLowerCase()==="chains" || props?.variantData?.parentCategoryName.toLowerCase()==="ring" || props?.variantData?.parentCategoryName.toLowerCase()==="bangles") &&
                              <div className='metal-text cursor-pointer' style={{color : data.color.secondaryColor}} onClick={() => {props.customizeModelClose('sizeGuide');}}>Size Guide</div>
                            }
                          </div>
                          <div className='options size-options'>
                            {sizes.map(sizeOption => (
                              <div
                                key={sizeOption.size}
                                className={`option ${selectedSize?.size === sizeOption.size ? 'selected' : ''}`}
                                style={{backgroundColor : selectedSize?.size === sizeOption.size ? data.color.shade1 : data.color.shade2}}
                                onClick={() =>
                                  handleSizeSelect(sizeOption)
                                }
                              >
                                <p>{sizeOption.size}</p>
                                <p className='size'>{sizeOption.mm}</p>
                                <div className='stock'>{sizeOption.stock}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  <div>
                  <div className='fixed-button-container'>
                      <button
                        className='confirm-button w-full font-bold'
                        onClick={handleConfirmCustomization}
                        disabled={selectedMetal===null && selectedQuality===null && selectedSize===null ? true :false}
                        style={{backgroundColor : data.color.ctaPrimary}}
                      >
                        CONFIRM CUSTOMIZATION
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div
          class='relative z-10'
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div
            class='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            aria-hidden='true'
          ></div>

          <div class='fixed inset-0 z-10 mt-[25%] w-screen'>
            <div class='flex min-h-full items-end justify-center p-4 text-center sm:p-0'>
              <div class='relative transform overflow-hidden rounded-lg rounded-t-[50px] bg-white text-left shadow-xl transition-all sm:my-0 sm:w-full sm:max-w-lg'>
                <div class='m:pb-4 bg-white pb-4'>
                  <div>
                    <div className='header flex justify-between sticky top-0 z-10' style={{backgroundColor : data.color.shade3}}>
                      <div className='price-section px-4 sm:px-6'>
                        <div className='title'>Estimated price</div>
                        {!selectedPrice?.price ? (
                          <div className='price2'>{(selectedPrice?.price ?? 0) !== 0 ? `₹${formatPriceWithCommas(selectedPrice?.price ?? 0)}` : (props?.variantData?.isDBP===true ? "Contact Store to get price" :"Please select variant")}</div>
                        ) : (
                          <div className='price'>{(selectedPrice?.price ?? 0) !== 0 ? `₹${formatPriceWithCommas(selectedPrice?.price ?? 0)}` : (props?.variantData?.isDBP===true ? "Contact Store to get price" :"Please select variant")}</div>
                        ) }
                      </div>

                      <button
                        type='button'
                        className='close relative cursor-pointer rounded-md text-black'
                        onClick={handleCloseButton}
                      >
                        <span className='absolute -inset-2.5'></span>
                        <span className='sr-only'>Close panel</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='41'
                          height='41'
                          viewBox='0 0 41 41'
                          fill='none'
                        >
                          <path
                            d='M20.5003 38.8646C10.3699 38.8646 2.13574 30.6304 2.13574 20.5C2.13574 10.3696 10.3699 2.13542 20.5003 2.13542C30.6307 2.13542 38.8649 10.3696 38.8649 20.5C38.8649 30.6304 30.6307 38.8646 20.5003 38.8646ZM20.5003 4.69792C11.7878 4.69792 4.69824 11.7875 4.69824 20.5C4.69824 29.2125 11.7878 36.3021 20.5003 36.3021C29.2128 36.3021 36.3024 29.2125 36.3024 20.5C36.3024 11.7875 29.2128 4.69792 20.5003 4.69792Z'
                            fill='#292D32'
                          />
                          <path
                            d='M15.6657 26.6158C15.3411 26.6158 15.0165 26.4963 14.7602 26.24C14.2648 25.7446 14.2648 24.9246 14.7602 24.4292L24.4294 14.76C24.9248 14.2646 25.7448 14.2646 26.2402 14.76C26.7357 15.2554 26.7357 16.0754 26.2402 16.5708L16.5711 26.24C16.3319 26.4963 15.9902 26.6158 15.6657 26.6158Z'
                            fill='#292D32'
                          />
                          <path
                            d='M25.3348 26.6158C25.0102 26.6158 24.6857 26.4963 24.4294 26.24L14.7602 16.5708C14.2648 16.0754 14.2648 15.2554 14.7602 14.76C15.2557 14.2646 16.0757 14.2646 16.5711 14.76L26.2402 24.4292C26.7357 24.9246 26.7357 25.7446 26.2402 26.24C25.984 26.4963 25.6594 26.6158 25.3348 26.6158Z'
                            fill='#292D32'
                          />
                        </svg>
                      </button>
                    </div>
                    <div className=' overflow-x-auto customize-details'>
                    <div className='px-3 sm:px-6'>
                      <div className='customization-container'>
                        {/* Choice of Metal */}
                        {metals?.length &&
                        <>
                        <div className='section'>
                          <div className='metal-text'>Choice of Metal</div>
                          <div className='options'>
                            {metals.map(metal => (
                              <div
                                key={metal.name}
                                className={`option`}
                                style={{backgroundColor : selectedMetal?.name === metal.name || metals.length===1 ? data.color.shade1 : data.color.shade2}}
                                onClick={() => handleMetalSelect(metal)}
                              >
                                <p>{metal.name}</p>
                                <div className='stock'>{metal.stock}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <hr
                          style={{ borderColor: 'black', margin: '4% -9%' }}
                        ></hr>
                        </>
                        }
                        {/* Diamond Quality */}
                        {qualities?.length ?
                          <>
                        <div className='section'>
                          <div className='flex justify-between'>
                            <div className='metal-text'>Diamond Quality</div>
                            <div className='metal-text cursor-pointer' style={{color : data.color.secondaryColor}} onClick={() => {props.customizeModelClose('diamondGuide')}}>Diamond Guide</div>
                          </div>
                          <div className='options'>
                            {qualities.length && qualities.map(quality => (
                              <div
                                key={quality.name}
                                className={`option`}
                                style={{backgroundColor : selectedQuality?.productId === quality?.productId ? data.color.shade1 : data.color.shade2}}
                                onClick={() =>
                                  handleQualitySelect(quality)
                                }
                              >
                                <p>{quality.name}</p>
                                <div className='stock'>{quality.stock}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <hr
                          style={{ borderColor: 'black', margin: '4% -9%' }}
                        ></hr>
                         </>
                           : null
                        }
                        {/* Select Size */}
                        <div className='section overflow-x-auto max-h-[21rem] min-h-72'>
                          <div className='flex justify-between'>
                            <div className='metal-text'>Select Size</div>
                            {(props?.variantData?.parentCategoryName.toLowerCase()==="chains" || props?.variantData?.parentCategoryName.toLowerCase()==="ring" || props?.variantData?.parentCategoryName.toLowerCase()==="bangles") &&
                              <div className='metal-text cursor-pointer	' style={{color : data.color.secondaryColor}} onClick={() => {props.customizeModelClose('sizeGuideMobile');}}>Size Guide</div>
                            }
                          </div>
                          <div className='options size-options'>
                            {sizes.map(sizeOption => (
                              <div
                                key={sizeOption.size}
                                className={`option`}
                                style={{backgroundColor : selectedSize?.size === sizeOption.size ? data.color.shade1 : data.color.shade2}}
                                onClick={() =>
                                  handleSizeSelect(sizeOption)
                                }
                              >
                                <p>{sizeOption.size}</p>
                                <p className='size'>{sizeOption.mm}</p>
                                <div className='stock'>{sizeOption.stock}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className='fixed-button-container'>
                      <button
                        className='confirm-button w-full font-bold'
                        onClick={handleConfirmCustomization}
                        disabled={selectedMetal===null && selectedQuality===null && selectedSize === null ? true :false}
                        style={{backgroundColor : data.color.ctaPrimary}}
                      >
                        CONFIRM CUSTOMIZATION
                      </button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Customize;
