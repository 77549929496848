import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cart: [],
    discount: 0,
    checkout: null
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action) {
            let exist = state.cart.find(x => x.id === action.payload.product.id);
            if (exist) {
                console.log("NEW PRICE IS :", parseFloat(exist.totalPrice?.toFixed(2)));
                state.cart = state.cart.map(x =>
                    x.id === action.payload.product.id
                        ? {
                            ...exist,
                            quantity: exist.quantity + 1,
                            totalPrice: parseFloat(exist.totalPrice?.toFixed(2))
                        }
                        : x
                );
            } else {
                state.cart.push(action.payload.product);
            }
        },
        deleteFromCart(state, action) {
            state.cart = state.cart.filter(item => item.id !== action.payload.id);
        },
        emptyCart(state) {
            state.cart = [];
        },
        decrementQuantity(state, action) {
            let existProduct = state.cart.find(x => x.id === action.payload.id);
            try {
                if (existProduct && existProduct.quantity > 1) {
                    state.cart = state.cart.map(x =>
                        x.id === action.payload.id
                            ? {
                                ...existProduct,
                                quantity: existProduct.quantity - 1,
                                totalPrice: parseFloat(existProduct.totalPrice?.toFixed(2))
                            }
                            : x
                    );
                } else if (existProduct.quantity <= 1) {
                    state.cart = state.cart.filter(item => item.id !== action.payload.id);
                }
            } catch (err) {
                console.log(err);
            }
        },
        createCheckout(state, action) {
            state.checkout = action.payload;
        },
        updateCheckoutBillingAddress(state, action) {
            let tempBillingCheckout = structuredClone(state.checkout);
            tempBillingCheckout.billingAddress = action.payload;
            state.checkout = tempBillingCheckout;
        },
        updateCheckoutShippingAddress(state, action) {
            let tempShippingCheckout = structuredClone(state.checkout);
            tempShippingCheckout.shippingAddress = action.payload;
            state.checkout = tempShippingCheckout;
        },
        deleteCheckout(state) {
            state.checkout = null;
        }
    }
});

export const {
    addToCart,
    deleteFromCart,
    emptyCart,
    decrementQuantity,
    createCheckout,
    updateCheckoutBillingAddress,
    updateCheckoutShippingAddress,
    deleteCheckout
} = cartSlice.actions;

export default cartSlice.reducer;
