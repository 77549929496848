import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import { VideoPlay } from 'icons';

const SectionVideo = ({ url, poster, data }) => {
  const [playing, setPlaying] = useState(true);

  const handlePlayPause = () => {
    setPlaying(prevPlaying => !prevPlaying);
  };

  useEffect(() => {
    setPlaying(true);
  }, []);

  return (
    <section className='pb-5'>
      <div className='font-dm-serif-display h-[400px] md:h-[200px]'>
        <div className='relative flex h-full w-full items-end justify-center overflow-hidden bg-black'>
          <div className='relative z-10 flex h-[650px] flex-col items-center justify-end'>
            {/* <VideoPlay /> */}
            {/* {playing && (
              <button
                className='absolute left-1/2 top-1/2 z-10 flex aspect-square w-[60px] -translate-x-1/2 -translate-y-1/2 rounded-full text-white'
                onClick={handlePlayPause}
              >
              </button>
            )} */}

            {/* <h1 className='font-dm-serif-display mb-10 flex flex-col text-[100px] leading-[80px] text-[#23309F]'>
              <span>Elegance &</span>
              <span className='translate-x-[40%]'>Opulence!</span>
            </h1> */}
          </div>

          <ReactPlayer
            url={data ?? url}
            muted
            loop
            progressInterval={0}
            playing={playing}
            controls={false}
            width='100%'
            height='100%'
            className='video-container absolute left-0 top-0 z-0 w-full sm:h-[236px] h-[650px]'
          />
          <span className='absolute bottom-0 left-0 h-[100px] w-full bg-gradient-to-t from-white to-transparent'></span>
        </div>
        <div className='z-[1] mt-[-90px] flex items-center justify-center bg-gradient-to-b from-transparent to-white pt-24'></div>
      </div>
      {/* <div className='relative mx-[20px] mt-[20px] hidden xs:block'>
        <input
          type='text'
          className='w-full rounded-[8px] border border-[#908282] py-2 pl-10 pr-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
          placeholder='Search...'
        />
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <FontAwesomeIcon icon={faSearch} className='text-gray-500' />
        </div>
      </div> */}
    </section>
  );
};

export default SectionVideo;
