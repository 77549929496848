import * as React from 'react';
const Maestro = props => (
  <svg
    width={47}
    height={50}
    viewBox='0 0 28 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x={0.2}
      y={0.2}
      width={26.5423}
      height={17.7522}
      rx={5.5}
      fill='white'
      stroke='#D9D9D9'
    />
    <path
      d='M16.3759 9.47562C16.3759 12.4544 13.9718 14.8692 11.0061 14.8692C8.04048 14.8692 5.63635 12.4544 5.63635 9.47562C5.63635 6.49682 8.04048 4.08203 11.0061 4.08203C13.9718 4.08203 16.3759 6.49682 16.3759 9.47562Z'
      fill='#ED0006'
    />
    <path
      d='M23.3421 9.47562C23.3421 12.4544 20.938 14.8692 17.9723 14.8692C15.0067 14.8692 12.6025 12.4544 12.6025 9.47562C12.6025 6.49682 15.0067 4.08203 17.9723 4.08203C20.938 4.08203 23.3421 6.49682 23.3421 9.47562Z'
      fill='#0099DF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4892 13.5813C15.6438 12.592 16.3759 11.12 16.3759 9.4762C16.3759 7.83242 15.6438 6.36038 14.4892 5.37109C13.3346 6.36038 12.6025 7.83242 12.6025 9.4762C12.6025 11.12 13.3346 12.592 14.4892 13.5813Z'
      fill='#6C6BBD'
    />
  </svg>
);
export default Maestro;
