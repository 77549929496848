import React from 'react';
import { Link } from 'react-router-dom';
import { useGetBackgroundColor } from "../../../src/api/index";

const MyAccountMobile = () => {
    const { data } = useGetBackgroundColor({ onLoad: true });
    const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
  return (
    <div className="px-5 py-5">
      <div className="flex flex-col items-start">
        <h2 className="text-xl font-bold">{refUserDetails?.firstName + " "+ refUserDetails?.lastName}</h2>
        <p className="text-sm text-gray-500">{refUserDetails?.phoneNumber}</p>
        <Link to="/edit-profile" style={{color : data.color.secondaryColor}}>
          Edit Profile
        </Link>
      </div>

      <div className="mt-8">
        <h3 className="text-md font-semibold">ORDERS</h3>
        <div className="border-b">
          <Link to="/orders" className="flex justify-between items-center py-3 text-gray-600">
            Orders
            <span className='font-bold'>&gt;</span>
          </Link>
        </div>
        {/* <div className="border-b">
          <Link to="/payment" className="flex justify-between items-center py-3 text-gray-600">
            Payment
            <span className='font-bold'>&gt;</span>
          </Link>
        </div>
        <div className="border-b">
          <Link to="/refunds" className="flex justify-between items-center py-3 text-gray-600">
            Manage Refunds
            <span className='font-bold'>&gt;</span>
          </Link>
        </div> */}
      </div>

      {/* <div className="mt-8">
        <h3 className="text-md font-semibold">APPOINTMENTS</h3>
        <div className="border-b">
          <Link to="/try-at-home" className="flex justify-between items-center py-3 text-gray-600">
            Try at Home
            <span className='font-bold'>&gt;</span>
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-md font-semibold">OFFERS</h3>
        <div className="border-b">
          <Link to="/coupons" className="flex justify-between items-center py-3 text-gray-600">
            Coupons
            <span className='font-bold'>&gt;</span>
          </Link>
        </div>
      </div> */}

      <div className="mt-8">
        <h3 className="text-md font-semibold">ACCOUNTS</h3>
        <div className="border-b">
          <Link to="/profile" className="flex justify-between items-center py-3 text-gray-600">
            Profile
            <span className='font-bold'>&gt;</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyAccountMobile;
