import React, { useEffect, useState } from 'react';
import ProductList from './productList/ProductList';
import MobileFilter from './filterMenu/mobileFilter';
import DesktopFilter from './filterMenu/desktopFilter';
import { useResponsive } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import Loader from 'components/Loader/Loader.jsx';
import { useGetDBPInventoryProductsApi, useGetStoreProductsApi } from 'api';
import { useGetBackgroundColor } from "../../../src/api/index";

const ProductsPage = () => {
  const { data: themeColor } = useGetBackgroundColor({ onLoad: true });
  const [searchParams] = useSearchParams();
  const { isDesktop,isTablet, isMobile } = useResponsive();
  
  const { data: storeDBPInventory, loading: loading1 } = useGetDBPInventoryProductsApi({ onLoad: true, searchParams });
  const { data: storeProducts, loading } = useGetStoreProductsApi({ onLoad: true, searchParams });

  const [filteredData, setFilteredData] = useState([]);
  const [runLoader, setRunLoader] = useState(false);
  const [sortFilter, setSortFilter] = useState('latest');
  
  let dataFinal = storeProducts && storeDBPInventory ? [...storeProducts, ...storeDBPInventory] : [];
  let totalDesigns = 0;

  // Ensure useEffect is not called conditionally
  useEffect(() => {
    console.log("loading1",loading1,"dataFinal",dataFinal)
    setRunLoader(loading1);
  }, [loading1, searchParams]);

  // Guard clause to show loader when data is loading
  if (loading===true || loading1===true || runLoader===true || (getNameById(dataFinal, searchParams.get("categories"))==="No Category Found" && dataFinal.length===0) ) {
    return <Loader />;
  }

  // Process data once loading completes
  const { metalTypeData, metalCaratData, ocassianData, genderData, metalColorData, gemstoneData } = getCounts(dataFinal);

  dataFinal.forEach(category => {
    category.sub.forEach(subcategory => {
      totalDesigns += subcategory.products.length;
    });
  });

  const onFilterChange = (filter) => setFilteredData(filter);

  const sortMobileFilter = (filterData) => setSortFilter(filterData);

  return (
    <>
      <div className=''>
        {getNameById(dataFinal, searchParams.get("categories"))!=="No Category Found" &&
          (<footer
            className={`py-5 mb-5 mt-[-1px] font-semibold flex flex-col items-start text-sm px-2 text-white`}
            style={{ backgroundColor: themeColor?.color?.primaryColor }}
          >
              <div className={`${!isMobile ? 'px-[80px]' : 'pl-[20px]'}`}>
                <div className="flex flex-col mb-1" style={{ letterSpacing: '0.1em' }}>
                  <span className='text-[20px] text-spacing-2'>
                    {dataFinal[0]?.name?.toUpperCase()} | {totalDesigns} Designs
                  </span>
                </div>
                <div style={styles.paymentIcons} className="text-[12px]">
                  <span>
                    HOME > JEWELLERY > {getNameById(dataFinal, searchParams.get("categories")).toUpperCase()}
                  </span>
                </div>
              </div>
          </footer>) 
          }
          {getNameById(dataFinal, searchParams.get("categories"))==="No Category Found" &&
            <> 
              <div className='py-5'></div>
            </>
          }
          {console.log("isTablet",isTablet)}
        <div className=''>
          <div className={`flex ${!isMobile ? 'gap-x-5' : ''}`}>
            {!isMobile && !isTablet && (
              <DesktopFilter
                metalTypeData={metalTypeData}
                metalCaratData={metalCaratData}
                ocassianData={ocassianData}
                genderData={genderData}
                metalColorData={metalColorData}
                gemstoneData={gemstoneData}
                onFilterChange={onFilterChange}
              />
            )}
            {(isMobile || isTablet) && (
              <MobileFilter
                sortMobileFilter={sortMobileFilter}
                metalTypeData={metalTypeData}
                metalCaratData={metalCaratData}
                ocassianData={ocassianData}
                genderData={genderData}
                metalColorData={metalColorData}
                gemstoneData={gemstoneData}
                onFilterChange={onFilterChange}
                filteredData={filteredData}
              />
            )}
            <ProductList dataFinal={dataFinal} filter={filteredData} sortFilter={sortFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

function getCounts(data) {
  const metalTypeCount = {};
  const metalCaratCount = {};
  const ocassianCount = {};
  const genderCount = {};
  const metalColorCount = {};
  const gemstoneCount = {};

  data.forEach(category => {
    category.sub.forEach(subcategory => {
      subcategory.products.forEach(product => {
        // Count each attribute
        if (product.metalType) metalTypeCount[product.metalType] = (metalTypeCount[product.metalType] || 0) + 1;
        if (product.metalCarat) metalCaratCount[product.metalCarat] = (metalCaratCount[product.metalCarat] || 0) + 1;
        if (product.ocassian) ocassianCount[product.ocassian] = (ocassianCount[product.ocassian] || 0) + 1;
        if (product.gender) genderCount[product.gender] = (genderCount[product.gender] || 0) + 1;
        if (product.metalColor) metalColorCount[product.metalColor] = (metalColorCount[product.metalColor] || 0) + 1;
        if (product.gemstone) gemstoneCount[product.gemstone] = (gemstoneCount[product.gemstone] || 0) + 1;
      });
    });
  });

  return {
    metalTypeData: formatCountData(metalTypeCount),
    metalCaratData: formatCountData(metalCaratCount),
    ocassianData: formatCountData(ocassianCount),
    genderData: formatCountData(genderCount),
    metalColorData: formatCountData(metalColorCount),
    gemstoneData: formatCountData(gemstoneCount),
  };
}

function formatCountData(countObj) {
  return Object.entries(countObj).map(([name, count]) => ({ name, count }));
}

const getNameById = (data, searchId) => {
  for (const category of data) {
    if (category.id === searchId) return category.name;
    if (category.sub) {
      for (const subCategory of category.sub) {
        console.log("subCategory line 153",subCategory)
        if (subCategory.id === searchId) return subCategory.description || subCategory.name;
      }
    }
  }
  return 'No Category Found';
};

const styles = {
  paymentIcons: {
    display: 'flex',
    alignItems: 'right',
    gap: 10,
  },
};

export default ProductsPage;
