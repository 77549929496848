import { compact } from "@apollo/client/utilities";
import AddAddress from "components/checkout/addAddress";
import CheckOutPage from "components/checkout/checkout";
import OrderSummary from "components/checkout/orderSummary";
import OrderItemDetails from "components/orders/OrderItemDetails";
import * as _ from "pages";
import SingleProductDetailsPage from "pages/products/singleProductDetails";
import StoreListPage from "pages/storeList";


const routesData = [
    // Home
    // {
    //     path: '/subscription-payment',
    //     component: _.B2BPaymentPage,
    //     exact: true,
    //     lazy: true,
    //     // auth: true,
    // },
    // Home
    {
        path: '/',
        component: _.HomePage,
        exact: true,
        lazy: true,
        // auth: true,
    },

    // Auth
    {
        path: '/login',
        component: _.LoginPage,
        exact: true,
    },
    {
        path: '/account',
        component: _.AccountPage,
        exact: true,
    },
    {
        path: '/profile',
        component: _.ProfilePage,
        exact: true,
    },
    {
        path:'/edit-profile',
        component: _.ProfileEditPage,
        exact: true,
    },
    {
        path: '/orders',
        component: _.OrderPage,
        exact: true,
    },
    {
        path: '/register',
        component: _.RegisterPage,
        exact: true,
    },
    {
        path: '/verify',
        component: _.OtpPage,
        exact: true,
    },
    // {
    //     path: '/checkout', // product checkout + Login/SignUp page
    //     component: LoginPage,
    //     exact: true,
    // },
    {
        path: process.env.REACT_APP_PRODUCT_ROUTE,
        component: _.ProductsPage,
        exact: true,
    },
    {
        path: `/product-details/:id`,
        component: SingleProductDetailsPage,
        exact: true,
    },
    {
        path: `/checkout`,
        component: CheckOutPage,
        exact: true,
    },
    {
        path: `/orderSummary`,
        component: OrderSummary,
        exact: true,
    },
    {
        path: `/addAddress`,
        component: AddAddress,
        exact: true,
    },
    {
        path: `/storeList`,
        component: StoreListPage,
        exact: true,
    },
    {
        path: `/order-item-details/:id`,
        component: OrderItemDetails,
        exact: true,
    },
    // {
    //     path: '/dashboard',
    //     component: DashboardPage,
    //     exact: true,
    //     auth: true,
    //     lay: true,
    //     routes: [
    //         {
    //             path: '/dashboard/overview',
    //             component: DashboardOverviewPage,
    //             exact: true,
    //             lay: true,
    //         },
    //         {
    //             path: '/dashboard/settings',
    //             component: DashboardSettingsPage,
    //             exact: true,
    //             auth: true,
    //         },
    // ],
    // },    
];

export default routesData;