import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import category from 'data/category';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import carousel styles
import './singleProduceDetails.css'
import Customize from '../customizeModel/customize';
import PriceBreakUp from '../priceBreakUpModel/priceBreakUp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay, EffectFade, Scrollbar } from 'swiper/modules';
import 'swiper/swiper-bundle.css'; // Import Swiper CSS
import { useGetCategoryPricing } from 'api';
import { useCreateCheckout,useAddCheckoutLine } from "./cartAPI";
import { useNavigate, Link   } from 'react-router-dom';
import { useMarqueeBannerApi } from 'pages/home/api';
import { useGetBackgroundColor } from "../../../../src/api/index";
import { useSelector } from 'react-redux';
import SizeGuide from 'components/sizeGuide';
import ShareToWhatsApp from 'components/shareDetails';


function formatPriceWithCommas(price) {
  // Convert the price to a string
  const priceString = price.toString();

  // Split the price into integer and decimal parts (if any)
  const [integerPart, decimalPart = ""] = priceString.split(".");

  // Add commas to the integer part
  let formattedIntegerPart = "";
  for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
    if ((count === 3 && i !== 0) || ((count + 1) % 2 === 0 && count > 3)) {
      formattedIntegerPart = "," + formattedIntegerPart;
    }

    formattedIntegerPart = integerPart[i] + formattedIntegerPart;
  }

  // Combine the formatted parts and return the result
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
}

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className='absolute left-0 top-1/2 z-10 -translate-y-1/2 transform p-2'
    style={{ fontSize: '20px' }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M13.9802 5.31975L10.7702 8.52975L8.80023 10.4897C7.97023 11.3197 7.97023 12.6697 8.80023 13.4997L13.9802 18.6797C14.6602 19.3597 15.8202 18.8697 15.8202 17.9197V12.3097V6.07975C15.8202 5.11975 14.6602 4.63975 13.9802 5.31975Z'
        fill='#292D32'
      />
    </svg>
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className='absolute right-0 top-1/2 z-10 -translate-y-1/2 transform p-2 text-white'
    style={{ fontSize: '20px' }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M15.1997 10.4899L13.2297 8.51993L10.0197 5.30993C9.33969 4.63993 8.17969 5.11993 8.17969 6.07993V12.3099V17.9199C8.17969 18.8799 9.33969 19.3599 10.0197 18.6799L15.1997 13.4999C16.0297 12.6799 16.0297 11.3199 15.1997 10.4899Z'
        fill='#292D32'
      />
    </svg>
  </button>
);

const SingleProductDetails = ({ product }) => {
  const menuData = useSelector(state => state.menu?.menuData); 
  const storeData = useSelector((state) => state.storeData);
  const createCheckout = useCreateCheckout();
  const addCheckoutLine = useAddCheckoutLine();
  const [categoryPricingParams, setCategoryPricingParams] = useState({});
  const prevRef = useRef(null);
  const nextRef = useRef(null); 
  const swiperRef = useRef(null);
  const sliderRef = useRef(null);
  const [productDetails, setProductDetails] = useState(product);
  const [customizeModel, setCustomizeModel] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [priceBreakUpModel, setPriceBreakUpModel] = useState(false);
  const [ringSizeGuideModel, setRingSizeGuideModel] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [categoryPricingFlag, setCategoryPricingFlag] = useState(true);
  const [diamondGuideModel, setDiamondGuideModel] = useState(false);
  const { data: categoryPricingData } = useGetCategoryPricing(categoryPricingParams);
  const { data: marqueeBannerData } = useMarqueeBannerApi({ onLoad: true });
  const { data } = useGetBackgroundColor({ onLoad: true });
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (productDetails?.images?.length) {
      setSelectedImage(productDetails.images[0].url);
    }
  }, [productDetails]);

  useEffect(() => {
    if (productDetails?.isDBP && productDetails?.allVariants?.length && productDetails?.price === 0) {
      const firstVariant = productDetails.allVariants[0];
      const categoryId = productDetails.parentCategoryId;
      const subcategoryId = productDetails.childCategoryId;
      const metalType = firstVariant.metalTypeName.toLowerCase();
      const carat = firstVariant.metalCarat;
      const stoneType = firstVariant.type === "studded"
        ? JSON.parse(firstVariant.gemstoneDetails)[0].name.toLowerCase()
        : "none";
      
      setCategoryPricingParams(prev => {
        const newParams = { categoryId, subcategoryId, metalType, carat, stoneType };
        return JSON.stringify(prev) !== JSON.stringify(newParams) ? newParams : prev;
      });
    }
  }, [productDetails]);

  useEffect(() => {

    if (categoryPricingData && productDetails?.isDBP && productDetails?.allVariants?.length && productDetails?.price === 0 ) {
      const firstVariant = productDetails.allVariants[0];
      const variantStoneType = firstVariant.type === "studded"
        ? JSON.parse(firstVariant.gemstoneDetails)[0].name.toLowerCase()
        : "none";
        const categoryPricing = categoryPricingData?.categoryPrice[0];
      if(categoryPricing){       
        const metalRate = productDetails.metalRates[`${categoryPricing.metalType}${categoryPricing.carat}kRate`];
        const netWeight = parseFloat(firstVariant.netWeight);
        const totalMetalCharges = metalRate * netWeight;
  
        if (!selectedVariant &&
          firstVariant.price === 0 &&
          categoryPricing.carat === parseInt(firstVariant.metalCarat) &&
          categoryPricing.stoneType.toLowerCase() === variantStoneType &&
          categoryPricing.metalType === firstVariant.metalTypeName.toLowerCase()) {
  
          const dbMCMode = categoryPricing.designBankMakingChargeMode ;
          const dbMC = categoryPricing.designBankMakingCharge;
          const makingCharge = parseFloat(parseFloat(dbMCMode?.toLowerCase() === 'percent' ? ((totalMetalCharges * dbMC)/100) : (dbMCMode?.toLowerCase() === 'flat' ? (dbMC): (totalMetalCharges + (dbMC*netWeight)))).toFixed(3));       
          
          const dbWCMode = categoryPricing.designBankWastageChargeMode;
          const dbWC = categoryPricing.designBankWastageCharge;       
          const wastageCharge = parseFloat(parseFloat(dbWCMode?.toLowerCase() === 'percent' ? ((totalMetalCharges * dbWC)/100) : (dbWCMode?.toLowerCase() === 'flat' ? (dbWC): (totalMetalCharges + (dbWC*netWeight)))).toFixed(3))
          
          const totalPrice = totalMetalCharges + makingCharge + wastageCharge;
          productDetails.price = totalPrice + (totalPrice * 0.03);
          console.log("firstVariant.gemstoneDetails Line 170 ",firstVariant.gemstoneDetails)
          productDetails.priceBreakdown = {
            metalType: firstVariant.metalTypeName,
            metalPrice: metalRate,
            gemstones: firstVariant.type?.toLowerCase() === "studded" ? JSON.parse(firstVariant.gemstoneDetails.replace(/'/g, '"')).map(gem => gem?.name) : [],
            totalMakingCharge : makingCharge,
            totalWastageCharge : wastageCharge,
            size: firstVariant.size,
            grossWeight: parseFloat(firstVariant.grossWeight),
            netWeight,
          };
          console.log("productDetails.priceBreakdown",productDetails.priceBreakdown)
          setProductDetails({ ...productDetails });
        }
        if (selectedVariant &&
          selectedVariant.price === 0 &&
          categoryPricing.carat === parseInt(selectedVariant.metalCarat) &&
          categoryPricing.stoneType.toLowerCase() === variantStoneType &&
          categoryPricing.metalType === selectedVariant.metalTypeName.toLowerCase()) {
  
          const dbMCMode = categoryPricing.designBankMakingChargeMode ;
          const dbMC = categoryPricing.designBankMakingCharge;
          const makingCharge = parseFloat(parseFloat(dbMCMode?.toLowerCase() === 'percent' ? ((totalMetalCharges * dbMC)/100) : (dbMCMode?.toLowerCase() === 'flat' ? (dbMC): (totalMetalCharges + (dbMC*netWeight)))).toFixed(3));       
          
          const dbWCMode = categoryPricing.designBankWastageChargeMode;
          const dbWC = categoryPricing.designBankWastageCharge;       
          const wastageCharge = parseFloat(parseFloat(dbWCMode?.toLowerCase() === 'percent' ? ((totalMetalCharges * dbWC)/100) : (dbWCMode?.toLowerCase() === 'flat' ? (dbWC): (totalMetalCharges + (dbWC*netWeight)))).toFixed(3))
          
          const totalPrice = totalMetalCharges + makingCharge + wastageCharge;
          productDetails.price = totalPrice + (totalPrice * 0.03);
          
          productDetails.priceBreakdown = {
            metalType: firstVariant.metalTypeName,
            metalPrice: metalRate,
            gemstones: firstVariant.type === "studded" ? JSON.parse(firstVariant.gemstoneDetails).map(gem => gem.name) : [],
            totalMakingCharge : makingCharge,
            totalWastageCharge : wastageCharge,
            size: firstVariant.size,
            grossWeight: parseFloat(firstVariant.grossWeight),
            netWeight,
          };
          setProductDetails({ ...productDetails });
        }

      }
      
    } else if (!productDetails?.isDBP && productDetails?.price === 0){
      setCategoryPricingFlag(false)
      
    }
  }, [categoryPricingParams, categoryPricingData, productDetails]);

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      // Bind custom navigation buttons
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.update();
    }
  }, []);

  useEffect(() => {
    setProductDetails(productDetails);
  }, [productDetails.price]);

  const certifiedBy = [
    { image: '/assets/img/productDetails/Certified_By_Section_1.png', index: 0 },
    { image: '/assets/img/productDetails/Certified_By Section_2.png', index: 1 },
    { image: '/assets/img/productDetails/Certified_By Section_3.png', index: 2 },
  ];

  function getFormattedDate(daysToAdd) {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
  
    const day = date.getDate();
    const suffix = getDaySuffix(day);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
  
    return `${day}${suffix} ${month} ${year}`;
  }
  
  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th all get 'th'
    switch (day % 10) {
        case 1:  return 'st';
        case 2:  return 'nd';
        case 3:  return 'rd';
        default: return 'th';
    }
  }

  const customizeModelHandle = (modalState, data) => {
    if (data) {
      const variant = productDetails?.allVariants.find(v => v.productId === data);     
      setSelectedVariant(variant);     
    }
    if(modalState === 'sizeGuide'){
     setRingSizeGuideModel(true);
    }else if(modalState === 'sizeGuideMobile'){
      setRingSizeGuideModel(true);
      setCustomizeModel(false);
    }else if(modalState === 'diamondGuide'){
      setRingSizeGuideModel(true);
      setCustomizeModel(false);
      setDiamondGuideModel(true);
    }else{
      setCustomizeModel(modalState);
    }
  };
  const ringSizeModelHandle = (data) => {
    setRingSizeGuideModel(data);
    setCustomizeModel(true);
    setDiamondGuideModel(false);
  }
  const priceBreakUpModelHandle = (data) => {
    setPriceBreakUpModel(data);
  };
  const navigate = useNavigate();
  const callAddToCart = async ()=>{
    try {
      let cart; 
      let userDetails = JSON.parse(
        localStorage.getItem(`vjw-${window.location.hostname}user-det`)
      );
      console.log("userDetails cart",userDetails)
      if(!userDetails){
        navigate({
          pathname: `/login`
        });  
      }
      console.log("userDetails cart",userDetails);
      let address = userDetails?.defaultBillingAddress ? userDetails?.defaultBillingAddress : (userDetails?.defaultShippingAddress ? userDetails?.defaultShippingAddress : userDetails?.addresses[0])     
      if(userDetails?.checkout===null){
        if(selectedVariant) {
          if(userDetails){
            cart = [{quantity:1, id : selectedVariant?.variantId, isDbp:productDetails?.isDBP
            }];
            const data = await createCheckout(cart,address);           
            // if(data && data?.checkoutCreate?.errors.length){
            //   alert("277 Something went wrong. Please try again after sometime.");
            // }
            userDetails.checkout = data?.checkoutCreate?.checkout;
            localStorage.setItem(
                `vjw-${window.location.hostname}user-det`,
                JSON.stringify(userDetails)
            );
            navigate({
              pathname: `/checkout`
            });
          }else{
            navigate({
              pathname: `/checkout`
            });   
          }
        }else{
          console.log("productDetails cart",productDetails)
          if(productDetails?.allVariants?.length){
            cart = [{quantity:1, id : productDetails?.allVariants[0]?.variantId, isDbp:productDetails?.isDBP}];           
            const data = await createCheckout(cart,address);           
            // if(data && data?.checkoutCreate?.errors.length){
            //   alert("Something went wrong. Please try again after sometime.");
            // }
            userDetails.checkout = data?.checkoutCreate?.checkout;
            localStorage.setItem(
                `vjw-${window.location.hostname}user-det`,
                JSON.stringify(userDetails)
            );
            navigate({
              pathname: `/checkout`
            });
          }
          
        }
      }else{
        if(selectedVariant) {
          if(userDetails){           
            console.log("At line 330")
            cart = [{quantity:1,id : selectedVariant?.variantId, isDbp:productDetails?.isDBP
            }];           
            const data = await addCheckoutLine(userDetails?.checkout?.id,cart);       
            if(data?.checkoutLinesAdd?.checkout){
              userDetails.checkout = data?.checkoutLinesAdd?.checkout;
            }    
            localStorage.setItem(
                `vjw-${window.location.hostname}user-det`,
                JSON.stringify(userDetails)
            );
            navigate({
              pathname: `/checkout`
            });
          }else{
            navigate({
              pathname: `/checkout`
            });   
          }
        }else{  
          console.log("At line 350")
          console.log("productDetails?.productVariant",productDetails?.allVariants)       
          if(productDetails?.allVariants?.length){
            cart = [{quantity:1,id : productDetails?.allVariants[0]?.variantId, isDbp:productDetails?.isDBP}];           
            const data = await addCheckoutLine(userDetails?.checkout?.id,cart);           
            // if(data && data?.checkoutCreate?.errors.length){
            //   alert("Something went wrong. Please try again after sometime.");
            // }
            console.log("API data",data)
            if(data?.checkoutLinesAdd?.checkout){
              userDetails.checkout = data?.checkoutLinesAdd?.checkout;
            }
            localStorage.setItem(
                `vjw-${window.location.hostname}user-det`,
                JSON.stringify(userDetails)
            );
            navigate({
              pathname: `/checkout`
            });
          }
        }
      }     
    } catch (error) {
      console.error('Checkout failed', error);
    }
  }
  const handleShare = async () => {
    const shareData = {
      title: 'Check this out!',
      text: 'Take a look at this amazing product!',
      url: window.location.href, // Current page URL
    };
  
    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log('Shared successfully!');
      } else {
        alert('Sharing is not supported on this device');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };
  if(productDetails){
    const path = `HOME / JEWELLERY / ${productDetails.childCategoryName ? productDetails.childCategoryName.toUpperCase() : '' } / ${productDetails.name ? productDetails.name.toUpperCase() : '' }`
    console.log("productDetails",productDetails);
    return (
      <>
        <section className='w-screen'>
          <div className='product-details-background'>
          <div className='w-[1460px]' style={{margin : '0 auto'}}>
            <p className='product-path pl-[7%] pt-2'>{path}</p>
            <div className='product-details-padding md:p-2 sm:p-2'>
              <div className='grid h-full grid-cols-12 gap-5 sm:grid-cols-1'>
                <div className='relative col-span-6 h-full md:col-span-12 sm:col-span-12'>
                  <div className='h-full'>
                    <div className='h-[470px] rounded-xl w-[470px]' style={{margin : '0 auto'}}>
                      {/* Image */}
                      <img
                        src={selectedImage} // Replace with your image path or import statement
                        alt='Product Image Not Found'
                        className='h-full w-full flex-shrink-0 rounded-xl object-cover object-center'
                      />
                      {/* Heart Icon */}
                      {/* <div className='absolute right-4 top-4'>
                      <div className='cursor-pointer rounded-full border border-gray-300 bg-gray-100 p-2 text-white shadow'>
                        <Like />
                      </div>
                    </div> */}
                    </div>
                    <div className='mt-2 h-full'>
                      <div className='relative h-[90px] w-[505px] px-4 mx-auto' >
                        <Swiper
                          ref={swiperRef}
                          modules={[Navigation]}
                          spaceBetween={1}
                          slidesPerView={5}
                          onSwiper={swiper => {
                            // Store swiper instance for later use
                            swiperRef.current = swiper;
                          }}
                          navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                          }}
                        >
                          {!productDetails &&
                            category?.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={image.img}
                                  alt={`Image ${index + 1}`}
                                  className={`h-[90px] w-[90px] flex-shrink-0 cursor-pointer object-cover ${selectedImage && selectedImage == image.url ? 'border border-black' : ''}`}
                                  onClick={() => {
                                    setSelectedImage(image.img);
                                  }}
                                />
                              </SwiperSlide>
                            ))}
                          {productDetails?.images?.map((image, index) => (
                            <SwiperSlide key={index}>
                              <img
                                src={image.url}
                                alt={`Image ${index + 1}`}
                                className={`h-[90px] w-[90px] flex-shrink-0 cursor-pointer object-cover ${selectedImage && selectedImage == image.url ? 'border border-black' : ''}`}
                                onClick={() => {
                                  setSelectedImage(image.url);
                                }}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        {/* Custom Navigation Buttons */}
                        <button
                          ref={prevRef}
                          className='absolute -left-5 top-1/2 z-10 -translate-y-1/2 transform p-2 text-white'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M13.9802 5.31975L10.7702 8.52975L8.80023 10.4897C7.97023 11.3197 7.97023 12.6697 8.80023 13.4997L13.9802 18.6797C14.6602 19.3597 15.8202 18.8697 15.8202 17.9197V12.3097V6.07975C15.8202 5.11975 14.6602 4.63975 13.9802 5.31975Z'
                              fill='#292D32'
                            />
                          </svg>
                        </button>
                        <button
                          ref={nextRef}
                          className='absolute -right-5 top-1/2 z-10 -translate-y-1/2 transform p-2 text-white'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M15.1997 10.4899L13.2297 8.51993L10.0197 5.30993C9.33969 4.63993 8.17969 5.11993 8.17969 6.07993V12.3099V17.9199C8.17969 18.8799 9.33969 19.3599 10.0197 18.6799L15.1997 13.4999C16.0297 12.6799 16.0297 11.3199 15.1997 10.4899Z'
                              fill='#292D32'
                            />
                          </svg>
                        </button>
                      </div>
                    <div
                    className='mt-3 rounded-[15px] bg-[#FDFDFD] p-1 w-[485px]  mx-auto'
                    style={{ border: '1px solid #00000033'}}
                  >
                    <Carousel
                      responsive={{
                        superLargeDesktop: {
                          // the naming can be any, depends on you.
                          breakpoint: { max: 4000, min: 3000 },
                          items: 1,
                        },
                        desktop: {
                          breakpoint: { max: 3000, min: 1024 },
                          items: 1,
                        },
                        tablet: {
                          breakpoint: { max: 1024, min: 464 },
                          items: 1,
                        },
                        mobile: {
                          breakpoint: { max: 464, min: 0 },
                          items: 1,
                        },
                      }}
                      swipeable={false}
                      draggable={false}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition='all .5'
                      transitionDuration={500}
                      containerClass='carousel-container'
                      dotListClass='custom-dot-list-style'
                      itemClass='carousel-item-padding-40-px'
                      customLeftArrow={<CustomLeftArrow />}
                      customRightArrow={<CustomRightArrow />}
                    >
                      {certifiedBy.map((item, index) => (
                        <div
                          key={index}
                          className='mx-1 flex h-[84px] min-w-[166px] flex-shrink-0 items-center justify-center'
                        >
                          <img
                            src={item.image}
                            alt={`Image ${index + 1}`}
                            className='h-[75px] w-[166px] flex-shrink-0 object-cover'
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  </div>
                  </div>
                </div>
                <div className='relative col-span-6 h-full md:col-span-12 md:-mt-2 sm:col-span-12 sm:-mt-2 w-[530px]'>
                  <div
                    className='overflow-hidden p-4'
                    // style={{ border: '1px solid #00000033' }}
                  >
                    <div className='py-2'>
                      <h1 className='product-title product-text'>
                        {productDetails.name}
                      </h1>
                      {console.log(
                        'selectedVariant Line 454',
                        selectedVariant,
                        'productDetails',
                        productDetails
                      )}
                      {selectedVariant &&
                      parseInt(selectedVariant?.price) > 0 ? (
                        <>
                          <p className='product-price product-text'>
                            ₹
                            {formatPriceWithCommas(
                              parseInt(selectedVariant?.price)
                            )}
                          </p>
                          <p className='product-price-des product-text -mt-1'>
                            (MRP Incl. of all taxes)
                          </p>
                        </>
                      ) : (productDetails?.pricingStatus === true &&
                          productDetails?.availability?.toLowerCase() ===
                            'ready') ||
                        productDetails?.price > 0 ? (
                        <>
                          <p className='product-price product-text'>
                            ₹
                            {formatPriceWithCommas(
                              parseInt(productDetails?.price)
                            )}
                          </p>
                          <p className='product-price-des product-text -mt-1'>
                            (MRP Incl. of all taxes)
                          </p>
                        </>
                      ) : (
                        <>
                          <br />
                        </>
                      )}
                      {/* <div className='mt-1 flex gap-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 21 21'
                        fill='none'
                      >
                        <path
                          d='M17.1675 9.7055L16.1035 8.6415C15.9215 8.4595 15.7745 8.1025 15.7745 7.8505V6.3385C15.7745 5.7225 15.2705 5.2185 14.6545 5.2185H13.1495C12.8975 5.2185 12.5405 5.0715 12.3585 4.8895L11.2945 3.8255C10.8605 3.3915 10.1465 3.3915 9.7125 3.8255L8.6345 4.8895C8.4595 5.0715 8.1025 5.2185 7.8435 5.2185H6.3385C5.7225 5.2185 5.2185 5.7225 5.2185 6.3385V7.8435C5.2185 8.0955 5.0715 8.4525 4.8895 8.6345L3.8255 9.6985C3.3915 10.1325 3.3915 10.8465 3.8255 11.2805L4.8895 12.3445C5.0715 12.5265 5.2185 12.8835 5.2185 13.1355V14.6405C5.2185 15.2565 5.7225 15.7605 6.3385 15.7605H7.8435C8.0955 15.7605 8.4525 15.9075 8.6345 16.0895L9.6985 17.1535C10.1325 17.5875 10.8465 17.5875 11.2805 17.1535L12.3445 16.0895C12.5265 15.9075 12.8835 15.7605 13.1355 15.7605H14.6405C15.2565 15.7605 15.7605 15.2565 15.7605 14.6405V13.1355C15.7605 12.8835 15.9075 12.5265 16.0895 12.3445L17.1535 11.2805C17.6085 10.8535 17.6085 10.1395 17.1675 9.7055ZM7.6965 8.3965C7.6965 8.0115 8.0115 7.6965 8.3965 7.6965C8.7815 7.6965 9.0965 8.0115 9.0965 8.3965C9.0965 8.7815 8.7885 9.0965 8.3965 9.0965C8.0115 9.0965 7.6965 8.7815 7.6965 8.3965ZM8.7675 12.9675C8.6625 13.0725 8.5295 13.1215 8.3965 13.1215C8.2635 13.1215 8.1305 13.0725 8.0255 12.9675C7.8225 12.7645 7.8225 12.4285 8.0255 12.2255L12.2255 8.0255C12.4285 7.8225 12.7645 7.8225 12.9675 8.0255C13.1705 8.2285 13.1705 8.5645 12.9675 8.7675L8.7675 12.9675ZM12.5965 13.2965C12.2045 13.2965 11.8895 12.9815 11.8895 12.5965C11.8895 12.2115 12.2045 11.8965 12.5895 11.8965C12.9745 11.8965 13.2895 12.2115 13.2895 12.5965C13.2895 12.9815 12.9815 13.2965 12.5965 13.2965Z'
                          fill='#2A5F3F'
                        />
                        <circle cx='10.5' cy='10.5' r='10' stroke='#2A5F3F' />
                      </svg>
                      <p className='product-text product-offer'>
                        Flat 1500 off over 75,000 and 3000 off over 1 lakh
                        purchase *TCA
                      </p>
                    </div> */}
                    </div>
                    <div className='py-1'>
                      <div className='flex items-center justify-between'>
                        <div>
                          <h3 className='product-text product-des'>
                            Product Details
                          </h3>
                          {/* <div className='flex'>
                          <a href='#' className='product-code'>
                            SKU JE04733-YGP600
                          </a>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='15'
                            viewBox='0 0 11 11'
                            fill='none'
                          >
                            <path
                              d='M5.22533 10.4268H3.48366C1.47158 10.4268 0.573242 9.52842 0.573242 7.51634V5.77467C0.573242 3.76259 1.47158 2.86426 3.48366 2.86426H4.85866C5.04658 2.86426 5.20241 3.02009 5.20241 3.20801C5.20241 3.39592 5.04658 3.55176 4.85866 3.55176H3.48366C1.84283 3.55176 1.26074 4.13384 1.26074 5.77467V7.51634C1.26074 9.15717 1.84283 9.73926 3.48366 9.73926H5.22533C6.86616 9.73926 7.44824 9.15717 7.44824 7.51634V6.14134C7.44824 5.95342 7.60408 5.79759 7.79199 5.79759C7.97991 5.79759 8.13574 5.95342 8.13574 6.14134V7.51634C8.13574 9.52842 7.23741 10.4268 5.22533 10.4268Z'
                              fill='#222AE7'
                            />
                            <path
                              d='M7.79173 6.48539H6.32507C5.03715 6.48539 4.51465 5.96289 4.51465 4.67497V3.20831C4.51465 3.07081 4.59715 2.94247 4.72548 2.89206C4.85382 2.83706 5.00048 2.86914 5.10132 2.96539L8.03465 5.89872C8.1309 5.99497 8.16298 6.14622 8.10798 6.27456C8.05757 6.40289 7.92923 6.48539 7.79173 6.48539ZM5.20215 4.03789V4.67497C5.20215 5.58706 5.41298 5.79789 6.32507 5.79789H6.96215L5.20215 4.03789Z'
                              fill='#222AE7'
                            />
                            <path
                              d='M7.14974 1.26074H5.31641C5.12849 1.26074 4.97266 1.10491 4.97266 0.916992C4.97266 0.729076 5.12849 0.573242 5.31641 0.573242H7.14974C7.33766 0.573242 7.49349 0.729076 7.49349 0.916992C7.49349 1.10491 7.33766 1.26074 7.14974 1.26074Z'
                              fill='#222AE7'
                            />
                            <path
                              d='M3.20801 2.63574C3.02009 2.63574 2.86426 2.47991 2.86426 2.29199C2.86426 1.34324 3.63426 0.573242 4.58301 0.573242H5.78384C5.97176 0.573242 6.12759 0.729076 6.12759 0.916992C6.12759 1.10491 5.97176 1.26074 5.78384 1.26074H4.58301C4.01467 1.26074 3.55176 1.72366 3.55176 2.29199C3.55176 2.47991 3.39592 2.63574 3.20801 2.63574Z'
                              fill='#222AE7'
                            />
                            <path
                              d='M8.7959 8.13574C8.60798 8.13574 8.45215 7.97991 8.45215 7.79199C8.45215 7.60408 8.60798 7.44824 8.7959 7.44824C9.3184 7.44824 9.74006 7.02199 9.74006 6.50408V3.66699C9.74006 3.47908 9.8959 3.32324 10.0838 3.32324C10.2717 3.32324 10.4276 3.47908 10.4276 3.66699V6.50408C10.4276 7.40241 9.69423 8.13574 8.7959 8.13574Z'
                              fill='#222AE7'
                            />
                            <path
                              d='M10.083 4.01007H8.70801C7.48884 4.01007 6.98926 3.51048 6.98926 2.29132V0.916316C6.98926 0.778816 7.07176 0.650482 7.20009 0.600066C7.32842 0.545066 7.47509 0.577149 7.57592 0.673399L10.3259 3.4234C10.4222 3.51965 10.4543 3.6709 10.3993 3.79923C10.3488 3.92757 10.2205 4.01007 10.083 4.01007ZM7.67676 1.7459V2.29132C7.67676 3.13007 7.86926 3.32257 8.70801 3.32257H9.25342L7.67676 1.7459Z'
                              fill='#222AE7'
                            />
                          </svg>
                        </div> */}
                        </div>
                        {(productDetails?.pricingStatus === true &&
                          productDetails?.availability?.toLowerCase() ===
                            'ready') ||
                        productDetails?.price > 0 ? (
                          <>
                            <button
                              className='btn-price-breakup product-text'
                              onClick={() => setPriceBreakUpModel(true)}
                              style={{
                                color: 'white',
                                backgroundColor: data.color.shade2,
                                fontSize : '11px'
                              }} // Add this line for inline styling
                            >
                            <FontAwesomeIcon
                            icon={faPlus}
                          /> PRICE BREAKUP
                            </button>
                          </>
                        ) : selectedVariant?.pricingStatus === true ||
                          selectedVariant?.availability?.toLowerCase() ===
                            'ready' ||
                          parseInt(selectedVariant?.price) > 0 ? (
                          <>
                            <button
                              className='btn-price-breakup product-text'
                              onClick={() => setPriceBreakUpModel(true)}
                              style={{
                                color: 'white',
                                backgroundColor: data.color.shade2,
                                fontSize : '13px'
                              }} // Add this line for inline styling
                            >
                            <FontAwesomeIcon
                            icon={faPlus}
                          /> PRICE BREAKUP
                            </button>
                          </>
                        ) : (
                          <>
                            <br />
                          </>
                        )}
                      </div>

                      {/* Product Info */}
                      <div className='product-text product-des-1'>
                        <p>
                          Set in {productDetails?.carat} KT{' '}
                          {productDetails?.metalColor}{' '}
                          {productDetails?.availability?.toLowerCase() ===
                            'ready' &&
                            productDetails?.priceBreakdown?.netWeight +
                              'g'}{' '}
                          {productDetails?.priceBreakdown?.type?.toLowerCase() ===
                          'studded'
                            ? "With"+ productDetails?.priceBreakdown?.gemstoneDetails[0]
                                ?.name
                            : ''}{' '}
                          {productDetails?.priceBreakdown?.type?.toLowerCase() ===
                            'studded' &&
                          productDetails?.priceBreakdown?.gemstoneDetails[0]
                            ?.name === 'Diamond'
                            ? '(' +
                              parseFloat(
                                productDetails?.priceBreakdown
                                  ?.gemstoneDetails[0]?.diamondWeigth
                              ).toFixed(2) +
                              'ct, ' +
                              productDetails?.priceBreakdown?.gemstoneDetails[0]?.dri
                                .split(',')
                                .reverse()[0] +
                              ')'
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className='product-des-2 product-details-background flex items-center justify-center' style={{ background: `${data.color.shade1}`}}>
                      {/* Left Section: Gold Details */}
                      <div className='mx-auto flex items-center gap-5 w-[220px]'>
                        <img
                          src='/assets/img/productDetails/layar.png' // Replace with your image path or import statement
                          alt='Earrings'
                          className='h-50 w-50 flex-shrink-0'
                        />
                        <div className='text-center'>
                          <div className='product-desc-3 product-text'>
                            {productDetails?.carat} KT{' '}
                            {productDetails?.metalColor}
                          </div>
                          <p className='product-text text-sm md:text-xs sm:text-xs'>
                            {productDetails?.availability?.toLowerCase() ===
                            'ready'
                              ? productDetails?.priceBreakdown?.netWeight +
                                ' gram'
                              : ''}
                          </p>
                          {/* Will add below field in future */}
                          {productDetails?.productWidth && (
                            <p className='product-text text-sm md:text-xs sm:text-xs'>
                              Width - {productDetails?.productWidth} mm
                            </p>
                          )}
                          {productDetails?.productHeight && (
                            <p className='product-text text-sm md:text-xs sm:text-xs'>
                              Height - {productDetails?.productHeight} mm
                            </p>
                          )}
                           {(productDetails?.priceBreakdown?.netWeight || productDetails?.allVariants?.length > 0) && 
                          <p className='product-text text-sm md:text-xs sm:text-xs'>
                          Weight - {(productDetails?.priceBreakdown?.netWeight ? productDetails?.priceBreakdown?.netWeight : (productDetails?.allVariants?.length>0 ? parseFloat(productDetails?.allVariants[0]?.netWeight).toFixed(2) : 0))+"g"}
                          </p>
                          }
                        </div>
                      </div>
                      {/* Divider */}
                      
                      <div className='divider'></div>

                      {/* Right Section: Diamond Details */}
                      <div className='mx-auto flex items-center gap-5'>
                        <img
                          src='/assets/img/productDetails/diamond.png' // Replace with your image path or import statement
                          alt='Diamond'
                          className='h-50 w-50'
                        />
                        <div className='text-center'>
                          <div className='product-desc-3 product-text'>
                            {productDetails?.priceBreakdown?.type?.toLowerCase() ===
                              'studded' &&
                            productDetails?.priceBreakdown?.gemstoneDetails[0]
                              ?.name === 'Diamond'
                              ? parseFloat(
                                  productDetails?.priceBreakdown
                                    ?.gemstoneDetails[0]?.diamondWeigth
                                ).toFixed(2) + 'ct'
                              : ''}
                          </div>
                          <p className='product-text text-sm md:text-xs sm:text-xs'>
                            {productDetails?.priceBreakdown?.type?.toLowerCase() ===
                              'studded' &&
                            productDetails?.priceBreakdown?.gemstoneDetails[0]
                              ?.name === 'Diamond'
                              ? productDetails?.priceBreakdown?.gemstoneDetails[0]?.dri
                                  .split(',')
                                  .reverse()[0]
                              : ''}
                          </p>
                          {/* Will add this field in future */}
                          {/* <p className='product-text text-sm md:text-xs sm:text-xs'>
                          Setting - Prong
                        </p> */}
                          {productDetails?.priceBreakdown?.type?.toLowerCase() ===
                          'studded' ? (
                            <p className='product-text text-sm md:text-xs sm:text-xs'>
                              Total Number -{' '}
                              {productDetails?.priceBreakdown?.type?.toLowerCase() ===
                              'studded'
                                ? productDetails?.priceBreakdown
                                    ?.gemstoneDetails[0]?.totalCount === 0
                                  ? 1
                                  : productDetails?.priceBreakdown
                                      ?.gemstoneDetails[0]?.totalCount
                                : ''}
                            </p>
                          ) : (
                            <p className='product-text text-sm md:text-xs sm:text-xs'>
                              <b>Non-Studded</b>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className='product-desc-4 my-5 flex justify-between md:flex-col md:px-0 md:py-0 sm:flex-col h-full'
                      style={{ border: `2px solid ${data.color.ctaPrimary}` }}
                    >
                      {/* Left Section with Options */}
                      <div className='flex h-full items-stretch'>
                      {/* Left Section with Options */}
                      <div className='flex-1 p-1 flex flex-col justify-center' style={{ borderRight: `2px solid ${data.color.ctaPrimary}` }}>
                        <div className='py-2 pl-2 md:pl-2 sm:pl-2'>
                          <div className='text-md product-text text-gray-500 md:text-[10px] sm:text-[10px] text-[11px] font-medium'>
                            Metal
                          </div>
                          <div className='product-text font-bold md:text-[12px] sm:text-[12px] text-[14px]'>
                            {selectedVariant
                              ? `${selectedVariant?.metalCarat}KT ${selectedVariant?.metalColor}`
                              : `${productDetails?.carat}KT ${productDetails?.metalColor}`}
                          </div>
                        </div>
                      </div>

                      {/* Option: Diamond */}
                      <div className='flex-1 p-1 flex flex-col justify-center' style={{ borderRight: `2px solid ${data.color.ctaPrimary}` }}>
                        <div className='py-2 pl-2 md:pl-2 sm:pl-2'>
                          {/* Diamond Type Handling */}
                          {selectedVariant ? (
                            selectedVariant?.type?.toLowerCase() === 'studded' ? (
                              <div className='product-text text-gray-500 md:text-[10px] sm:text-[10px] text-[11px] font-medium'>
                                {selectedVariant?.type?.toLowerCase() === 'studded' &&
                                JSON.parse(selectedVariant?.gemstoneDetails.replace(/'/g, '"'))[0]?.name === 'Diamond'
                                  ? 'Diamond'
                                  : 'Gemstone'}
                              </div>
                            ) : (
                              <div className='product-text text-gray-500 md:text-[10px] sm:text-[10px] text-[11px] font-medium'>
                                Type
                              </div>
                            )
                          ) : productDetails?.priceBreakdown?.type?.toLowerCase() === 'studded' ? (
                            <div className='text-[14px] product-text text-gray-500 md:text-[10px] sm:text-[10px] font-bold'>
                              {productDetails?.priceBreakdown?.type?.toLowerCase() === 'studded' &&
                              productDetails?.priceBreakdown?.gemstoneDetails[0]?.name === 'Diamond'
                                ? 'Diamond'
                                : 'Gemstone'}
                            </div>
                          ) : (
                            <div className='product-text text-gray-500 md:text-[10px] sm:text-[10px] text-[11px] font-medium'>
                              Type
                            </div>
                          )}

                          {/* Diamond Info */}
                          {selectedVariant ? (
                            selectedVariant?.type?.toLowerCase() === 'studded' ? (
                              <div className='product-text text-[14px] font-bold md:text-[12px] sm:text-[12px]'>
                                {selectedVariant?.type?.toLowerCase() === 'studded' &&
                                JSON.parse(selectedVariant?.gemstoneDetails.replace(/'/g, '"'))[0]?.name === 'Diamond'
                                  ? JSON.parse(selectedVariant?.gemstoneDetails.replace(/'/g, '"'))[0]?.dri.split(',').reverse()[0]
                                  : ''}
                                {selectedVariant?.type?.toLowerCase() === 'studded' &&
                                JSON.parse(selectedVariant?.gemstoneDetails.replace(/'/g, '"'))[0]?.name !== 'Diamond'
                                  ? JSON.parse(selectedVariant?.gemstoneDetails.replace(/'/g, '"'))[0]?.name
                                  : ''}
                              </div>
                            ) : (
                              <div className='product-text text-[14px] font-bold md:text-[12px] sm:text-[12px]'>
                                Non-Studded
                              </div>
                            )
                          ) : productDetails?.priceBreakdown?.type?.toLowerCase() === 'studded' ? (
                            <div className='product-text text-[14px] font-bold md:text-[12px] sm:text-[12px]'>
                              {productDetails?.priceBreakdown?.type?.toLowerCase() === 'studded' &&
                              productDetails?.priceBreakdown?.gemstoneDetails[0]?.name === 'Diamond'
                                ? productDetails?.priceBreakdown?.gemstoneDetails[0]?.dri.split(',').reverse()[0]
                                : ''}
                              {productDetails?.priceBreakdown?.type?.toLowerCase() === 'studded' &&
                              productDetails?.priceBreakdown?.gemstoneDetails[0]?.name !== 'Diamond'
                                ? productDetails?.priceBreakdown?.gemstoneDetails[0]?.name
                                : ''}
                            </div>
                          ) : (
                            <div className='product-text text-[14px] font-bold md:text-[12px] sm:text-[12px]'>
                              Non-Studded
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Option: Size */}
                      {product?.allVariants?.length > 0 && (
                        <div className='flex-1 p-1 flex flex-col justify-center'>
                          <div className='py-2 pl-2 md:pl-2 sm:pl-2'>
                            <div className='text-md product-text text-gray-500 md:text-[10px] sm:text-[10px] text-[11px] font-medium'>
                              Size
                            </div>
                            <div className='product-text text-[14px] font-bold md:text-[12px] sm:text-[12px]'>
                              {selectedVariant ? selectedVariant?.size : product?.allVariants[0]?.size}
                            </div>
                          </div>
                        </div>
                      )}
                      </div>


                      <button
                        className='product-customize text-white font-bold'
                        onClick={() => setCustomizeModel(true)}
                        style={{ backgroundColor: data.color.ctaPrimary }}
                      >
                        CUSTOMIZE DESIGN
                      </button>
                    </div>
                    <div>
                      <div className='mt-3 flex'>
                        {storeData?.isAccountVerified && (productDetails?.price > 0 ||
                        selectedVariant?.price > 0) ? (
                          <div className='w-full'>
                            <button
                              className='focus:shadow-outline add-to-cart sm:text:xl w-full rounded-[5px] px-4 py-2 text-2xl font-bold text-white hover:bg-sky-600 focus:outline-none md:text-xl'
                              onClick={callAddToCart}
                              style={{ backgroundColor: data.color.ctaPrimary }}
                            >
                              ADD TO CART
                            </button>
                          </div>
                        ) : (
                          <div className='w-full'>
                            <button
                              className='focus:shadow-outline add-to-cart sm:text:xl w-full rounded-[5px] px-4 py-2 text-2xl font-bold text-white hover:bg-sky-600 focus:outline-none md:text-xl'
                              style={{ backgroundColor: data.color.ctaPrimary }}
                              onClick={() =>
                                ShareToWhatsApp(
                                  selectedVariant &&
                                    product.id !== selectedVariant.productId
                                    ? selectedVariant
                                    : product,
                                  storeData,
                                  formatPriceWithCommas(
                                    selectedVariant
                                      ? parseInt(selectedVariant?.price)
                                      : parseInt(productDetails?.price)
                                  )
                                )
                              }
                            >
                              REQUEST PRICE
                            </button>
                          </div>
                        )}
                        <div
                          className='icons border-1 mx-3 flex h-[52px] w-[60px] items-center justify-center overflow-hidden rounded-[5px] border border-black bg-[#40FF75] text-2xl sm:h-[42px] sm:w-[52px]'
                          onClick={() =>
                            ShareToWhatsApp(
                              selectedVariant &&
                                product.id !== selectedVariant.productId
                                ? selectedVariant
                                : product,
                              storeData,
                              formatPriceWithCommas(
                                selectedVariant
                                  ? parseInt(selectedVariant?.price)
                                  : parseInt(productDetails?.price)
                              )
                            )
                          }
                        >
                          {' '}
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            className='text-black'
                          />
                        </div>
                        <div
                          className='icons flex h-[52px] w-[60px] cursor-pointer items-center justify-center overflow-hidden rounded-[5px] border-2 text-2xl sm:h-[42px] sm:w-[52px]'
                          style={{ borderColor: data.color.ctaPrimary }}
                          onClick={handleShare}
                        >
                          {' '}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='23'
                            height='23'
                            viewBox='0 0 23 23'
                            fill='none'
                          >
                            <g clip-path='url(#clip0_179_18401)'>
                              <path
                                d='M8.52106 9.87194L14.9467 6.981M14.9224 16.0613L7.91366 12.9208M21.5625 17.9688C21.5625 19.9535 19.9535 21.5625 17.9688 21.5625C15.984 21.5625 14.375 19.9535 14.375 17.9688C14.375 15.984 15.984 14.375 17.9688 14.375C19.9535 14.375 21.5625 15.984 21.5625 17.9688ZM21.5625 5.03125C21.5625 7.01602 19.9535 8.625 17.9688 8.625C15.984 8.625 14.375 7.01602 14.375 5.03125C14.375 3.04648 15.984 1.4375 17.9688 1.4375C19.9535 1.4375 21.5625 3.04648 21.5625 5.03125ZM8.625 10.7812C8.625 12.766 7.01602 14.375 5.03125 14.375C3.04648 14.375 1.4375 12.766 1.4375 10.7812C1.4375 8.79648 3.04648 7.1875 5.03125 7.1875C7.01602 7.1875 8.625 8.79648 8.625 10.7812Z'
                                stroke='#101C8C'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0_179_18401'>
                                <rect width='23' height='23' fill='white' />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div className='mt-4'>
                        <div className='product-text product-delivery sm:text-md md:text-md'>
                          Expected Delivery Date
                        </div>
                        <div
                          className='product-delivery-date mt-2 flex items-center rounded-[10px] p-3 bg-white'
                          style={{ border: '1px solid #00000033' }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                          >
                            <path
                              d='M6 1.5V3.75'
                              stroke='#292D32'
                              stroke-width='1.5'
                              stroke-miterlimit='10'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M12 1.5V3.75'
                              stroke='#292D32'
                              stroke-width='1.5'
                              stroke-miterlimit='10'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M12 2.625C14.4975 2.76 15.75 3.7125 15.75 7.2375V11.8725C15.75 14.9625 15 16.5075 11.25 16.5075H6.75C3 16.5075 2.25 14.9625 2.25 11.8725V7.2375C2.25 3.7125 3.5025 2.7675 6 2.625H12Z'
                              stroke='#292D32'
                              stroke-width='1.5'
                              stroke-miterlimit='10'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M15.5625 13.2002H2.4375'
                              stroke='#292D32'
                              stroke-width='1.5'
                              stroke-miterlimit='10'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M9 6.1875C8.0775 6.1875 7.2975 6.69 7.2975 7.665C7.2975 8.13 7.515 8.4825 7.845 8.7075C7.3875 8.9775 7.125 9.4125 7.125 9.9225C7.125 10.8525 7.8375 11.43 9 11.43C10.155 11.43 10.875 10.8525 10.875 9.9225C10.875 9.4125 10.6125 8.97 10.1475 8.7075C10.485 8.475 10.695 8.13 10.695 7.665C10.695 6.69 9.9225 6.1875 9 6.1875ZM9 8.3175C8.61 8.3175 8.325 8.085 8.325 7.7175C8.325 7.3425 8.61 7.125 9 7.125C9.39 7.125 9.675 7.3425 9.675 7.7175C9.675 8.085 9.39 8.3175 9 8.3175ZM9 10.5C8.505 10.5 8.145 10.2525 8.145 9.8025C8.145 9.3525 8.505 9.1125 9 9.1125C9.495 9.1125 9.855 9.36 9.855 9.8025C9.855 10.2525 9.495 10.5 9 10.5Z'
                              fill='#292D32'
                            />
                          </svg>
                          <p className='product-text product-delivery-date-desc ml-2'>
                            Free Delivery by{' '}
                            {productDetails?.makingDays &&
                            productDetails?.makingDays > 0
                              ? getFormattedDate(productDetails?.makingDays)
                              : getFormattedDate(2)}
                          </p>
                        </div>
                      </div>
                      <div className='bg-white rounded-[15px] my-3' style={{border: '1px solid #00000033'}}>
                      <div className='flex w-full max-w-4xl items-center justify-center rounded-xl p-3'>
                        <div className='flex w-1/4 flex-col items-center justify-center'>
                          <div className='text-bold flex h-[40px] w-[41px] items-center justify-center rounded-full bg-green-200 p-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='66'
                              height='69'
                              viewBox='0 0 66 69'
                              fill='none'
                            >
                              <path
                                d='M51.94 25.7776C51.94 29.8974 50.7696 33.6763 48.7552 36.8301C45.8154 41.3762 41.1606 44.5868 35.7437 45.4107C34.8182 45.5812 33.8655 45.6665 32.8856 45.6665C31.9056 45.6665 30.9529 45.5812 30.0274 45.4107C24.6105 44.5868 19.9557 41.3762 17.0159 36.8301C15.0015 33.6763 13.8311 29.8974 13.8311 25.7776C13.8311 14.7818 22.3511 5.88867 32.8856 5.88867C43.42 5.88867 51.94 14.7818 51.94 25.7776Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M58.0643 52.6843L53.5729 53.7923C52.5657 54.0481 51.7763 54.8437 51.5586 55.8949L50.6058 60.0716C50.0886 62.3446 47.3121 63.0265 45.8694 61.2365L32.8851 45.6664L19.9009 61.2649C18.4582 63.0549 15.6817 62.373 15.1645 60.1L14.2117 55.9233C13.9668 54.872 13.1774 54.0481 12.1974 53.8208L7.70599 52.7127C5.63722 52.2013 4.90226 49.502 6.3994 47.9393L17.0155 36.8584C19.9553 41.4044 24.6101 44.6151 30.027 45.4391C30.9525 45.6096 31.9052 45.6948 32.8851 45.6948C33.8651 45.6948 34.8178 45.6096 35.7433 45.4391C41.1602 44.6151 45.815 41.4044 48.7548 36.8584L59.3709 47.9393C60.868 49.4736 60.1331 52.1729 58.0643 52.6843Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M34.4642 17.1968L36.0702 20.5495C36.288 21.0041 36.8596 21.4587 37.3768 21.544L40.2894 22.0554C42.1404 22.3679 42.576 23.7886 41.2421 25.1808L38.9828 27.539C38.6017 27.9368 38.384 28.704 38.5201 29.2722L39.1734 32.1987C39.6906 34.5001 38.5201 35.4093 36.5602 34.1876L33.8381 32.5112C33.3481 32.1987 32.5315 32.1987 32.0415 32.5112L29.3195 34.1876C27.3596 35.3809 26.1891 34.5001 26.7063 32.1987L27.3596 29.2722C27.4685 28.7324 27.2779 27.9368 26.8968 27.539L24.6375 25.1808C23.3037 23.7886 23.7392 22.3963 25.5902 22.0554L28.5029 21.544C28.9928 21.4587 29.5645 21.0041 29.7822 20.5495L31.3882 17.1968C32.1776 15.3784 33.5931 15.3784 34.4642 17.1968Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                          </div>
                          <div className='product-text warranty mt-1'>
                            100% Certified
                          </div>
                        </div>
                        <div className='flex w-1/4 flex-col items-center justify-center'>
                          <div className='flex h-[40px] w-[41px] items-center justify-center rounded-full bg-cyan-200 p-2'>
                            <svg
                              width='50'
                              height='52'
                              viewBox='0 0 50 52'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M37.4777 29.6056C36.6202 30.4793 36.1303 31.7366 36.2528 33.0791C36.4365 35.3805 38.4576 37.0639 40.6625 37.0639H44.5415V39.5998C44.5415 44.0108 41.0912 47.6122 36.8652 47.6122H16.2455C17.94 46.0566 19.022 43.7765 19.022 41.2193C19.022 36.5099 15.3677 32.6955 10.8558 32.6955C8.93678 32.6955 7.16062 33.3987 5.75195 34.5707V25.2585C5.75195 20.8474 9.20217 17.2461 13.4282 17.2461H36.8652C41.0912 17.2461 44.5415 20.8474 44.5415 25.2585V28.3271H40.4175C39.2742 28.3271 38.233 28.7958 37.4777 29.6056Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M5.75195 25.2582V17.4376C5.75195 14.9018 7.24228 12.6429 9.5084 11.7479L25.7183 5.35503C28.2499 4.37479 30.9651 6.31402 30.9651 9.14819V17.2458'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M46.7038 30.5006V34.8905C46.7038 36.0625 45.8055 37.0214 44.6622 37.064H40.6608C38.4559 37.064 36.4348 35.3806 36.251 33.0791C36.1286 31.7366 36.6185 30.4794 37.476 29.6057C38.2313 28.7959 39.2725 28.3271 40.4158 28.3271H44.6622C45.8055 28.3698 46.7038 29.3286 46.7038 30.5006Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M14.9395 26.3018H29.2303'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M19.0229 41.2181C19.0229 43.7753 17.9408 46.0554 16.2463 47.611C14.7968 48.9322 12.9186 49.742 10.8566 49.742C6.34481 49.742 2.69043 45.9276 2.69043 41.2181C2.69043 38.5331 3.87453 36.1252 5.75276 34.5696C7.16143 33.3976 8.93758 32.6943 10.8566 32.6943C15.3685 32.6943 19.0229 36.5087 19.0229 41.2181Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M11.3674 38.5547V41.7511L8.81543 43.3493'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                          </div>
                          <div className='product-text warranty mt-1'>
                            Easy Returns
                          </div>
                        </div>
                        <div className='flex w-1/4 flex-col items-center justify-center'>
                          <div className='flex h-[40px] w-[41px] items-center justify-center rounded-full bg-yellow-200 p-2'>
                            <svg
                              width='75'
                              height='82'
                              viewBox='0 0 75 82'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M52.6939 21.3213C65.6729 29.1429 70.1249 46.4857 62.6315 60.033C55.1381 73.5803 37.4903 73.9729 37.4903 73.9729M37.4903 73.9729L43.6262 62.8798M37.4903 73.9729L49.2466 81.0576M25.5439 70.4058C12.5649 62.5843 8.05856 45.3396 15.6063 31.6941C24.6608 15.3244 42.2543 15.0301 42.2543 15.0301M42.2543 15.0301L30.498 7.94533M42.2543 15.0301L36.227 25.9268'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                          </div>
                          <h4 className='product-text warranty mt-1'>
                            Lifetime Exchange
                          </h4>
                        </div>
                        <div className='flex w-1/4 flex-col items-center justify-center'>
                          <div className='flex h-[40px] w-[41px] items-center justify-center rounded-full bg-purple-200 p-2'>
                            <svg
                              width='55'
                              height='58'
                              viewBox='0 0 55 58'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M18.5967 5.46582V12.569'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M36.7432 5.46582V12.569'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M8.38867 22.2529H46.9513'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M50.3542 45.717C50.3542 47.4928 49.8778 49.1739 49.0385 50.5945C47.4733 53.3411 44.5925 55.1879 41.2806 55.1879C38.9895 55.1879 36.9026 54.3118 35.3147 52.8202C34.6115 52.2046 33.9991 51.4469 33.5227 50.5945C32.6834 49.1739 32.207 47.4928 32.207 45.717C32.207 40.4843 36.2675 36.2461 41.2806 36.2461C44.0027 36.2461 46.4299 37.501 48.0858 39.4662C49.4922 41.1472 50.3542 43.3256 50.3542 45.717Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M37.7422 45.7169L39.9879 48.0609L44.8196 43.3965'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M48.0859 20.8562V39.4665C46.43 37.5013 44.0028 36.2464 41.2808 36.2464C36.2676 36.2464 32.2072 40.4846 32.2072 45.7173C32.2072 47.4931 32.6836 49.1742 33.5229 50.5948C33.9992 51.4472 34.6117 52.2049 35.3149 52.8205H18.5968C10.6575 52.8205 7.25488 48.085 7.25488 40.9819V20.8562C7.25488 13.753 10.6575 9.01758 18.5968 9.01758H36.744C44.6834 9.01758 48.0859 13.753 48.0859 20.8562Z'
                                stroke='#292D32'
                                stroke-width='1.5'
                                stroke-miterlimit='10'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M27.6598 33.1682H27.6802'
                                stroke='#292D32'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M19.2643 33.1682H19.2847'
                                stroke='#292D32'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M19.2643 40.2718H19.2847'
                                stroke='#292D32'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                          </div>
                          <h4 className='product-text warranty mt-1'>
                            One Year Warranty
                          </h4>
                        </div>
                      </div>
                      <hr className='mt-3'></hr>
                      <p className='product-text mt-1 text-center pb-1' style={{ color: '#4f4f4f', fontSize: '10px', fontWeight: '400', gap: '0px', opacity: '0px'}}>
                        Any Questions? Please feel free to reach us at{' '}
                        <span style={{ fontSize: '10px', fontWeight: '600', lineHeight: '12.1px'}}>{storeData?.storePhoneNumber}</span>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='mx-16 my-3 flex gap-8 px-8 md:mx-0 md:flex-col md:px-0 sm:mx-0 sm:flex-col'>
                <div className='flex w-full rounded-3xl bg-white shadow-md md:w-full sm:w-full'>
                  <div>
                    <img
                      src='/assets/img/productDetails/image_1.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full flex-shrink-0 rounded-l-3xl object-cover'
                    />
                  </div>
                  <div className='ml-3 p-2'>
                    <div className='store mb-4 lg:text-[18px] md:mb-1 md:text-[13px] sm:mb-1 sm:text-[13px]'>
                      Collect from Store
                    </div>
                    <p className='store-description mb-4 lg:text-[13px] md:mb-1 md:text-[10px] sm:mb-1 sm:text-[10px]'>
                      Place your order and select the nearest store from where
                      you can get your designs.
                    </p>
                    <button className='store-btn px-4 py-2'>
                      Find the Nearest Store
                    </button>
                  </div>
                </div>
                <div className='flex w-full rounded-3xl bg-white shadow-md md:-mt-5 md:w-full sm:-mt-5 sm:w-full'>
                  <div>
                    <img
                      src='/assets/img/productDetails/image_2.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full flex-shrink-0 rounded-l-3xl object-cover'
                    />
                  </div>
                  <div className='p-2 lg:ml-3'>
                    <h2 className='store mb-4 lg:text-[18px] md:mb-1 md:text-[13px] sm:mb-1 sm:text-[13px]'>
                      Live Video Call
                    </h2>
                    <p className='store-description mb-4 lg:text-[13px] md:mb-1 md:text-[10px] sm:mb-1 sm:text-[10px]'>
                      Join a live video call with our consultants to see your
                      favourite designs up close!
                    </p>
                    <button className='video-btn px-4 py-2'>
                      Schedule A Video Call
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          </div>

          <div className='mb-5'>
          <div className='relative mx-[5%]'>
            <Swiper
              ref={swiperRef}
              modules={[Navigation]}
              // spaceBetween={10}
              slidesPerView={2}
              onSwiper={swiper => {
                // Store swiper instance for later use
                swiperRef.current = swiper;
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
            >
              {marqueeBannerData?.img?.map(banner => {
                return (
                  <>
                    <SwiperSlide>
                      {banner?.ctaUrl ? (
                        <a
                          href={banner?.ctaUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <img
                            src={banner?.image} // Replace with your image path or import statement
                            alt='Earrings'
                            className='h-[240px] !w-[632px] flex-shrink-0 object-cover'
                          />
                        </a>
                      ) : (
                        <img
                          src={banner?.image} // Replace with your image path or import statement
                          alt='Earrings'
                          className='h-[240px] !w-[632px] flex-shrink-0 object-cover'
                        />
                      )}
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
              {/* Custom Navigation Buttons */}
              <button
                ref={prevRef}
                className='absolute -left-[4%] top-1/2 z-10 -translate-y-1/2 transform'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                >
                  <path
                    d='M20 36.6666C29.2048 36.6666 36.6667 29.2047 36.6667 19.9999C36.6667 10.7952 29.2048 3.33325 20 3.33325C10.7953 3.33325 3.33334 10.7952 3.33334 19.9999C3.33334 29.2047 10.7953 36.6666 20 36.6666Z'
                    stroke='#0A0A0A'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M22.1 25.8834L16.2333 20L22.1 14.1167'
                    stroke='#0A0A0A'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
              <button
                ref={nextRef}
                className='absolute -right-[4%] top-1/2 z-10 -translate-y-1/2 transform'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                >
                  <path
                    d='M20 3.33341C10.7952 3.33341 3.33329 10.7953 3.33329 20.0001C3.33329 29.2048 10.7952 36.6667 20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20.0001C36.6666 10.7953 29.2047 3.33341 20 3.33341Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M17.9 14.1166L23.7667 20L17.9 25.8833'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
          </div>
            <div className='mx-[5%] mt-3'>
              <div className='product-text mb-4 ml-4 text-[20px] font-bold md:text-[15px]'>
                Browse Products by Type
              </div>
              <div>
                <div className='mr-2 grid grid-cols-3 gap-2'>
                  {menuData?.data?.map(menu => {
                    return (
                      <>
                        <div className='w-full rounded-[30px] bg-gray-200 p-4'>
                          <div className='product-text mb-3 font-bold'>
                            {menu?.name}
                          </div>
                          <div className='relative'>
                            <Swiper
                              modules={[Pagination, EffectFade, Scrollbar]}
                              spaceBetween={10}
                              slidesPerView={3.2}
                              loop={false}
                              pagination={{
                                el: '.swiper-pagination',
                                dynamicBullets: false,
                                clickable: true,
                              }}
                              scrollbar={{
                                el: '.swiper-scrollbar',
                                hide: false, // Set to true if you want it to hide after use
                                draggable: true, // Allow dragging the scrollbar
                              }}
                              className='extra_swiper'
                            >
                              {menu?.sub?.map((image, index) => (
                                <SwiperSlide
                                  key={index}
                                  className='!h-[84px] !w-[70px] rounded-lg bg-white shadow-md'
                                >
                                  <Link to={`/products?categories=${image.id}`}>
                                    <img
                                      src={image.img}
                                      alt={`Image ${index + 1}`}
                                      className='h-[84px] w-[70px] flex-shrink-0 cursor-pointer object-cover'
                                    />
                                  </Link>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className='product-text ml-4 text-[20px] font-bold md:text-[15px]'>
              Recently Viewed by you
            </div>
            <div className='flex'>
              <div className='w-80'>
                <div className='product-card--wrapper m-auto cursor-pointer'>
                  <div className='relative aspect-square bg-[#dcdcdc]'>
                    <img
                      src='/assets/img/category/Rectangle-2.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full object-cover'
                    />
                    
                    <div className='floating-tags periodtag'>LATEST</div>
                    <div className='floating-tags karattag'>18K GOLD</div>
                   
                  </div>
                  <div className='product-detail-section px-3 py-2'>
                    <p className='product-name mb-0 text-[13px] font-semibold text-[#0A0C43] sm:mb-[10px]'>
                      Geometric Gleam Earrings
                    </p>
                    <div className='p-price-share--wrapper items-center gap-2'>
                      {true ? (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          ₹9,99,999
                        </p>
                      ) : (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          {'Made To Order'}
                        </p>
                      )}
                      <div className='share-icons flex justify-end gap-1'>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#2BFF66] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              className='text-black'
                            />
                          </a>
                        </div>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#E3E4FF] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faBagShopping}
                              className='text-blue-600'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mx-3 w-80'>
                <div className='product-card--wrapper m-auto cursor-pointer'>
                  <div className='relative aspect-square bg-[#dcdcdc]'>
                    <img
                      src='/assets/img/category/Rectangle-3.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full object-cover'
                    />
                    <div className='floating-tags periodtag'>LATEST</div>
                    <div className='floating-tags karattag'>18K GOLD</div>
                  </div>
                  <div className='product-detail-section px-3 py-2'>
                    <p className='product-name mb-0 text-[13px] font-semibold text-[#0A0C43] sm:mb-[10px]'>
                      Geometric Gleam Earrings
                    </p>
                    <div className='p-price-share--wrapper items-center gap-2'>
                      {true ? (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          ₹9,99,999
                        </p>
                      ) : (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          {'Made To Order'}
                        </p>
                      )}
                      <div className='share-icons flex justify-end gap-1'>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#2BFF66] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              className='text-black'
                            />
                          </a>
                        </div>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#E3E4FF] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faBagShopping}
                              className='text-blue-600'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mx-3 w-80'>
                <div className='product-card--wrapper m-auto cursor-pointer'>
                  <div className='relative aspect-square bg-[#dcdcdc]'>
                    <img
                      src='/assets/img/category/Rectangle-3.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full object-cover'
                    />                 
                    <div className='floating-tags periodtag'>LATEST</div>
                    <div className='floating-tags karattag'>18K GOLD</div>
                  
                  </div>
                  <div className='product-detail-section px-3 py-2'>
                    <p className='product-name mb-0 text-[13px] font-semibold text-[#0A0C43] sm:mb-[10px]'>
                      Geometric Gleam Earrings
                    </p>
                    <div className='p-price-share--wrapper items-center gap-2'>
                      {true ? (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          ₹9,99,999
                        </p>
                      ) : (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          {'Made To Order'}
                        </p>
                      )}
                      <div className='share-icons flex justify-end gap-1'>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#2BFF66] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              className='text-black'
                            />
                          </a>
                        </div>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#E3E4FF] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faBagShopping}
                              className='text-blue-600'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mx-3 w-80'>
                <div className='product-card--wrapper m-auto cursor-pointer'>
                  <div className='relative aspect-square bg-[#dcdcdc]'>
                    <img
                      src='/assets/img/category/Rectangle-3.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full object-cover'
                    />                   
                    <div className='floating-tags periodtag'>LATEST</div>
                    <div className='floating-tags karattag'>18K GOLD</div>
                  </div>
                  <div className='product-detail-section px-3 py-2'>
                    <p className='product-name mb-0 text-[13px] font-semibold text-[#0A0C43] sm:mb-[10px]'>
                      Geometric Gleam Earrings
                    </p>
                    <div className='p-price-share--wrapper items-center gap-2'>
                      {true ? (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          ₹9,99,999
                        </p>
                      ) : (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          {'Made To Order'}
                        </p>
                      )}
                      <div className='share-icons flex justify-end gap-1'>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#2BFF66] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              className='text-black'
                            />
                          </a>
                        </div>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#E3E4FF] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faBagShopping}
                              className='text-blue-600'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-80'>
                <div className='product-card--wrapper m-auto cursor-pointer'>
                  <div className='relative aspect-square bg-[#dcdcdc]'>
                    <img
                      src='/assets/img/category/Rectangle-5.png' // Replace with your image path or import statement
                      alt='Earrings'
                      className='h-full w-full object-cover'
                    />
                    <div className='floating-tags periodtag'>LATEST</div>
                    <div className='floating-tags karattag'>18K GOLD</div>
                  </div>
                  <div className='product-detail-section px-3 py-2'>
                    <p className='product-name mb-0 text-[13px] font-semibold text-[#0A0C43] sm:mb-[10px]'>
                      Geometric Gleam Earrings
                    </p>
                    <div className='p-price-share--wrapper items-center gap-2'>
                      {true ? (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          ₹9,99,999
                        </p>
                      ) : (
                        <p className='p-price font-sherif m-0 mb-[5px] text-[18px] font-bold sm:text-[20px]'>
                          {'Made To Order'}
                        </p>
                      )}
                      <div className='share-icons flex justify-end gap-1'>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#2BFF66] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              className='text-black'
                            />
                          </a>
                        </div>
                        <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#E3E4FF] sm:h-[32px] sm:w-[32px]'>
                          <a href='#'>
                            <FontAwesomeIcon
                              icon={faBagShopping}
                              className='text-blue-600'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        {/* Customize pop-up */}
        {customizeModel && (
          <div>
            <Customize
              customizeModelClose={customizeModelHandle}
              variantData={product}
              selectedVariantData={selectedVariant}
            ></Customize>
          </div>
        )}
        {/* price-breakUP model */}
        {priceBreakUpModel && (
          <div>
            <PriceBreakUp
              priceBreakUpModelClose={priceBreakUpModelHandle}
              productDetails={
                selectedVariant && product.id !== selectedVariant.productId
                  ? selectedVariant
                  : product
              }
              isSelectedVariant={
                selectedVariant && product.id !== selectedVariant.productId
                  ? true
                  : false
              }
            ></PriceBreakUp>
          </div>
        )}
        {/* guide size pop-up */}
        {ringSizeGuideModel && (
          <div>
            <SizeGuide
              ringSizeModelClose={ringSizeModelHandle}
              type={productDetails?.parentCategoryName}
              diamondGuideModel={diamondGuideModel}
            ></SizeGuide>
          </div>
        )}
      </>
    );
  }
};

export default SingleProductDetails;
