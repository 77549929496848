import { combineReducers } from "redux";
import loginReducer from "./loginSlice";
import clientReducer from "./clientSlice";
import cartReducer from "./cartSlice";

import graphReducer from "./graphSlice";
import menuReducer, { setMenuData } from "./menuSlice";
import storeReducer, { setStoreData } from "./storeSlice";
import categoryPricingReducer, { setPricing } from "./categoryPricingSlice";
import designBankProductsReducer, {setDesignBankProducts} from "./dbpSlice";
const reducers = combineReducers(
    {
        login: loginReducer,
        client: clientReducer,
        cart: cartReducer,
        graph: graphReducer,
        menu: menuReducer,
        storeData: storeReducer,
        categoryPricing: categoryPricingReducer,
        designBankProducts: designBankProductsReducer
        // allForms: formReducer,
        // users: Users

    }
)

export default reducers;

export {
    setMenuData, setStoreData, setPricing, setDesignBankProducts
};