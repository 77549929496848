import React, { useState } from 'react';
import useHistoryTracker from '../../../hooks/useHistoryTracker'; // Import the custom hook
import { useSendAndValidateOtp } from '../api';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
const LoginFormMobile = () => {
    const navigate = useNavigate(); // Create navigate function
    const goBack = useHistoryTracker(); // Get the goBack function
    const { sendOtp, error, success } = useSendAndValidateOtp();
    const storeData = useSelector((state) => state.storeData);
    const [formState, setFormState] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const phone = (process.env.REACT_APP_PH_DEFAULT + formState.phone)?.trim();
        if (phone) {
            sendOtp(phone);
        }
    };

    if (success) {
        navigate(`/verify?mobile=${formState.phone}`); // Redirect to /otp page
    }

    return (
        <>
            <div className="overlay bg-[#0B0B0BA3] h-[100vh] w-[100vw] absolute top-0 bottom-0 right-0 left-0 z-2" />
            <div className="bg-white rounded-t-[30px] py-[35px] px-[30px] w-full font-['Inter'] z-50 fixed bottom-0">
                <div
                    onClick={goBack} // Use the goBack function
                    className="flex justify-center items-center h-[54px] w-[54px] bg-[#D9D9D940] rounded-full absolute top-[-60px] right-[14px]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22" fill="none">
                        <path d="M17.875 17.875L11 11M11 11L4.125 4.125M11 11L17.875 4.125M11 11L4.125 17.875" stroke="white" strokeWidth={2} strokeLinecap="round" />
                    </svg>
                </div>
                <p className="text-[20px] font-bold mb-[18px]">Login to your Account</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="phone"
                        placeholder="Enter Mobile Number"
                        value={formState.phone}
                        onChange={handleChange}
                        className="h-[60px] mb-[18px] w-full rounded-xl bg-[#F2F2F2] px-4 text-gray-500 outline-none focus:bg-white focus:ring-2 focus:ring-gray-100 text-[14px] text-[#505050] font-bold"
                    />
                    <div className="flex gap-2 mb-[18px]">
                        <input type="checkbox" className="border-gray-300 rounded h-[20px] w-[20px]" />
                        <p className="text-[13px] font-regular">Remember Me</p>
                    </div>
                    <button type="submit" className="w-full text-black text-[18px] font-bold py-[14px] bg-[#D9D9D9] rounded-[25px] flex justify-center mb-[34px]">
                        Get OTP
                    </button>
                    <p className="text-center text-[13px] font-semibold text-[#525252]">
                        By proceeding, I agree to{" "}
                        <span>
                            <a href="##" className="text-black underline">Terms & Conditions</a>
                        </span>
                    </p>
                    <p className="text-center text-[13px] font-semibold text-[#525252]">
                        New to {storeData?.name?.replace(","," ")}?
                        <span>
                            <Link to="/register" > Create an account</Link>
                        </span>
                    </p>
                    {error && <p className="text-red-500 text-[14px]">{error.message}</p>}
                    {success && <p className="text-green-500 text-[14px]">{success.message}</p>}
                </form>
            </div>
        </>
    );
};

export default LoginFormMobile;
