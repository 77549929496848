// Style
import '../../App.css';

// Components
import {  
  SectionVideo,
  PromiseSection,  
  Category,
  BannerSwiper,
  FindStore
} from 'components/index';

// Data
import { category } from 'data/';
import { useMarqueeBannerApi, useBannerApi } from './api';

import CollectionsLists from './CollectionsLists';
import { useFAQsApi } from "api";
import HelpSectionPage from './helpSection';

function HomePage() {

  // APIS
  const { data: marqueeBannerData } = useMarqueeBannerApi({ onLoad: true });
  const { data: faqData } = useFAQsApi({ onLoad: true });
  const { data: banners } = useBannerApi({ onLoad: true });
  return (
    <div>
      {marqueeBannerData?.video?.video ? (marqueeBannerData?.video?.ctaUrl ? 
        <a href={marqueeBannerData?.video?.ctaUrl} target="_blank" rel="noopener noreferrer">
          <SectionVideo
            data={marqueeBannerData?.video?.video}
            url={marqueeBannerData?.video?.video}
            poster={marqueeBannerData?.video?.video}
          />
        </a>
        :
        <SectionVideo
        data={marqueeBannerData?.video?.video}
        url={marqueeBannerData?.video?.video}
        poster={marqueeBannerData?.video?.video}
      />
      )
      : <BannerSwiper images={marqueeBannerData?.img} />}
      <div className='flex items-center justify-center pl-[40px] sm:pl-[10px]'>
        <Category data={category} />
      </div>
      {marqueeBannerData?.video && <BannerSwiper images={marqueeBannerData?.img} />}
      {/* <MetalType metalData={metalData} /> */}
      <CollectionsLists data={banners} />
      <PromiseSection data={faqData}  />      
      <FindStore banners={banners}/>
      <HelpSectionPage></HelpSectionPage>
    </div>
  );
}

export default HomePage;
