const category = [
  {
    img: '/assets/img/category/Rectangle-1.png',
    name: 'Earrings',
  },
  {
    img: '/assets/img/category/Rectangle-2.png',
    name: 'Rings',
  },
  {
    img: '/assets/img/category/Rectangle-3.png',
    name: 'Bracelets & Bangles',
  },
  {
    img: '/assets/img/category/Rectangle-4.png',
    name: 'Mangalsutras',
  },
  {
    img: '/assets/img/category/Rectangle-5.png',
    name: 'Necklaces',
  },
  {
    img: '/assets/img/category/Rectangle-6.png',
    name: 'Gold Coins',
  },
  {
    img: '/assets/img/category/Rectangle-7.png',
    name: 'Kada',
  },
  {
    img: '/assets/img/category/Rectangle-8.png',
    name: 'Studs',
  },
];
export default category;
