import { useMutation } from "@apollo/client";
import { CREATE_CHECKOUT,ADD_CHECKOUT_LINE,REMOVE_CHECKOUT_LINE, UPDATE_ADDRESS,CREATE_ADDRESS,UPDATE_CHECKOUT_SHIPPING_ADDRESS,UPDATE_CHECKOUT_BILLING_ADDRESS } from "../../../../src/graphql/mutations";

export const useCreateCheckout = () => {
  const [createCheckout] = useMutation(CREATE_CHECKOUT);

  const createCheckoutFunction = async (cart, address) => {
    try {
      const { data } = await createCheckout({
        variables: {
          checkoutInput: {
            phoneNumber: JSON.parse(
              localStorage.getItem(`vjw-${window.location.hostname}user-det`)
            )?.phoneNumber,           
            lines: cart?.map((item) => {
              return { quantity: item?.quantity, variantId: item?.id, isDbp: item?.isDbp};
            }),
            // shippingAddress: {
            //   city: address?.city,
            //   companyName: address?.companyName,
            //   country:'IN',
            //   countryArea: address?.countryArea,
            //   firstName: address?.firstName,
            //   lastName: address?.lastName,
            //   phone: address?.phoneNumber,
            //   postalCode: address?.postalCode,
            //   streetAddress1: address?.streetAddress1,
            //   streetAddress2: address?.streetAddress2,
            // },
          },
        },
      });
      console.log("data", data);
      return data;
    } catch (error) {
      console.error("Error creating checkout:", error);
      throw error;
    }
  };

  return createCheckoutFunction;
};

export const useAddCheckoutLine = () => {
  const [addCheckoutLine] = useMutation(ADD_CHECKOUT_LINE);

  const addCheckoutLineFunction = async (cID,cart) => {
    try {
      console.log("inside aaaaa49 :: ",cID, cart)
      const { data } = await addCheckoutLine({
        variables: {
            checkoutId: cID,
            lines: cart?.map((item) => {
              return { quantity: item?.quantity, variantId: item?.id, isDbp:item?.isDbp };
            })
          },       
      });
      console.log("Add checkout Line data", data);
      return data;
    } catch (error) {
      console.error("Error adding new line in the checkout:", error);
      throw error;
    }
  };

  return addCheckoutLineFunction;
};

export const useRemoveCheckoutLine = () => {
  const [removeCheckoutLine] = useMutation(REMOVE_CHECKOUT_LINE);

  const removeCheckoutLineFunction = async (checkoutId,lineId) => {
    try {
      console.log("inside remove Line :: ",checkoutId,lineId)
      const { data } = await removeCheckoutLine({
        variables: {
              checkoutId,
              lineId
          },       
      });

      if(data?.checkoutLineDelete?.checkoutErrors?.length===0){
        let userD = JSON.parse(
          localStorage.getItem(`vjw-${window.location.hostname}user-det`)
        );
        // Find the index of the line with the given lineId
        let lineIndex = userD?.checkout?.lines?.findIndex((line) => line.id === lineId);
  
        // Check if the line is found (index is not -1)
        if (lineIndex !== -1) {
          // Remove the line at the found index
          userD.checkout.lines.splice(lineIndex, 1);
        }
        localStorage.setItem(
          `vjw-${window.location.hostname}user-det`,
          JSON.stringify(userD)
      );       
      }
      return data;
    } catch (error) {
      console.error("Error adding new line in the checkout:", error);
      throw error;
    }
  };

  return removeCheckoutLineFunction;
};

export const useAddressUpdate = () =>{
  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  const updateAddressFunction = async (addressId,address) => {
    try {
      const { data } = await updateAddress({
        variables: {
              id:addressId,
              input : {
                firstName:address?.firstName,
                lastName:address?.lastName,
                streetAddress1:address?.streetAddress1,
                streetAddress2:address?.streetAddress2,
                city:address?.city,
                countryArea:address?.countryArea,
                cityArea:address?.cityArea,
                postalCode:address?.postalCode,
                country:"IN",
                phone:address?.phone,
              },
          },       
      });
      return data;
    } catch (error) {
      console.error("Error adding new line in the checkout:", error);
      throw error;
    }
  };

  return updateAddressFunction;
}


export const useAddNewAddress = () =>{
  const [addNewAddress] = useMutation(CREATE_ADDRESS);

  const addNewAddressFunction = async (addressId,address) => {
    try {
      const { data } = await addNewAddress({
        variables: {
              id:addressId,
              input : {
                firstName:address?.firstName,
                lastName:address?.lastName,
                streetAddress1:address?.streetAddress1,
                streetAddress2:address?.streetAddress2,
                city:address?.city,
                countryArea:address?.countryArea,
                cityArea:address?.cityArea,
                postalCode:address?.postalCode,
                country:"IN",
                phone:address?.phone,
              },
          },       
      });
      return data;
    } catch (error) {
      console.error("Error adding new line in the checkout:", error);
      throw error;
    }
  };

  return addNewAddressFunction;
}

export const useUpdateCheckoutBillingAddress = () =>{
  const [updateCheckoutAddress] = useMutation(UPDATE_CHECKOUT_BILLING_ADDRESS);

  const updateCheckoutAddressFunction = async (checkoutId,address) => {
    try {
      const { data } = await updateCheckoutAddress({
        variables: {
            checkoutId,
            billingAddress : {
              firstName:address?.firstName,
              lastName:address?.lastName,
              streetAddress1:address?.streetAddress1,
              streetAddress2:address?.streetAddress2,
              city:address?.city,
              countryArea:address?.countryArea,
              cityArea:address?.cityArea,
              postalCode:address?.postalCode,
              country:"IN",
              phone:address?.phone,
            },
          },       
      });
      return data;
    } catch (error) {
      console.error("Error 198:", error);
      throw error;
    }
  };

  return updateCheckoutAddressFunction;
}

export const useUpdateCheckoutShippingAddress = () =>{
  const [updateCheckoutAddress] = useMutation(UPDATE_CHECKOUT_SHIPPING_ADDRESS);

  const updateCheckoutAddressFunction = async (checkoutId,address) => {
    try {
      const { data } = await updateCheckoutAddress({
        variables: {
            checkoutId,
            shippingAddress : {
              firstName:address?.firstName,
              lastName:address?.lastName,
              streetAddress1:address?.streetAddress1,
              streetAddress2:address?.streetAddress2,
              city:address?.city,
              countryArea:address?.countryArea,
              cityArea:address?.cityArea,
              postalCode:address?.postalCode,
              country:"IN",
              phone:address?.phone,
            },
          },       
      });
      return data;
    } catch (error) {
      console.error("Error 230:", error);
      throw error;
    }
  };

  return updateCheckoutAddressFunction;
}


