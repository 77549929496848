
import { useLazyQuery } from "hooks";
import { GET_FAQ } from "graphql/query";


export const useFAQs = (onLoad) => {
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_FAQ, { onLoad });

    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};
