import React from "react";

const Poster = ({ img, url }) => {

  return (
    <div className="w-full mt-1 mb-1">
      {url ? 
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img
          className="w-full sm:h-[190px] h-[520px]"
          src={img}
          alt="Hero Section Poster"
          // style={{height:"190px"}}
        />  
      </a>:  
      <img
        className="w-full sm:h-[190px] h-[520px]"
        src={img}
        alt="Hero Section Poster"
        // style={{height:"190px"}}
      />
    }
    </div>
  );
};

export default Poster;
