import * as React from 'react';
const MasterCard = props => (
  <svg
    width={47}
    height={50}
    viewBox='0 0 28 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x={0.2}
      y={0.2}
      width={26.5423}
      height={17.7522}
      rx={5.5}
      fill='white'
      stroke='#D9D9D9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5422 13.405C13.6045 14.2084 12.3882 14.6934 11.0591 14.6934C8.09346 14.6934 5.68933 12.2786 5.68933 9.29984C5.68933 6.32104 8.09346 3.90625 11.0591 3.90625C12.3882 3.90625 13.6045 4.39127 14.5422 5.1947C15.4799 4.39127 16.6962 3.90625 18.0253 3.90625C20.9909 3.90625 23.3951 6.32104 23.3951 9.29984C23.3951 12.2786 20.9909 14.6934 18.0253 14.6934C16.6962 14.6934 15.4799 14.2084 14.5422 13.405Z'
      fill='#ED0006'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5422 13.405C15.6968 12.4157 16.4289 10.9436 16.4289 9.29984C16.4289 7.65604 15.6968 6.18399 14.5422 5.19471C15.4799 4.39127 16.6962 3.90625 18.0253 3.90625C20.991 3.90625 23.3951 6.32104 23.3951 9.29984C23.3951 12.2786 20.991 14.6934 18.0253 14.6934C16.6962 14.6934 15.4799 14.2084 14.5422 13.405Z'
      fill='#F9A000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5422 5.19531C15.6967 6.1846 16.4288 7.65664 16.4288 9.30042C16.4288 10.9442 15.6967 12.4163 14.5422 13.4055C13.3876 12.4163 12.6555 10.9442 12.6555 9.30042C12.6555 7.65664 13.3876 6.1846 14.5422 5.19531Z'
      fill='#FF5E00'
    />
  </svg>
);
export default MasterCard;
