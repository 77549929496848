import useDeviceDetect from 'hooks/useDeviceDetect';
import ProfileEditMobile from 'components/profile/ProfileEditMobile';
import ProfileEditDesktop from 'components/profile/ProfileEditDesktop';
const ProfileEditPage = () => {
  const { isMobile } = useDeviceDetect(); 

  return (
    <>
    {isMobile && <ProfileEditMobile/>}
     {!isMobile && <ProfileEditDesktop/>}
    </>
  );
};
export default ProfileEditPage;