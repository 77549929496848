import React, { useState, useEffect } from 'react';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { CachePersistor } from 'apollo3-cache-persist';
import MasterRoutes from "./routes";
import { Provider } from "react-redux";
import store from "hooks/redux/store";
import INITmain from "components/master/INITmain";
import 'react-multi-carousel/lib/styles.css'
// Define error handling link
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

// Define authentication link
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(`vjw-${window.location.hostname}-token`);
  const ref_token = localStorage.getItem(`vjw-${window.location.hostname}user`);

  let authHeader = '';
  if (_.operationName === "storeDetails" || _.operationName === "getDesignBankProducts" || _.operationName === "B2BProductDetails" || _.operationName === "getB2BDBCategories" || _.operationName === "dbpProducts" || _.operationName === "themeColors") {
    authHeader = localStorage.getItem(`vjw-${window.location.hostname}ad-token`);
  } else if (token) {
    authHeader = token;
  } else if (ref_token) {
    authHeader = JSON.parse(ref_token)?.token;
  }

  return {
    headers: {
      ...headers,
      Authorization: authHeader ? `JWT ${authHeader}` : "",
    },
  };
});

// Split link for different operations
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      (definition.name?.value === "storeDetails" ||
        definition.name?.value === "dbpProducts" ||
        definition.name?.value === "createToken" ||
        definition.name?.value === "publishedBanners" ||
        definition.name?.value === "getDesignBankProducts" ||
        definition.name?.value === "B2BProductDetails" ||
        definition.name?.value === "getB2BDBCategories" || 
      definition.name?.value === "themeColors")
    );
  },
  new HttpLink({
    uri: "https://goldclub.co/graphql/",
  }),
  new HttpLink({
    uri:
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "https://mangaldasjewellers.goldclub.co/graphql/"
        : window.location.origin + "/graphql/",
  })
);

// Initialize cache and client
const cache = new InMemoryCache();
const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
});

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  key: 'my-cache',
  trigger: 'write',
});

// Function to handle cache persistence
const initializeCache = async () => {
  await persistor.restore();
};

function App() {
  const [isCacheRestored, setIsCacheRestored] = useState(false);

  useEffect(() => {
    // if (isDevelopment) {
    //   const restoreCache = async () => {
    //     await initializeCache();
    //     setIsCacheRestored(true);
    //   };

    //   restoreCache();
    // }
  }, []);

  // if (!isCacheRestored && isDevelopment) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
      <INITmain>
        <MasterRoutes />
        </INITmain>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
