import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const storeSlice = createSlice({
  name: 'storeData',
  initialState,
  reducers: {
    setStoreData(state, action) {
      // Check if the action type matches the expected type for setting store data
      if (action?.type === "storeData/setStoreData") {
        const storeData = action.payload;

        // Update the document title
        document.title = storeData?.name || "Jewellery Application";

        // Dynamically set the favicon (store logo)
        const favicon = document.querySelector("link[rel*='icon']") || document.createElement("link");
        favicon.type = "image/x-icon";
        favicon.rel = "icon";  // Ensure that 'rel' is set correctly
        favicon.href = storeData?.storeLogo || "/default-favicon.ico";  // Use default favicon if storeLogo is not provided
        document.head.appendChild(favicon);

        // Set dynamic manifest values (using store data)
        const name = storeData?.name || "Jewellery Application";
        const short_name = storeData?.shortName || "SJ";
        const description = storeData?.description || "This is description";
        const icon = storeData?.storeLogo || "/default-icon.png"; // Use store logo or fallback to default

        const myDynamicManifest = {
          name,
          short_name,
          description,
          start_url: window.location.origin,
          display: "standalone",
          orientation: "portrait",
          background_color: "white",
          id: "/",
          launch_handler: {
            client_mode: ["focus-existing", "auto"]
          },
          scope: "/",
          icons: [
            { src: icon, sizes: "36x36", type: "image/png" },
            { src: icon, sizes: "48x48", type: "image/png" },
            { src: icon, sizes: "72x72", type: "image/png" },
            { src: icon, sizes: "96x96", type: "image/png" },
            { src: icon, sizes: "144x144", type: "image/png" },
            { src: icon, sizes: "192x192", type: "image/png" },
            { src: icon, sizes: "512x512", type: "image/png" },
          ]
        };

        // Create and inject the manifest link dynamically
        const manifestLink = document.createElement("link");
        manifestLink.rel = "manifest";
        const stringManifest = JSON.stringify(myDynamicManifest);
        manifestLink.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest));
        document.head.appendChild(manifestLink);
      }

      // Return the updated state after setting store data
      return { ...state, ...action.payload };
    },
  },
});

export const { setStoreData } = storeSlice.actions;
export default storeSlice.reducer;
