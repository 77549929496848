import * as React from 'react';
const GooglaPay = props => (
  <svg
    width={47}
    height={50}
    viewBox='0 0 28 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x={0.2}
      y={0.2}
      width={26.5423}
      height={17.7522}
      rx={5.5}
      fill='white'
      stroke='#D9D9D9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.1448 12.3133V9.95292H14.3718C14.8746 9.95292 15.2989 9.78565 15.6449 9.45575L15.7279 9.37211C16.3598 8.68908 16.3183 7.62505 15.6449 6.99313C15.3082 6.65859 14.8469 6.47738 14.3718 6.48667H12.4022V12.3133H13.1448ZM13.1449 9.23736V7.20221H14.3905C14.6581 7.20221 14.9118 7.30443 15.101 7.49029C15.5023 7.88059 15.5116 8.5311 15.1241 8.93534C14.9349 9.13513 14.6673 9.24665 14.3905 9.23736H13.1449ZM19.192 8.63798C18.8737 8.34525 18.4401 8.19657 17.8912 8.19657C17.1855 8.19657 16.655 8.45677 16.3045 8.97252L16.9595 9.38606C17.1993 9.03293 17.5268 8.85636 17.942 8.85636C18.2049 8.85636 18.4586 8.95394 18.6569 9.1305C18.8506 9.29777 18.9613 9.53939 18.9613 9.79494V9.96686C18.6754 9.80888 18.3156 9.72525 17.8728 9.72525C17.3562 9.72525 16.941 9.84606 16.632 10.0923C16.3229 10.3386 16.1661 10.6638 16.1661 11.0774C16.1569 11.4537 16.3183 11.8115 16.6043 12.0531C16.8949 12.3133 17.2639 12.4434 17.6975 12.4434C18.2095 12.4434 18.6154 12.2157 18.9244 11.7604H18.9567V12.3133H19.6671V9.85535C19.6671 9.33959 19.5102 8.93071 19.192 8.63798ZM17.1764 11.5792C17.0242 11.4677 16.9319 11.2865 16.9319 11.0913C16.9319 10.8729 17.0334 10.6917 17.2318 10.5477C17.4347 10.4036 17.6885 10.3293 17.9883 10.3293C18.4035 10.3246 18.7265 10.4176 18.9571 10.6034C18.9571 10.9194 18.8326 11.1935 18.5881 11.4258C18.3666 11.6489 18.0668 11.7743 17.7531 11.7743C17.5455 11.779 17.3425 11.7093 17.1764 11.5792ZM21.263 14.065L23.7446 8.32667H22.9374L21.7889 11.1889H21.775L20.5988 8.32667H19.7916L21.4199 12.0578L20.4973 14.065H21.263Z'
      fill='#3C4043'
    />
    <path
      d='M10.581 9.44205C10.581 9.21438 10.5625 8.9867 10.5256 8.76367H7.39368V10.0507H9.18797C9.11417 10.4643 8.87432 10.836 8.52376 11.0683V11.9047H9.59388C10.2212 11.3239 10.581 10.4643 10.581 9.44205Z'
      fill='#4285F4'
    />
    <path
      d='M7.39387 12.712C8.28872 12.712 9.04518 12.4147 9.59408 11.9036L8.52396 11.0672C8.22414 11.2716 7.8413 11.3878 7.39387 11.3878C6.52671 11.3878 5.79331 10.7977 5.53039 10.0078H4.42798V10.8721C4.99071 12.0011 6.13925 12.712 7.39387 12.712Z'
      fill='#34A853'
    />
    <path
      d='M5.5305 10.0092C5.3921 9.59567 5.3921 9.14496 5.5305 8.72678V7.86719H4.42791C3.95274 8.81042 3.95274 9.92556 4.42791 10.8688L5.5305 10.0092Z'
      fill='#FBBC04'
    />
    <path
      d='M7.39387 7.34592C7.86897 7.33663 8.32562 7.51784 8.66695 7.84774L9.61714 6.89057C9.01289 6.32371 8.21953 6.01239 7.39387 6.02169C6.13925 6.02169 4.99071 6.73724 4.42798 7.86633L5.53039 8.73057C5.79331 7.93602 6.52671 7.34592 7.39387 7.34592Z'
      fill='#EA4335'
    />
  </svg>
);
export default GooglaPay;
