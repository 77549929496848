import { useLazyQuery } from "hooks";
import { DBP_PRODUCTS_WITH_FILTER, GET_ALL_CATEGORIES, GET_ALL_PRODUCTS_FILTERED, GET_DBP_INVENTORY } from "graphql/query";
import { useEffect } from "react";

export const useGetStoreProducts = ({ onLoad, searchParams, }) => {
    const { data, loading, error, executeQuery, refetch } = useLazyQuery(
        GET_ALL_PRODUCTS_FILTERED,
        {
            variables: {
                filter: {
                    search: searchParams?.get("search") || null, // mean 3~4< char. length
                    categories: searchParams?.get("categories") || null, // Main + Local Store
                    collections: searchParams?.get("collections") || null, // Local store
                    productType: searchParams?.get("productType") || null,
                    attributes: [
                        ...(searchParams?.get("gender")
                            ? [
                                {
                                    slug: "gender",
                                    values: [searchParams?.get("gender")],
                                },
                            ]
                            : []),
                        ...(searchParams?.get("metal") ? [
                            {
                                slug: "metal-type",
                                values: [searchParams.get("metal")],
                            },
                        ] : []),
                        {
                            slug: "parent-product-id",
                            values: ["0"],
                        },
                    ],
                },
                sort: { direction: "DESC", field: "PUBLICATION_DATE" },
                fetchPolicy: "no-cache",
            },
            onLoad
        }

    );

    useEffect(() => {
        if (!data && searchParams) {           
            executeQuery();
        }
    }, [data, executeQuery, searchParams])
    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};

export const useAllB2BProducts = ({ ids, searchParams }) => {
    const { data, loading, error, executeQuery, refetch } = useLazyQuery(DBP_PRODUCTS_WITH_FILTER, {
        variables: {
            filter: {
                ids: ids || null,
                search: searchParams?.get("search") || null, // search string, length 3~4 chars or more
                categories: searchParams?.get("categories") || null, // Main + Local Store categories
                collections: searchParams?.get("collections") || null, // Local store collections
                productType: searchParams?.get("productType") || null,
                attributes: [
                    ...(searchParams?.get("gender") ? [
                        {
                            slug: "gender",
                            values: [searchParams.get("gender")],
                        },
                    ] : []),
                    ...(searchParams?.get("metal") ? [
                        {
                            slug: "metal-type",
                            values: [searchParams.get("metal")],
                        },
                    ] : []),
                    {
                        slug: "parent-product-id",
                        values: ["0"], // Only parent products
                    },
                ],
            },
            sort: { direction: "DESC", field: "PUBLICATION_DATE" },
        },
        fetchPolicy: "no-cache", // Ensuring no cache is used
    });

    return {
        executeQueryB2B: executeQuery,
        dataB2B: data,
        loadingB2B: loading,
        errorB2B: error,
        refetchB2B: refetch,
    };
};


export const useGetDBPInventory = ({ onLoad, searchParams }) => {
    const { data, loading, error, executeQuery, refetch } = useLazyQuery(GET_DBP_INVENTORY, { onLoad });
    const { executeQueryB2B, dataB2B } = useAllB2BProducts({ ids: data?.getDesignBankProductsId, searchParams });

    useEffect(() => {
        if (data?.getDesignBankProductsId?.length) {
            executeQueryB2B(); // Calling executeQueryB2B without arguments as it uses variables internally
        }
    }, [data?.getDesignBankProductsId, searchParams, executeQueryB2B]); // Including necessary dependencies

    return {
        executeQuery,
        data: dataB2B, // Returning B2B data
        loading,
        error,
        refetch,
    };
};


export const useGetAllCategories = ({ onLoad, productLimit }) => {
    const { data, loading, error, executeQuery, refetch } = useLazyQuery(GET_ALL_CATEGORIES, { variables: { productsFirst: productLimit }, onLoad });

    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
}
