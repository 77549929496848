import { Link } from 'react-router-dom';
const MetalType = ({ metalData }) => {
  return (
    <>
    {/* <section
      className='bg-cover py-12'
      style={{
        backgroundImage: `url('/assets/img/metaltype/metal_sec_bg.png')`,
      }}
    >
      <div className='container'>
        <div className='grid grid-cols-2 items-center gap-4 md:grid-cols-1 md:gap-10'>
          <div className='md:text-center'>
            <h4 className='section-subtitle relative text-[30px] font-extrabold text-[#1E1E1E]'>
              JEWELLERY BY
            </h4>
            <h1 className='section-maintitle font-playfair text-[90px] leading-[100px] text-[#1E1E1E]'>
              Metal Type
            </h1>
          </div>
          <div>
            <div className='grid grid-cols-2 grid-rows-2 gap-4'>
              {metalData?.map((item, index) => (
                <>
                  <div key={index} className='metal-type-category-image'>
                    <Link
                      to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_METAL_PRODUCT_QUERY_ROUTE}=${item?.slug}`}
                      className='border-b border-black text-[20px] font-semibold md:text-[20px] sm:text-[15px]'
                    >
                      <img
                        src={item.image}
                        alt={item.description}
                        className='mb-2 w-full'
                      />
                      <p className='category-desc text-center font-inter text-xs font-semibold'>
                        {item.description}
                      </p>
                    </Link>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section> */}
    <div className="bg-white pt-3 px-[5%]">
    <p className="text-[30px] font-black text-center text-[#2C3ABF] sm:text-[20px]" style={{fontFamily: 'Inter'}}>
        JEWELLERY BY
      </p>
      <p className="text-[75px] font-medium text-center text-[#2C3ABF] mt-[-30px] sm:text-[64px] sm:mt-[-25px]" style={{fontFamily: 'playFair'}}>
        Metal Type
      </p>
      <div className="grid sm:grid-cols-1 grid-cols-4 gap-2 sm:gap-3 w-[1335px] sm:w-full md:grid-cols-2 md:w-full" style={{margin : '0 auto'}}>
        {metalData.map((item, index) => (
          <Link
            key={index}
            to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_METAL_PRODUCT_QUERY_ROUTE}=${item?.slug}`}
            className="text-center"
          >
            <img
              src={item.image}
              alt={item.description}
              className="w-[310px] h-[360px] object-cover rounded-[5px] mb-3 sm:mb-1 sm:w-[371px] sm:h-[430px]"
            />
            <p className="text-[18px] font-bold text-[#292929]" 
            style={{
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            }}
            >{item.description}</p>
          </Link>
        ))}
      </div>
    </div>
    </>
  );
};

export default MetalType;
