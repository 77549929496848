import useDeviceDetect from "hooks/useDeviceDetect";
import React from 'react';
import OTPFormDesktop from "./DesktopOTP";
import { useSearchParams } from 'react-router-dom';
import MobileOTP from "./MobileOTP";

const OtpPage = () => {
  const { isMobile } = useDeviceDetect();
  const [searchParams] = useSearchParams(); 
  const phoneNumber = searchParams?.get("mobile");
  return (
    <div className="p-4">
      {!isMobile && (
        <>
          <div>
            <OTPFormDesktop phone={phoneNumber}/>
          </div>
        </>
      )}
      {isMobile && (
        <MobileOTP phone={phoneNumber}/>
      )}
    </div>
  );
};

export default OtpPage;
