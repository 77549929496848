import { useState, useEffect, useCallback } from "react";
import { useLazyQuery as useApolloLazyQuery } from "@apollo/client";

/**
 * Custom useLazyQuery Hook
 * @param {DocumentNode} query - GraphQL query document
 * @param {object} options - Apollo client query options
 * @returns {object} { data, loading, error, executeQuery, refetch }
 */

const useLazyQuery = (query, options = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const variables = !options.variables ? { variables: { domain: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "mangaldasjewellers.goldclub.co" : window.location.hostname, } } : '';
    
    const [execute, { called, refetch }] = useApolloLazyQuery(query, {
        ...options,
        ...variables,
        onCompleted: (responseData) => {
            if (!cancelled) {
                setData(responseData);
                setLoading(false);
                setError(null);

                if (options.onCompleted) {
                    options.onCompleted(responseData);
                }
            }
        },
        onError: (err) => {
            if (!cancelled) {
                setError(err);
                setLoading(false);

                if (options.onError) {
                    options.onError(err);
                }
            }
        },
        fetchPolicy: options.fetchPolicy || "cache-first",
    });

    const [cancelled, setCancelled] = useState(false);

    const executeQuery = useCallback(
        (variables) => {
            setLoading(true);
            execute({ variables });
        },
        [execute]
    );

    useEffect(() => {
        setCancelled(false);

        if (options.onLoad) {
            executeQuery();
        }

        return () => {
            setCancelled(true);
        };
    }, []);

    useEffect(() => {
        if (called && options.refetchOnMount) {
            refetch();
        }
    }, [called, refetch, options.refetchOnMount]);

    return {
        data,
        loading,
        error,
        executeQuery,
        refetch,
    };
};

export default useLazyQuery;
