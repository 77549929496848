import * as React from 'react';
const Facebook = props => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.1199 0.160156C3.92257 0.160156 0.519897 3.56283 0.519897 7.76016C0.519897 11.3243 2.97379 14.315 6.28404 15.1364V10.0827H4.71692V7.76016H6.28404V6.75939C6.28404 4.17265 7.45475 2.97368 9.99436 2.97368C10.4759 2.97368 11.3067 3.06822 11.6466 3.16246V5.26766C11.4672 5.24881 11.1556 5.23939 10.7687 5.23939C9.52255 5.23939 9.04102 5.7115 9.04102 6.93875V7.76016H11.5235L11.097 10.0827H9.04102V15.3045C12.8042 14.85 15.7202 11.6459 15.7202 7.76016C15.7199 3.56283 12.3172 0.160156 8.1199 0.160156Z'
      fill='white'
    />
  </svg>
);
export default Facebook;
