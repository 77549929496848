import { useMutation } from "@apollo/client";
import { REGISTER,SEND_OTP, VALIDATE_OTP } from 'graphql/mutations';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRegisterUser = (authDetails) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const [register, { loading, data }] = useMutation(REGISTER, {
        variables: {
            firstName: authDetails?.firstname?.trim(),
            lastName: authDetails?.lastname?.trim(),
            phoneNumber: (process.env.REACT_APP_PH_DEFAULT + authDetails.phone)?.trim(),
        },
        context: {
            headers: {
                authorization: localStorage.getItem(
                    `vjw-${window.location.hostname}token`
                ),
            },
        },
        onCompleted: (data) => {
            if (data.customerCreate.user) {
                setError(null);
                setSuccess({
                    success: true,
                    message: "You're registered successfully",
                });
           
            } else {
                setSuccess(null);
                setError({
                    error: true,
                    message: data.customerCreate.accountErrors[0].message,
                });
            }
        },
        onError: (err) => {
            setError({ error: true, message: err.message });
        },
    });

    return {
        register,
        data,
        loading,
        error,
        success,
    };
};


export const useSendAndValidateOtp = () => {
    const [state, setState] = useState({ phone: '', otp: '', is: '' });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const [sendOtp, { loading: otpLoading }] = useMutation(SEND_OTP, {
        variables: {
            mobile: state.phone,
        },
        onCompleted: (data) => {
            if (data?.otp?.status) {
                setError(null);
                setSuccess({ success: true, message: data.otp.message });
            } else {
                setSuccess(null);
                setError({ error: true, message: data.otp.message });
            }
        },
        onError: (err) => {
            setError({ error: true, message: err.message });
        },
    });

    const [validateOtp, { loading: validateLoading }] = useMutation(VALIDATE_OTP, {
        variables: {
            mobile: state.phone,
            otp: state.otp,
        },
        onCompleted: (data) => {
            if (data.verify.status) {
                setSuccess({ success: true, message: data.verify.message });

                localStorage.setItem(
                    `vjw-${window.location.hostname}user`,
                    JSON.stringify(data.verify)
                );
                localStorage.setItem(
                    `vjw-${window.location.hostname}user-det`,
                    JSON.stringify(data.verify.user)
                );

                setTimeout(() => {
                    const redirectTo = sessionStorage.getItem("beforeLogin") || "/";
                    navigate(redirectTo);
                    sessionStorage.removeItem("beforeLogin");
                }, 100);
            } else {
                setSuccess(null);
                setError({ error: true, message: data.verify.message });
            }
        },
        onError: (err) => {
            setError({ error: true, message: err.message });
        },
    });

    const handleSendOtp = (phone) => {
        setState(prevState => ({ ...prevState, phone: phone, is: "SEND" }));
    };

    const handleValidateOtp = ({ phone, otp }) => {
        setState({ phone, otp, is: "VALIDATE" });        
    };

    useEffect(() => {
        if (state.phone && state.is === "SEND") {
            sendOtp();
        }
    }, [state.phone, sendOtp]);

    useEffect(() => {
        if (state.otp && state.is === "VALIDATE") {
            validateOtp();
        }
    }, [state.otp, validateOtp]);

    return {
        sendOtp: handleSendOtp,
        verifyOtp: handleValidateOtp,
        loading: otpLoading || validateLoading,
        error,
        success,
        setState,
    };
}
