import React from 'react'
import { useResponsive } from 'hooks';
import SingleProductDetails from './singleProductDetails.jsx';
import SingleProductDetailsMobile from './singleProductDetailesMobile.jsx';
import { useProductDetailsByIdApi } from 'api';
import { useSearchParams,useParams } from 'react-router-dom';
import Loader from 'components/Loader/Loader.jsx';
const SingleProductDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { data : productDetails, loading } = useProductDetailsByIdApi({ id:id, isDBP:searchParams.get("isDBP")==="true", onLoad:true});
  const { isDesktop, isMobile } = useResponsive();
  return (
    <>
    {loading ? <Loader/> : 
    <div>
      <div className='flex gap-x-5'>
        {!isMobile && <SingleProductDetails product={productDetails} />}
        {isMobile && <SingleProductDetailsMobile product={productDetails} />}
      </div>
    </div>
    }
    </>
  )
}

export default SingleProductDetailsPage