import * as React from 'react';

const UserCircle = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.12 13.5305C12.1 13.5305 12.07 13.5305 12.05 13.5305C12.02 13.5305 11.98 13.5305 11.95 13.5305C9.67998 13.4605 7.97998 11.6905 7.97998 9.51047C7.97998 7.29047 9.78998 5.48047 12.01 5.48047C14.23 5.48047 16.04 7.29047 16.04 9.51047C16.03 11.7005 14.32 13.4605 12.15 13.5305C12.13 13.5305 12.13 13.5305 12.12 13.5305ZM12 6.97047C10.6 6.97047 9.46998 8.11047 9.46998 9.50047C9.46998 10.8705 10.54 11.9805 11.9 12.0305C11.93 12.0205 12.03 12.0205 12.13 12.0305C13.47 11.9605 14.52 10.8605 14.53 9.50047C14.53 8.11047 13.4 6.97047 12 6.97047Z'
      fill='#FFFFFF'
    />
    <path
      d='M12 22.7503C9.30996 22.7503 6.73996 21.7503 4.74996 19.9303C4.56996 19.7703 4.48996 19.5303 4.50996 19.3003C4.63996 18.1103 5.37996 17.0003 6.60996 16.1803C9.58996 14.2003 14.42 14.2003 17.39 16.1803C18.62 17.0103 19.36 18.1103 19.49 19.3003C19.52 19.5403 19.43 19.7703 19.25 19.9303C17.26 21.7503 14.69 22.7503 12 22.7503ZM6.07996 19.1003C7.73996 20.4903 9.82996 21.2503 12 21.2503C14.17 21.2503 16.26 20.4903 17.92 19.1003C17.74 18.4903 17.26 17.9003 16.55 17.4203C14.09 15.7803 9.91996 15.7803 7.43996 17.4203C6.72996 17.9003 6.25996 18.4903 6.07996 19.1003Z'
      fill='#FFFFFF'
    />
    <path
      d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z'
      fill='#FFFFFF'
    />
  </svg>
);
export default UserCircle;
