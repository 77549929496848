import * as React from 'react';

const Cart = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.5 8.6303C16.09 8.6303 15.75 8.2903 15.75 7.8803V6.5003C15.75 5.4503 15.3 4.4303 14.52 3.7203C13.73 3.0003 12.71 2.6703 11.63 2.7703C9.83 2.9403 8.25 4.7803 8.25 6.7003V7.6703C8.25 8.0803 7.91 8.4203 7.5 8.4203C7.09 8.4203 6.75 8.0803 6.75 7.6703V6.6903C6.75 4.0003 8.92 1.5203 11.49 1.2703C12.99 1.1303 14.43 1.6003 15.53 2.6103C16.62 3.6003 17.25 5.0203 17.25 6.5003V7.8803C17.25 8.2903 16.91 8.6303 16.5 8.6303Z'
      fill='#FFFFFF'
    />
    <path
      d='M14.9999 22.75H8.99994C4.37994 22.75 3.51994 20.6 3.29994 18.51L2.54994 12.52C2.43994 11.44 2.39994 9.89 3.44994 8.73C4.34994 7.73 5.83994 7.25 7.99994 7.25H15.9999C18.1699 7.25 19.6599 7.74 20.5499 8.73C21.5899 9.89 21.5599 11.44 21.4499 12.5L20.6999 18.51C20.4799 20.6 19.6199 22.75 14.9999 22.75ZM7.99994 8.75C6.30994 8.75 5.14994 9.08 4.55994 9.74C4.06994 10.28 3.90994 11.11 4.03994 12.35L4.78994 18.34C4.95994 19.94 5.39994 21.26 8.99994 21.26H14.9999C18.5999 21.26 19.0399 19.95 19.2099 18.36L19.9599 12.35C20.0899 11.13 19.9299 10.3 19.4399 9.75C18.8499 9.08 17.6899 8.75 15.9999 8.75H7.99994Z'
      fill='#FFFFFF'
    />
    <path
      d='M15.42 13.1504C14.86 13.1504 14.41 12.7004 14.41 12.1504C14.41 11.6004 14.86 11.1504 15.41 11.1504C15.96 11.1504 16.41 11.6004 16.41 12.1504C16.41 12.7004 15.97 13.1504 15.42 13.1504Z'
      fill='#FFFFFF'
    />
    <path
      d='M8.42003 13.1504C7.86003 13.1504 7.41003 12.7004 7.41003 12.1504C7.41003 11.6004 7.86003 11.1504 8.41003 11.1504C8.96003 11.1504 9.41003 11.6004 9.41003 12.1504C9.41003 12.7004 8.97003 13.1504 8.42003 13.1504Z'
      fill='#FFFFFF'
    />
  </svg>
);
export default Cart;
