import useDeviceDetect from 'hooks/useDeviceDetect';
import MyAccountMobile from 'components/account/MyAccountMobileView';
import ViewAccountDesktop from 'components/account/MyAccountDesktop'; 
import React, { useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
const AccountPage = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect(); 
  useEffect(()=>{
    let userDetails = JSON.parse(
      localStorage.getItem(`vjw-${window.location.hostname}user-det`)
    );
    console.log("userDetailsuserDetailsuserDetailsuserDetails",userDetails)
    if(!userDetails){
      navigate({
        pathname: `/login`
      });  
    }
  },[]);
  return (
    <>
    {isMobile && <MyAccountMobile/>}
    {!isMobile && <ViewAccountDesktop/>}
     
    </>
  );
};
export default AccountPage;