import { useLazyQuery } from "hooks";
import { GET_STORE_DETAILS } from "graphql/query"; 
import { generateDynamicManifest } from "functions";
export const useMarquee = (onLoad) => {
 
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_STORE_DETAILS, onLoad );
    generateDynamicManifest(data?.storeDetails?.name, data?.storeDetails?.name, data?.storeDetails?.user?.phoneNumber, data?.storeDetails?.storeLogo)
    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};
