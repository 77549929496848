
import { useLazyQuery } from "hooks";
import { GET_COLLECTIONS } from "graphql/query";
 

export const useCollection = (onLoad) => {
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_COLLECTIONS, { onLoad } );
          
    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};
