import { useState, useEffect } from "react";

const useResponsive = () => {
	const [isMobile, setIsMobile] = useState(false);
	const [isTablet, setIsTablet] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		const mobileQuery = window.matchMedia("(max-width: 767px)");
		const tabletQuery = window.matchMedia(
			"(min-width: 768px) and (max-width: 1023px)",
		);
		const desktopQuery = window.matchMedia("(min-width: 1024px)");

		const handleMobileChange = (e) => setIsMobile(e.matches);
		const handleTabletChange = (e) => setIsTablet(e.matches);
		const handleDesktopChange = (e) => setIsDesktop(e.matches);

		setIsMobile(mobileQuery.matches);
		setIsTablet(tabletQuery.matches);
		setIsDesktop(desktopQuery.matches);

		mobileQuery.addEventListener("change", handleMobileChange);
		tabletQuery.addEventListener("change", handleTabletChange);
		desktopQuery.addEventListener("change", handleDesktopChange);

		return () => {
			mobileQuery.removeEventListener("change", handleMobileChange);
			tabletQuery.removeEventListener("change", handleTabletChange);
			desktopQuery.removeEventListener("change", handleDesktopChange);
		};
	}, []);

	return { isMobile, isTablet, isDesktop };
};

export default useResponsive;
